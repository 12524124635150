import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Grid,
  IconButton,
  TextField,
  makeStyles,
  Theme,
  createStyles,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";
import React, { useEffect } from "react";
import { IUser } from "../../../../models/user_model";
import { useForm, Controller } from "react-hook-form";

type FormUserData = {
  email: string;
  password: string;
  rol: string;
  nombres: string;
  primerApellido: string;
  segundoApellido: string;
  nombreCorto: string;
  ci: string;
  firstName: string;
};

type FormUserProps = {
  user: IUser | null;
  isModalOpen: boolean;
  handleClose: () => void;
  handleSave: (data: IUser) => void;
};

const FormUser: React.FC<FormUserProps> = ({
  user,
  isModalOpen,
  handleClose,
  handleSave,
}) => {
  const { handleSubmit, control, setValue } = useForm<FormUserData>({
    shouldUnregister: false,
  });

  useEffect(() => {
    if (user) {
      console.log('seteando datos de usuario para edicion:: ', user);
      setTimeout(() => {
        setValue('email', user.email);
        setValue("rol", user.rol);
        setValue("nombres", user.nombres);
        setValue("primerApellido", user.primerApellido);
        setValue("segundoApellido", user.segundoApellido);
        setValue("nombreCorto", user.nombreCorto);
        setValue("ci", user.ci);
      }, 1000);
    }
  }, [user, setValue])


  const classes = useStyles();

  const onSubmit = (data: FormUserData) => {
    console.log("data usuario :: ", data);
    handleSave({
      email: data.email,
      password: data.password,
      rol: data.rol,
      nombres: data.nombres,
      primerApellido: data.primerApellido,
      segundoApellido: data.segundoApellido,
      nombreCorto: data.nombreCorto,
      ci: data.ci,
      direccion: '',
      estado: '',
      celular: '',
      ciudad: '',
    });
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={isModalOpen}
    >
      <DialogTitle>
        <Grid container justify="space-between">
          { user ? 'Editar usuario' : 'Crear nuevo usuario'}
          <IconButton size="small" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent dividers>
          <Box mb={3}>
            <Typography gutterBottom style={{ fontWeight: "bold" }}>
              Datos de usuario
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="email"
                  control={control}
                  defaultValue=""
                  render={({ field }) => <TextField {...field} type="email" fullWidth variant='filled' required label="Correo electrónico" />}
                />
              </Grid>
              { !user && (
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="password"
                    control={control}
                    defaultValue=""
                    render={({ field }) => <TextField {...field} fullWidth variant='filled' required label="Contraseña" type="password" autoComplete="new-password" />}
                  />
                </Grid>
              )}
              <Grid item xs={12} sm={6}>
                <Controller
                  name="rol"
                  control={control}
                  defaultValue=''
                  render={({ field }) => (
                    <FormControl variant="filled" required className={classes.formControl}>
                      <InputLabel>Selecciona un Rol</InputLabel>
                      <Select
                        {...field}
                      >
                        <MenuItem value={"ADMINISTRADOR"}>Administrador</MenuItem>
                        <MenuItem value={"MONITOR"}>Monitor</MenuItem>
                        <MenuItem value={"PROFESOR"}>Profesor</MenuItem>
                      </Select>
                    </FormControl>
                  )}
                />
              </Grid>
            </Grid>
          </Box>
          <Box my={3}>
            <Typography gutterBottom style={{ fontWeight: "bold" }}>
              Datos personales del usuario
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="nombres"
                  control={control}
                  defaultValue=""
                  render={({ field }) => <TextField {...field} required fullWidth variant='filled' label="Nombre(s)" />}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="primerApellido"
                  control={control}
                  defaultValue=""
                  render={({ field }) => <TextField {...field} required fullWidth variant='filled' label="Primer apellido" />}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="segundoApellido"
                  control={control}
                  defaultValue=""
                  render={({ field }) => <TextField {...field} fullWidth variant='filled' label="Segundo apellido" />}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="nombreCorto"
                  control={control}
                  defaultValue=""
                  render={({ field }) => <TextField {...field} fullWidth variant='filled' label="Nombre corto" />}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="ci"
                  control={control}
                  defaultValue=""
                  render={({ field }) => <TextField {...field} fullWidth variant='filled' label="Cédula de identidad" />}
                />
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} className={classes.btnError} fullWidth>
            Cancelar
          </Button>
          <Button type="submit" className={classes.btnSave} fullWidth>
            Aceptar y Guardar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    btnSave: {
      backgroundColor: theme.palette.success.main,
      // margin: '0 3px',
      "&:hover": {
        backgroundColor: theme.palette.success.light,
      },
    },
    btnError: {
      backgroundColor: theme.palette.error.main,
      // margin: '0 3px',
      "&:hover": {
        backgroundColor: theme.palette.error.light,
      },
    },
    formControl: {
      // margin: theme.spacing(1),
      width: "100%",
      // minWidth: '100%',
    },
  })
);

export default FormUser;