import { Curso } from "../../models/curso_model";
import {
  getCursos,
  createCurso,
  editCurso,
  deleteCurso,
  getCursoById
} from "../../repositories/curso_repository";
import { CURSO_TYPES } from "../types";


export const cargarCursos = (cursoFilter?: string, turnoFilter?: string, gestionFilter?: string) => {
  return async (dispatch: any) => {
    const cursos: any = await getCursos(cursoFilter, turnoFilter, gestionFilter);

    dispatch( setCursos(cursos || []) );
  }
}

export const cargarCursoPorId = (id: string)  => {
  return async (dispatch: any) => {
    const curso: any = await getCursoById(id);

    dispatch(changeCurso(curso));
  }
}

export const agregarCurso = (data: Curso) => {
  return async (dispatch: any) => {
    await createCurso(data);
  }
}

export const editarCurso = (data: Curso, cursoId: string) => {
  return async(dispatch: any) => {
    await editCurso(data, cursoId);
  }
}

export const eliminarCurso = (id: string) => {
  return async (dispatch: any) => {
    await deleteCurso(id);
  }
}

export const changeCurso = (curso: Curso | null) => ({
  type: CURSO_TYPES.CHANGE_CURSO,
  payload: curso
})

export const setStepForm = (value: number) => ({
  type: CURSO_TYPES.SET_STEP_FORM,
  payload: value
})

export const setFormCurso = (value: any) => ({
  type: CURSO_TYPES.SET_FORM_CURSO,
  payload: {...value}
})

export const setCursos = (cursos: Curso[]) => ({
  type: CURSO_TYPES.CARGAR_CURSOS,
  payload: [...cursos]
})