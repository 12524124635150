import http from "./http";
import { Anotacion } from "../models/anotacion_model";

export const createAnotacion = async (data: Anotacion) => {
  try {
    await http.post(`/anotacion/`, data);
    return true
  } catch (error) {
    console.error("[create] Error :: ", error);
    return false
  }
};

export const editAnotacion = async (data: Partial<Anotacion>, id: string) => {
  try {
    await http.put(`/anotacion/${id}`, data);
    return true
  } catch (error) {
    console.error("[put] Error :: ", error);
    return false;
  }
};
