import { createContext, Dispatch, SetStateAction, useState } from "react";

export type MessageProps = {
  text: string;
  type: string;
  show: boolean;
}

type Context = {
  message: MessageProps,
  setMessage: Dispatch<SetStateAction<MessageProps | undefined>>;
}

type InputProps = {
  children: React.ReactNode;
}

export const AppContext = createContext<Partial<Context>>({});

export default function AppContextProvider ({ children }: InputProps) {

  const [ message, setMessage ] = useState<MessageProps>();

  const state = {
    message,
    setMessage
  }

  return (
    <AppContext.Provider value={state}>
      {children}
    </AppContext.Provider>
  )
}