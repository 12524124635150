// import { IProfesor } from "../../models/profesor_model";
import { IUser } from "../../models/user_model";
import { changePassword, createUser, deleteUser, editUser, getUsers } from "../../repositories/usuario_repository";
// import { allProfesorsRepository, createProfesorRepository } from "../../repositories/profesor";
import { USER_TYPES } from "../types";


export const cargarUsuarios = (nombreFilter?: string, emailFilter?: string) => {
  return async (dispatch: any) => {
    const usuarios: any = await getUsers(nombreFilter, emailFilter);

    dispatch( setUsuarios(usuarios || []) );
  }
}

export const addUsuario = (data: IUser) => {
  return async (dispatch: any) => {
    await createUser(data);
  }
}

export const editUsuario = (data: any, userId: string) => {
  return async(dispatch: any) => {
    await editUser(data, userId);
  }
}

export const deleteUsuario = (id: string) => {
  return async (dispatch: any) => {
    await deleteUser(id);
  }
}

export const setUsuarios = (usuarios: IUser[]) => ({
  type: USER_TYPES.LOAD_USERS,
  payload: [...usuarios]
})

export const cambiarContrasenia = (newPass: string, onComplete?: (success: boolean) => void) => {
  return async (dispatch: any) => {
    const success = await changePassword(newPass);
    if (onComplete) onComplete(success);
  }
}
