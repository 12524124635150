import { Grid, IconButton, TextField, Tooltip, Typography, useTheme } from "@material-ui/core";
import {Delete as DeleteIcon, AddCircle as AddIcon} from '@material-ui/icons';
import React, { useEffect, useState } from "react";
import { Controller, useFieldArray } from "react-hook-form";
import { Translate } from "react-redux-i18n";
import SkillsRow from "./SkillsRow";

const AreaRow: React.FC<any> = ({ control, register, setValue, getValues, kinderSkills, gridType, activities }) => {
  const [skills, setSkills] = useState([]); 
  const theme = useTheme();
  const { fields, append, remove } = useFieldArray({ 
    control,
    name: "areas"
  });

  // useEffect(() => {
  //    console.log('get val')
  //    console.log(getValues());
  // });

  useEffect(() => {
    setTimeout(() => {
      if (kinderSkills) {
        
        let data = loadGrid();
        
        if(data.length>0){
          setValue('areas', data);
        }
          
      }
    }, 200);
  }, [kinderSkills]);


  useEffect(() => {

    // let index = localStorage.getItem('locale') === 'de' ? 1 : 0;

    // let tmpSkills = kinderSkills.map((el:any) => {

    //   let tmp = {
    //     ...el, nombre: el.nombre.split('/')[index], categoria: el.categoria.split('/')[index]
    //   }
      
    //   return tmp;
      
    // });

    // let orderedSkills =  [...tmpSkills].sort((o1:any, o2:any) => {
    //   if(o1.categoria< o2.categoria)
    //     return -1;
    //   else if (o1.categoria > o2.categoria)
    //     return 1;
      
    //   return 0
    // }) ;
    // handleSetSkills(kinderSkills);

  }, []);

  const loadGrid = () => {

    let groupArea:any = [];

    for(let x in kinderSkills) {
	
      let found:boolean = false;
      let index:number = -1;
      for(let y in groupArea) {
        
        if(kinderSkills[x].categoria == groupArea[y].nombre){
          found = true;
          index = parseInt(y);
          groupArea[y].competencias = [...groupArea[y].competencias, kinderSkills[x]]
          break;
        }
        
      }
      
      if(!found) {
        let obj:any = {
          nombre: kinderSkills[x].categoria,
          competencias: [kinderSkills[x]]
        }
        groupArea = [...groupArea, obj];  
      }
      
    }
    
    let formData: any = groupArea.map((el:any) => {
      let row:any = {
        nombre: el.nombre,
        competencias: el.competencias
          .map((item:any) => {
            let obj:any = {
              val: item.nombre,
              skillId: item.id
            }

            return obj;
          })
      }

      return row;
    });

    return formData;
  }

  useEffect(() => {
    setTimeout(() => {
      if (activities) {
        
        let data = loadGrid();

        if(data.length>0){
          setValue('areas', data);
        }
          
      }
    }, 200);
  }, [activities]);

  return (
    <>
      <Grid container>
        {fields.map((item:any, index) => {


          return (
            <Grid item container key={item.id}>
              <Grid item xs={3}>
                <IconButton size='small' onClick={() => remove(index)} style={{ color: theme.palette.common.black }}>
                  <DeleteIcon />
                </IconButton>
                <Controller
                  name={`areas[${index}].nombre`}
                  control={control}
                  defaultValue={item.nombre}
                  render={({ field }) =>
                    <Tooltip arrow title={
                      <Typography>
                        <Translate value={getValues(`areas[${index}].nombre`)}/>
                      </Typography>}
                    >
                      <TextField
                        type='text'
                        required
                        inputProps={{step: 1, min: 0, max: 6}}
                        {...field}
                        //className={classes.formControl}
                        style={{margin: theme.spacing(1), minWidth: "100px",}}
                        variant='filled'
                        label={<Translate value="Nombre Área de Desarrollo"/>}
                      />
                    </Tooltip>
                  }
                />
              </Grid>
              <Grid item xs={9}>
                <SkillsRow availableSkills={skills} nestIndex={index} {...{ control, register }} />
              </Grid>
            </Grid>
          );
        })}
      </Grid>
      <section>
        <Grid item xs={12}>
          <IconButton size='small' onClick={() => append({
            nombre: '',
            categoria: gridType,
            competencias: [{val: '', skillId: null}, {val: '', skillId: null}, {val: '', skillId: null}]
          })} style={{ color: theme.palette.success.main }}>
            <AddIcon fontSize='large' />
          </IconButton>
        </Grid>
      </section>
    </>
  );
}

export default AreaRow;