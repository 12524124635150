import { makeStyles, createStyles, Theme } from "@material-ui/core";
import { useEffect, useState } from 'react';
import { Calendar, momentLocalizer, SlotInfo, stringOrDate } from 'react-big-calendar';
import MainLayout from "../../components/layout/MainLayout";
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import ModelEvento from "./components/ModalEvento";
import { agregarEvento, cargarEventos, changeEvento, editarEvento, eliminarEvento } from "../../store/actions/evento_actions";
import { useDispatch } from "react-redux";
import { RootState, useStateSelector } from "../../store/reducers";
import { I18n } from "react-redux-i18n";

const localizer = momentLocalizer(moment)

export type Evento = {
  id?: number,
  title: string,
  desc?: string,
  start: stringOrDate,
  end: stringOrDate,
  allDay?: boolean
};

const Calendario: React.FC = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [modalEvento, setModalEvento ] = useState<boolean>(false);

  const { eventos, evento } = useStateSelector((state: RootState) => state.eventoState);

  useEffect(() => {
    localStorage.getItem('locale') === 'de' ? require('moment/locale/de') : require('moment/locale/es');
  }, []);

  useEffect(() => {
    dispatch( cargarEventos() );
  }, [dispatch])

  const handleClose = () => {
    setModalEvento(false);
    dispatch( changeEvento(null) );
  };

  const handleSaveEvento = (data: any) => {
    const _evento = data;

    dispatch(
      _evento.id ? editarEvento(_evento, _evento.id) : agregarEvento(_evento)
    )

    setModalEvento(false);

    setTimeout(() => {
      dispatch( cargarEventos() )
    }, 1000);
  }

  const handleDeleteEvento = (id: string) => {
    if (window.confirm('Quiere eliminar el evento?')) {
      dispatch(
        eliminarEvento(id)
      )
  
      setModalEvento(false);
  
      setTimeout(() => {
        dispatch( cargarEventos() )
      }, 1000);
    }
  }

  const handleSelect = ({ start, end }: SlotInfo) => {
    dispatch( changeEvento({
      fecha: start,
      fechaFinal: start // para que la fecha de inicio y fin seleccionada por defecto sea la misma
    }) );
    setModalEvento(true);
  }

  const handleSelectEvent = (event: any) => {
    const eventoSeleccionado = eventos.find(e => e.id === event.id);
    if (!eventoSeleccionado) return;
    dispatch( changeEvento(eventoSeleccionado) );
    setModalEvento(true);
  }

  return (
    <MainLayout>
      <div className={classes.containerCalendar}>
        {
          (
            <Calendar
              selectable
              localizer={localizer}
              events={eventos.map((item: any) => ({
                id: item.id,
                start: moment(`${item.fecha.split('T')[0]} ${item.horaInicio}`, 'YYYY-MM-DD HH:mm').toDate(),
                end: moment(`${item.fechaFinal.split('T')[0]} ${item.horaFin}`, 'YYYY-MM-DD HH:mm').toDate(),
                title: `${item.titulo} [${item.horaInicio === '00:00' && item.horaFin === '23:59' ? I18n.t('calendario.todoElDia'): `${item.horaInicio} - ${item.horaFin}`}]`,
                desc: item.descripcion,
                all: item.dia
              }))}
              defaultView="month"
              scrollToTime={new Date()}
              defaultDate={new Date()}
              onSelectEvent={handleSelectEvent}
              onSelectSlot={handleSelect}
              messages={{
                next: I18n.t('calendario.siguiente'),
                previous: I18n.t('calendario.anterior'),
                today: I18n.t('calendario.hoy'),
                month: I18n.t('calendario.mes'),
                week: I18n.t('calendario.semana'),
                day: I18n.t('calendario.dia'),
                agenda: I18n.t('calendario.agenda'),
                allDay: I18n.t('calendario.todoElDia'),
                date: I18n.t('calendario.fecha'),
                noEventsInRange: I18n.t('calendario.noHayEventosEnEsteRango'),
                event: I18n.t('calendario.evento'),
              }}
            />
          )          
        }
      </div>
      { modalEvento && (
        <ModelEvento  
          handleClose={handleClose}
          isModalOpen={modalEvento}
          evento={evento}
          handleSave={handleSaveEvento}
          handleDelete={handleDeleteEvento}
        />
      )}
    </MainLayout>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    containerCalendar: {
      height: 800,
      backgroundColor: 'white',
      padding: theme.spacing(1),
    },
  }),
);

export default Calendario;
