import { ReporteModel } from "models/reporte_model";
import { REPORTE_TYPES } from "store/types";
import { getReporte, downloadReporteExcel } from "../../repositories/reporte_repository";

export const cargarReporte = (tipoReporte?: string, desde?: string | null, hasta?: string | null, filtros?: any) => {
  return async (dispatch: any) => {
    const reporte: any = await getReporte(tipoReporte, desde, hasta, filtros);
    // console.log("reporte :: ", reporte, reporte.length);

    dispatch(setReporte(reporte));
  };
};

export const descargarReporteExcel = (tipoReporte?: string, desde?: string | null, hasta?: string | null, filtros?: any) => {
  return async (dispatch: any) => {
    await downloadReporteExcel(tipoReporte, desde, hasta, filtros);
  };
};

export const setReporte = (reporte: ReporteModel) => ({
  type: REPORTE_TYPES.CARGAR_REPORTE,
  payload: reporte,
});
