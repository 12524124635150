import { Box, FormControl, IconButton, useTheme } from "@material-ui/core";
import {Delete as DeleteIcon, AddCircle as AddIcon} from '@material-ui/icons';
import React from "react";
import { Controller, useFieldArray } from "react-hook-form";
import ActivitySelect from "./ActivitySelect";

const SkillsRow: React.FC<any> = ({ nestIndex, control, register, availableSkills }) => {

  const theme = useTheme();

  const { fields, remove, append } = useFieldArray({
    control,
    name: `actividades[${nestIndex}].competencias`
  });

  return (
    <React.Fragment>
      {fields.map((item:any, k) => {
        return (
          <React.Fragment key={item.id}>
            <Box>
              <Controller
                name={`actividades[${nestIndex}].competencias[${k}].val`}
                control={control}
                defaultValue={item.val}
                //defaultValue={item.id}
                render={({ field }) => {
                  
                  // console.log(availableSkills)
                  //console.log('field xxxx')
                  //console.log(field)

                  let tmpSkill =  field.value ? availableSkills.filter((el:any) => {
                    return el.id === field.value
                  })[0] : null;

                  let handleUpdate = (val:any) => 
                  {
                    //console.log('Le llega')
                    //console.log(val)
                    field.onChange(val)
                  }

                  return(
                  <FormControl variant="filled" style={{ width: '90%'}}>
                    <ActivitySelect 
                      availableSkills={availableSkills}
                      skill={tmpSkill}
                      handleUpdate={handleUpdate}
                    />
                  </FormControl>
                )}}
              />
              <IconButton size='small' onClick={() => remove(k)} style={{ color: theme.palette.common.black }}>
                <DeleteIcon />
              </IconButton>
              {fields && fields.length < 3 ? 
                <IconButton size='small' onClick={() => append({val:''})} style={{ color: theme.palette.success.main }}>
                  <AddIcon fontSize='large' />
                </IconButton> :  null
              }
            </Box>
          </React.Fragment>
        );
      })}

    {fields.length === 0 ? 
      <IconButton size='small' onClick={() => append({val:''})} style={{ color: theme.palette.success.main }}>
        <AddIcon fontSize='large' />
      </IconButton> :  null
    }
    </React.Fragment>
  );
};

export default SkillsRow;