import {
  createCircularService,
  deleteCircularService,
  editCircularService,
  getCircularesService,
} from 'repositories/circular_repository';
import { CIRCULAR_TYPES } from '../types';
import { ICircular, ICircularForm } from 'models/circular_model';

export const cargarCirculares = (
  codigoFilter?: string,
  asuntoFilter?: string,
) => {
  return async (dispatch: any) => {
    const { rows } = await getCircularesService({
      codigo: codigoFilter,
      asunto: asuntoFilter,
    });
    dispatch(setCirculares(rows || []));
  };
};

export const addCircular = (data: ICircularForm) => {
  return async (dispatch: any) => {
    await createCircularService(data);
  };
};

export const editCircular = (data: any, userId: string) => {
  return async (dispatch: any) => {
    await editCircularService(data, userId);
  };
};

export const deleteCircular = (id: string) => {
  return async (dispatch: any) => {
    await deleteCircularService(id);
  };
};

export const setCirculares = (usuarios: ICircular[]) => ({
  type: CIRCULAR_TYPES.LOAD_CIRCULARES,
  payload: [...usuarios],
});
