import React, { ReactElement } from 'react';

import { Theme, FormControl, TextField, makeStyles } from '@material-ui/core';

type Props = {
    name: string;
    label: string;
    value?: string;
    onChange: (name: string, value: string) => void;
    disabled?: boolean;
};

export const TextFilter = ({ name, label, value, onChange, disabled }: Props): ReactElement => {
    const classes = useStyles();

    return (
        <FormControl className={classes.formControl}>
            <TextField
                name={name}
                label={label}
                value={value || ''}
                onChange={(evt) => onChange(evt.target.name as string, evt.target.value as string)}
                disabled={disabled}
            />
        </FormControl>
    );
};

const useStyles = makeStyles((theme: Theme) => ({
    formControl: {
        width: '100%',
        margin: theme.spacing(0, 1),
        overflow: 'hidden',
    },
}));
