import { MATERIA_TYPES, Action } from "../types";
import { Materia } from "../../models/materia_model";

export type State = {
  materias: Materia[] | [];
  materia: Materia | null;
};

const initialState: State = {
  materias: [],
  materia: null,
};

const reducer = (state: State = initialState, { type, payload }: Action) => {
  switch (type) {
    case MATERIA_TYPES.CARGAR_MATERIAS:
      return {
        ...state,
        materias: payload,
      };
    case MATERIA_TYPES.SET_MATERIA:
      return {
        ...state,
        materia: payload
      }
    default:
      return state;
  }
};

export default reducer;
