import React, { forwardRef, ReactElement, useEffect, useImperativeHandle } from "react";
import {
  Box,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  makeStyles,
  LinearProgress,
} from "@material-ui/core";
import { ReporteColumn, ReporteItem, ReporteModel, ReporteRow } from "models/reporte_model";
import { I18n, Translate } from "react-redux-i18n";
import { useIsMounted } from "hooks/useIsMounted";
import { getNotificaciones } from "repositories/notificacion_repository";
import DOMPurify from 'dompurify';

const DEFAULT_ROWS_PER_PAGE = 50;

export type NotificacionesRefProps = {
  refresh: () => void;
};

type Props = {
  tipoReporte: string;
  descargarExcel?: boolean;
};

const NotificationTable = ({ tipoReporte, descargarExcel }: Props, ref: React.Ref<NotificacionesRefProps>): ReactElement => {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [count, setCount] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(DEFAULT_ROWS_PER_PAGE);
  const [loading, setLoading] = React.useState<boolean>(false);
  const isMounted = useIsMounted();
  const [stateFilters, setStateFilters] = React.useState<any>({});

  const [rows, setRows] = React.useState<ReporteRow[]>([]);
  const [columns, setColumns] = React.useState<ReporteColumn[]>([]);

  const cargarReporte = async (filters: any, page: number, rowsPerPage: number) => {
    setLoading(true);
    const result = await getNotificaciones(tipoReporte, filters, { _limit: rowsPerPage, _page: page }) as ReporteModel;
    if (isMounted()) setLoading(false);
    if (result) {
      setRows(result.rows);
      setColumns(result.columns);
      setCount(result.count);
    }
  };
  const cargadoInicial = async () => {
    setStateFilters({});
    setPage(0);
    setRowsPerPage(DEFAULT_ROWS_PER_PAGE);
    cargarReporte({}, 0, DEFAULT_ROWS_PER_PAGE);
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const cargadoInicialCallback = React.useCallback(cargadoInicial, []);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    cargarReporte(stateFilters, newPage, rowsPerPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newRowsPerPage = +event.target.value;
    const newPage = 0;
    setRowsPerPage(newRowsPerPage);
    setPage(newPage);
    cargarReporte(stateFilters, newPage, newRowsPerPage);
  };

  useEffect(() => {
    cargadoInicialCallback();
  }, [cargadoInicialCallback]);

  const renderText = (value: ReporteItem, column: ReporteColumn): ReactElement => {
    if (typeof value !== 'string') {
      if (column.id === 'asunto') {
        return (
          <Box display='flex' flexDirection='column'>
            <Typography><strong>{value.data.titulo}</strong></Typography>
            <div className={classes.textHtml}
              dangerouslySetInnerHTML={{
                  __html: typeof value.data.contenido === 'string' ? DOMPurify.sanitize(value.data.contenido) : '',
              }}
            />
          </Box>
        )
      }
      return <></>;
    }
    return <>{value.split('|').map((line, lineIndex) => <Typography key={lineIndex}>{line}</Typography>)}</>;
  }

  const tableHandler = () => ({
    refresh: () => {
      cargadoInicialCallback();
    },
  });
  useImperativeHandle(ref, tableHandler, [cargadoInicialCallback]);

  return (
    <React.Fragment>
      <Paper className={classes.root}>
        {loading && <Box position="absolute" left={0} right={0}><LinearProgress color="secondary" /></Box>}
        {/* {(rows.length === 0 || rows === undefined || rows === null) && (
          <Typography variant="subtitle1" align="center">
            <Translate value="tabla.sinRegistrosReporte" />
          </Typography>
        )} */}
        {/* {rows.length > 0 && ( */}
          <React.Fragment>
            <TableContainer className={classes.container}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      key="nro"
                      align="left"
                      style={{
                        minWidth: 60,
                        fontWeight: "bold",
                      }}
                    >
                      <Translate value="tabla.nro" />
                    </TableCell>
                    {columns.filter(c => !c.hidden).map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{
                          minWidth: column.minWidth,
                          fontWeight: "bold",
                        }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(rows.length === 0 || rows === undefined || rows === null) && (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                      >
                        <TableCell key="nro" colSpan={999}>
                          <Typography variant="subtitle1" align="center">
                            <Translate value="tabla.sinRegistrosReporte" />
                          </Typography>
                        </TableCell>
                      </TableRow>
                  )}
                  {rows && rows
                    .map((row: ReporteRow, rowIndex: number) => {
                      let resaltado = false;
                      if (tipoReporte === 'ASISTENCIAS_POR_MATERIA') {
                        resaltado = parseInt(String(row[9])) > 0;
                      }
                      if (tipoReporte === 'LIBRO_CLASES_POR_SESION') {
                        if (typeof row[7] === 'string') return <></>;
                        resaltado = !String(row[7].value) || String(row[7].value).trim().length === 0;
                      }
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={rowIndex}
                        >
                          <TableCell key="nro" className={resaltado ? classes.resaltado : undefined}>
                            <Typography>
                              {rowIndex + 1 + page * rowsPerPage}
                            </Typography>
                          </TableCell>
                          {columns.filter(c => !c.hidden).map(
                            (column: ReporteColumn, colIndex: number) => {
                              return (
                                <TableCell key={colIndex} className={resaltado ? classes.resaltado : undefined} width={column.width}>
                                  <div>{renderText(row[colIndex], column)}</div>
                                </TableCell>
                              );
                            }
                          )}
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 50, 100, 250]}
              component="div"
              count={count}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              labelRowsPerPage={<Translate value="tabla.filasPorPagina" />}
              labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${I18n.t('tabla.de')} ${count !== -1 ? count : `${I18n.t('tabla.masDe')} ${to}`}`}
            />
          </React.Fragment>
        {/* )} */}
      </Paper>
      {/* {modalFormLibroClase && (
        <FormLibroClase handleClose={handleClose} libroClase={libroClase} handleSave={handleSaveLibroClase} isModalOpen={modalFormLibroClase} />
      )} */}
    </React.Fragment>
  );
};

const useStyles = makeStyles({
  root: {
    width: "100%",
    position: 'relative',
  },
  container: {
    // maxHeight: 440,
    // maxHeight: 'calc(100vh - 350px)',
  },
  filterBox: {
    display: 'flex',
    justifyContent: 'left',
    height: 0,
    alignItems: 'center',
    overflow: 'hidden',
    transition: 'height .5s, marginTop .5s',
    background: '#eee',
    paddingLeft: '16px',
  },
  filterBoxOpen: {
    overflowX: 'auto',
    height: '64px',
  },
  btnAction: {
    margin: "0 3px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  formControl: {
    width: "200px",
    padding: "0 0 0 20px",
  },
  input: {
    margin: 0,
  },
  filterItem: {
    minWidth: '190px',
    margin: '0 16px',
  },
  resaltado: {
    backgroundColor: '#ffcccc',
    borderColor: 'grey',
  },
  textHtml: {
    '& p': {
      marginTop: 0,
      padding: 0,
    }
  }
});

export default forwardRef(NotificationTable);
