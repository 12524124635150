import { TextField, Box, DialogContent, Grid, Typography, Button, DialogActions, Theme, makeStyles, createStyles } from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from "@date-io/date-fns";
import esl from 'date-fns/locale/es';
import { useEffect, useState } from 'react';
import { RootState, useStateSelector } from 'store/reducers';
import { useDispatch } from 'react-redux';
import { setFormEstudiante } from 'store/actions/estudiante_actions';
const urlFile = process.env.REACT_APP_FILE_URL;

type StepEstudianteProps = {
  handleClose: () => void;
  handleSave: (data: unknown) => void;
}

type StepEstudianteData = {
  id?: string,
  email: string;
  password: string;
  codigoRude: string;
  codigoEstudiante: string;
  foto: string;
  nombres: string;
  fechaNacimiento: Date | string;
  primerApellido: string;
  segundoApellido: string;
  nombreCorto: string;
  celular: string;
  direccion: string;
  ci: string;
  ciudad: string;
  estado: string;
  imagen: any;
  imagenData: any;
  alergia1: string;
  alergia2: string;
  alergia3: string;
}

export const StepEstudiante: React.FC<StepEstudianteProps> = ({
  handleClose,
  handleSave,
}) => {
  const { formEstudiante } = useStateSelector((state: RootState) => state.estudianteState);
  console.log(formEstudiante);
  const { handleSubmit, control, setValue, getValues } = useForm<StepEstudianteData>({
    shouldUnregister: false
  });
  const classes = useStyles();
  const dispatch = useDispatch();
  const [foto, setFoto] = useState<any>(null);
  const [src, setSrc] = useState<any>(null);

  useEffect(() => {
    if (formEstudiante?.hijo) {
      setValue("id", formEstudiante.hijo.id);
      setValue("email", formEstudiante.hijo.email);
      setValue("password", formEstudiante.hijo.password);
      setValue("codigoRude", formEstudiante.codigoRude);
      setValue("codigoEstudiante", formEstudiante.codigoEstudiante);
      setValue("imagen", formEstudiante.hijo.imagen);
      setValue("imagenData", formEstudiante.hijo.imagenData);
      setValue("nombres", formEstudiante.hijo.nombres);
      setValue("fechaNacimiento", formEstudiante.hijo.fechaNacimiento);
      setValue("primerApellido", formEstudiante.hijo.primerApellido);
      setValue("segundoApellido", formEstudiante.hijo.segundoApellido);
      setValue("nombreCorto", formEstudiante.hijo.nombreCorto);
      setValue("celular", formEstudiante.hijo.celular);
      setValue("direccion", formEstudiante.hijo.direccion);
      setValue("ci", formEstudiante.hijo.ci);
      setValue("ciudad", formEstudiante.hijo.ciudad);
      setValue("estado", formEstudiante.hijo.estado);
      setValue("alergia1", formEstudiante.hijo.alergia1);
      setValue("alergia2", formEstudiante.hijo.alergia2);
      setValue("alergia3", formEstudiante.hijo.alergia3)

      const fileName = formEstudiante.hijo.imagen;
      if (fileName && fileName.indexOf('base64') === -1 && fileName.indexOf('http') === -1) {
        const newSrc = fileName ? `${urlFile}/${fileName}` : null;
        setSrc(newSrc);
        setFoto({ name: fileName });
      }

      if (formEstudiante.hijo.imagenData) {
        const reader = new FileReader();
        reader.onload = function (e) {
          const newSrc = e?.target?.result;
          setSrc(newSrc);
        };
        reader.readAsDataURL(formEstudiante.hijo.imagenData[0]);
        setFoto({ name: `00000000-0000-0000-0000-000000000000-${formEstudiante.hijo.imagenData[0].name}` });
      }
    }
  }, [formEstudiante, setValue]);

  const onSubmit = async (data: any) => {
    handleSave(data);
  }

  const uploadFile = () => {
    const inputFile = document.getElementById("img-estudiante");
    inputFile?.click();
  };

  const handleDragOver = (event: React.DragEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDrop = (event: React.DragEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setValue('imagenData', event.dataTransfer.files);
    mostrarImagen(event.dataTransfer.files);
  };

  const handleFileSelected = (files: FileList | null) => {
    if (files) {
      setValue('imagenData', files);
      mostrarImagen(files);
    }
  };

  function mostrarImagen(files: any) {
    if (files.length) {
      const formValues = getValues();
      const _data = {
        ...formEstudiante,
        codigoRude: formValues.codigoRude,
        codigoEstudiante: formValues.codigoEstudiante,
        hijo: {
          ...formEstudiante.hijo,
          ...formValues,
          imagenData: files,
        },
      };
      dispatch(setFormEstudiante(_data));
    }
  }

  const handleChangePhoto = () => {
    setFoto(null);
    setSrc(null);
    const formValues = getValues();
    const _data = {
      ...formEstudiante,
      codigoRude: formValues.codigoRude,
      codigoEstudiante: formValues.codigoEstudiante,
      hijo: {
        ...formEstudiante.hijo,
        ...formValues,
        imagen: '',
        imagenData: null,
      },
    };
    dispatch(setFormEstudiante(_data));
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <DialogContent dividers>
        <Box display='flex' justifyContent='center' mb={2}>
          <Box className={classes.activeStep}></Box>
          <Box className={classes.inactiveStep}></Box>
          <Box className={classes.inactiveStep}></Box>
        </Box>
        <Typography variant='h6' style={{ fontWeight: 'bold' }}>
          Datos del estudiante
        </Typography>
        <Box mb={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <Controller
                name="email"
                control={control}
                defaultValue=""
                render={({ field }) => <TextField {...field} className={classes.formControl} variant='filled' autoComplete={'new-password'} required label="Correo electrónico" />}
              />
            </Grid>
            { !formEstudiante?.hijo?.id && (
              <Grid item xs={12} sm={4}>
                <Controller
                  name="password"
                  control={control}
                  defaultValue=""
                  render={({ field }) => <TextField {...field} className={classes.formControl} variant='filled' autoComplete={'new-password'} required label="Contraseña" type="password" />}
                />
              </Grid>
            )}
            <Grid item xs={12} sm={4}>
              <Controller
                name="codigoRude"
                control={control}
                defaultValue=""
                render={({ field }) => <TextField {...field} className={classes.formControl} variant='filled' label="Código RUDE" />}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Controller
                name="codigoEstudiante"
                control={control}
                defaultValue=""
                render={({ field }) => <TextField {...field} className={classes.formControl} variant='filled' label="Código de estudiante" />}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Controller
                name="nombres"
                control={control}
                defaultValue=""
                render={({ field }) => <TextField {...field} className={classes.formControl} variant='filled' required label="Nombre(s)" />}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Controller
                name="primerApellido"
                control={control}
                defaultValue=""
                render={({ field }) => <TextField {...field} className={classes.formControl} variant='filled' required label="Primer apellido" />}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Controller
                name="segundoApellido"
                control={control}
                defaultValue=""
                render={({ field }) => <TextField {...field} className={classes.formControl} variant='filled' label="Segundo apellido" />}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Controller
                name="nombreCorto"
                control={control}
                defaultValue=""
                render={({ field }) => <TextField {...field} className={classes.formControl} variant='filled' label="Nombre corto" />}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
            <MuiPickersUtilsProvider locale={esl} utils={DateFnsUtils}>
                  <Controller
                    name="fechaNacimiento"
                    control={control}
                    defaultValue={null}
                    render={({ field: { ref, ...rest } }) => (
                      <KeyboardDatePicker
                        className={classes.formControl}
                        inputVariant="filled"
                        required
                        label={'Fecha de nacimiento'}
                        autoOk
                        openTo="date"
                        disableFuture
                        views={["year", "month", "date"]}
                        format="dd/MM/yyyy"
                        {...rest}
                      />
                    )}
                  />
                </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Controller
                name="celular"
                control={control}
                defaultValue=""
                render={({ field }) => <TextField {...field} className={classes.formControl} variant='filled' label="Celular" />}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Controller
                name="direccion"
                control={control}
                defaultValue=""
                render={({ field }) => <TextField {...field} className={classes.formControl} variant='filled' label="Dirección" />}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Controller
                name="ci"
                control={control}
                defaultValue=""
                render={({ field }) => <TextField {...field} className={classes.formControl} variant='filled' label="Cédula de identidad" />}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Controller
                name="ciudad"
                control={control}
                defaultValue=""
                render={({ field }) => <TextField {...field} className={classes.formControl} variant='filled' label="Ciudad" />}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="alergia1"
                control={control}
                defaultValue=""
                render={({field}) => <TextField { ...field } className={classes.formControl} variant='filled' label="Primera Alergia" />}
              ></Controller>
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="alergia2"
                control={control}
                defaultValue=""
                render={({field}) => <TextField { ...field } className={classes.formControl} variant='filled' label="Segunda Alergia" />}
              ></Controller>
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="alergia3"
                control={control}
                defaultValue=""
                render={({field}) => <TextField { ...field } className={classes.formControl} variant='filled' label="Tercera Alergia" />}
              ></Controller>
            </Grid>

            <Grid item xs={12}>
              {foto?.name && (
                <div className={classes.previewContent}>
                  <strong>{foto.name.substr(37)}</strong> <br />
                  {src && <img src={src} alt="Vista previa" className={classes.preview} />} <br />
                  <Button onClick={handleChangePhoto} className={classes.btnError}>
                    Cambiar foto
                  </Button>
                </div>
              )}
              {!foto?.name && <Box
                className={classes.boxDragNDrop}
                onDragOver={handleDragOver}
                onDrop={handleDrop}
                onClick={uploadFile}
              >
                Arrastre o suba la imagen del estudiante aquí.
                <input
                  id="img-estudiante"
                  type="file"
                  hidden
                  onChange={(e) => handleFileSelected(e.target.files)}
                />
              </Box>}
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} className={classes.btnError}>
          Cancelar
        </Button>
        <Button type="submit" className={classes.btnSave}>
          Aceptar y Continuar
        </Button>
      </DialogActions>
    </form>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    btnSave: {
      padding: theme.spacing(1, 4),
      backgroundColor: theme.palette.success.main,
      // margin: '0 3px',
      "&:hover": {
        backgroundColor: theme.palette.success.light,
      },
    },
    btnError: {
      padding: theme.spacing(1, 4),
      backgroundColor: theme.palette.error.main,
      // margin: '0 3px',
      "&:hover": {
        backgroundColor: theme.palette.error.light,
      },
    },
    formControl: {
      margin: theme.spacing(1),
      width: "100%",
      // minWidth: '100%',
    },
    stepRoot: {
      display: 'flex',
      justifyContent: 'center',
    },
    small: {
      width: theme.spacing(3),
      height: theme.spacing(3),
      backgroundColor: theme.palette.primary.main
    },
    activeStep: {
      margin: theme.spacing(0, 1),
      width: theme.spacing(2),
      height: theme.spacing(2),
      backgroundColor: theme.palette.primary.main,
      borderRadius: '50%'
    },
    inactiveStep: {
      margin: theme.spacing(0, 1),
      width: theme.spacing(2),
      height: theme.spacing(2),
      backgroundColor: theme.palette.grey[400],
      borderRadius: '50%'
    },
    label: {
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.common.black
    },
    boxDragNDrop: {
      textAlign: 'center',
      padding: theme.spacing(4),
      color: theme.palette.common.black,
      backgroundColor: theme.palette.secondary.main,
      border: `2px dashed ${theme.palette.primary.main}`,
      cursor: 'pointer'
    },
    preview: {
      width: '100%',
      maxWidth: '150px',
      padding: 4,
      border: '1px solid #ccc',
      borderRadius: 3
    },
    previewContent: {
      '& button': {
        padding: '3px 15px'
      }
    }
  })
);