import { combineReducers } from 'redux';
import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { i18nReducer } from "react-redux-i18n";

// reducers
import profesorReducer, { State as ProfesorState } from './reducers/profesorReducer';
import userReducer, { State as UserState } from './reducers/userReducer';
import circularReducer, { State as CircularState } from './reducers/circularReducer';
import cursoReducer, { State as CursoState } from './reducers/cursoReducer';
import materiaReducer, { State as MateriaState } from './reducers/materiaReducer';
import eventoReducer, { State as EventoState } from './reducers/eventoReducer';
import permisoReducer, { State as PermisoState } from './reducers/permisoReducer';
import reporteReducer, { State as ReporteState } from './reducers/reporteReducer';
import estudianteReducer, { State as EstudianteState } from './reducers/estudianteReducer';
import authReducer, { State as AuthState } from './reducers/authReducer';
import libroDeCursoReducer, { State as LibroDeCursoState } from './reducers/libroDeCursoReducer';
import asistenciaKinderReducer, { State as AsistenciaKinderState } from './reducers/asistenciaKinderReducer';
import skillsReducer, { State as SkillsState } from './reducers/skillsReducer';
import ticketReducer, { State as TicketsState } from './reducers/ticketReducer';
import certificateReducer, { State as CertificateState } from './reducers/certificateReducer';

export type RootState = {
  profesorState: ProfesorState,
  userState: UserState,
  circularState: CircularState,
  cursoState: CursoState,
  materiaState: MateriaState,
  eventoState: EventoState,
  permisoState: PermisoState,
  estudianteState: EstudianteState,
  authState: AuthState,
  reporteState: ReporteState,
  i18n: any,
  libroDeCursoState: LibroDeCursoState,
  asistenciaKinderState: AsistenciaKinderState,
  skillsState: SkillsState
  ticketState: TicketsState
  certificateState: CertificateState
}

export const useStateSelector: TypedUseSelectorHook<RootState> = useSelector

const rootReducer = combineReducers<RootState>({
  profesorState: profesorReducer,
  userState: userReducer,
  circularState: circularReducer,
  cursoState: cursoReducer,
  materiaState: materiaReducer,
  eventoState: eventoReducer,
  permisoState: permisoReducer,
  estudianteState: estudianteReducer,
  authState: authReducer,
  reporteState: reporteReducer,
  i18n: i18nReducer,
  libroDeCursoState: libroDeCursoReducer,
  asistenciaKinderState: asistenciaKinderReducer,
  skillsState: skillsReducer,
  ticketState: ticketReducer,
  certificateState: certificateReducer,
})

export default rootReducer