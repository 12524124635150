import http from "./http";
import { Evento } from "../models/evento_model";

export const getEventos = async () => {
  try {
    return await http.get(`/evento/`);
  } catch (error) {
    console.error("[get] Error :: ", error);
    return error;
  }
};

export const getEventoById = async (id: string) => {
  try {
    return await http.get(`/evento/${id}`);
  } catch (error) {
    console.error('[get_by_id] error :: ', error);
    return error;
  }
}

export const createEvento = async (data: Evento) => {
  try {
    return await http.post(`/evento/`, data);
  } catch (error) {
    console.error("[create] Error :: ", error);
    return error;
  }
};

export const createAllEventos = async (data: Evento[]) => {
  try {
    return await http.post(`/evento/`, { eventos: data });
  } catch (error) {
    console.error('Error al crear array de eventos ', error)
    return error;
  }
}

export const editEvento = async (data: Evento, id: string) => {
  try {
    return await http.put(`/evento/${id}`, data);
  } catch (error) {
    console.error("[put] Error :: ", error);
    return error;
  }
};

export const deleteEvento = async (id: string) => {
  try {
    return await http.delete(`/evento/${id}`);
  } catch (error) {
    console.error("[delete] Error :: ", error);
    return error;
  }
};
