import { CURSO_TYPES, Action } from "../types";
import { Curso } from "../../models/curso_model";

export type State = {
  cursos: Curso[] | [];
  curso: Curso | null;
  stepForm: number;
  formCurso: Curso;
};

const initialState: State = {
  cursos: [],
  curso: null,
  stepForm: 1,
  formCurso: {
    nivel: '',
    paralelo: '',
    grado: 0,
    turno: '',
    codigoCurso: '',
    gestion: 2021,
    fechaInicio: new Date(),
    fechaFin: new Date(),
    profesorId: '',
    horarios: []
  }
};

const reducer = (state: State = initialState, { type, payload }: Action) => {
  switch (type) {
    case CURSO_TYPES.CARGAR_CURSOS:
      return {
        ...state,
        cursos: payload,
      };
    case CURSO_TYPES.CHANGE_CURSO:
      return {
        ...state,
        curso: payload
      }
    case CURSO_TYPES.SET_STEP_FORM:
      return {
        ...state,
        stepForm: payload
      }
    case CURSO_TYPES.SET_FORM_CURSO:
      return {
        ...state,
        formCurso: {
          ...state.formCurso,
          ...payload
        }
      }
    default:
      return state;
  }
};

export default reducer;
