import { Estudiante } from "../../models/estudiante_model";
import { createEstudiante, getEstudiantes, getEstudiantesConInscripciones, editEstudiante, deleteEstudiante, changeCursoEstudiante } from "../../repositories/estudiante_repository";
import { ESTUDIANTE_TYPES } from "../types";


export const cargarEstudiantes = (cursoFilter?: string, turnoFilter?: string, nombreFilter?: string) => {
  return async (dispatch: any) => {
    const estudiantes = await getEstudiantes(cursoFilter, turnoFilter, nombreFilter);

    dispatch(setEstudiantes(estudiantes || [] as any));
  }
}

export const cargarEstudiantesConInscripciones = (cursoFilter?: string, turnoFilter?: string, nombreFilter?: string, gestionFilter?: string) => {
  return async (dispatch: any) => {
    const estudiantes = await getEstudiantesConInscripciones(cursoFilter, turnoFilter, nombreFilter, gestionFilter);

    dispatch(setEstudiantes(estudiantes || [] as any));
  }
}

export const agregarEstudiante = (data: any, file?: any) => {
  return async (dispatch: any) => {
    await createEstudiante(data, file);
  }
}

export const editarEstudiante = (data: any, estudianteId: string, file?: any) => {
  return async (dispatch: any) => {
    await editEstudiante(data, estudianteId, file);
  }
}

export const eliminarEstudiante = (estudianteId: string, cursoId: string) => {
  return async (dispatch: any) => {
    await deleteEstudiante(estudianteId, cursoId);
  }
}

export const cambiarCursoEstudiante = (estudianteId: string, cursoId: string, nuevoCurso: string) => {
  return async (dispatch: any) => {
    await changeCursoEstudiante(estudianteId, cursoId, nuevoCurso);
  }
}

export const changeEstudiante = (estudiante: Estudiante | null) => ({
  type: ESTUDIANTE_TYPES.SET_ESTUDIANTE,
  payload: estudiante
})

export const setFormEstudiante = (value: any) => ({
  type: ESTUDIANTE_TYPES.SET_FORM_ESTUDIANTE,
  payload: {...value}
})

export const setEstudiantes = (estudiantes: Estudiante[]) => ({
  type: ESTUDIANTE_TYPES.CARGAR_ESTUDIANTES,
  payload: [...estudiantes]
})