import {
  Typography,
  Grid,
  Paper,
  makeStyles,
  createStyles,
  Theme,
  Button,
  Box,
  Tooltip
} from "@material-ui/core";
import { Translate } from "react-redux-i18n";
import Exclamation from '../../../../../../../assets/img/icons/exclamacion_tema.svg';

const Event = (props:any) => {
  const classes = useStyles();

  return(
    <Paper className={classes.paper} >
      <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h5" className={`${props.event.isHoliday ? classes.holiday : classes.eventDay}`}>
              {props.event.start ? props.event.start.format('D') : ''}
            </Typography>
          </Grid>
          {!props.event.isHoliday ?
            <Grid item xs={12} container>
              {
                props.event.title ? 
                  <Paper className={classes.topicContainer}>
                    <Tooltip arrow title={
                      <Typography>
                        <Translate value="kinder.tema" />: {props.event.title}
                      </Typography>}
                    >
                      <Grid item className={classes.topic} xs={12} >
                        <div className={classes.textBox}>
                          <Typography variant="body2" noWrap={true}>
                            <Translate value="kinder.tema" />: {props.event.title}
                          </Typography>
                        </div>
                      </Grid>
                    </Tooltip>
                    { props.event && props.event.colorTitle ? 
                      <Tooltip arrow title={
                        <Typography>
                          {props.event.colorTitle}
                        </Typography>}
                      >
                        <Grid item className={classes.color} xs={12}>
                          <div className={classes.textBox}>
                            <Typography variant="body2" noWrap={true}>
                              {props.event.colorTitle}
                            </Typography>
                          </div>
                        </Grid>
                      </Tooltip> : null
                    }
                  </Paper> : 
                  <Paper className={classes.topicEmptyContainer}>
                    <Tooltip arrow title={
                      <Typography variant="body2" noWrap={true}>
                        <Translate value="kinder.crearNuevoTema" />
                      </Typography>}
                    >
                      <Grid item container justify="center" className={classes.emptyTopic} xs={12}>
                        <Box display='flex' my={0} mx={0}>
                          <Button style={{ padding:'0px 0px 0px 0px', width: '15px', minWidth:'15px'}} disabled startIcon={<img style={{ margin: '0px 0px 0px 0px', width:'13px', height: '13px'  }} src={Exclamation} alt="presente icon" />}> </Button>
                          <Typography variant="body2" noWrap={true}>
                            <Translate value="kinder.crearNuevoTema" />
                          </Typography>
                        </Box>
                      </Grid>
                    </Tooltip>
                    { props.event && props.event.colorTitle ? 
                      <Tooltip arrow title={
                        <Typography>
                          {props.event.colorTitle}
                        </Typography>}
                      >
                        <Grid item className={classes.color} xs={12}>
                          <Typography variant="body2" noWrap={true}>
                            {props.event.colorTitle}
                          </Typography>
                        </Grid>
                      </Tooltip> : null
                    }
                  </Paper>
              }
              
            </Grid> : null
          }
      </Grid>
    </Paper>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    topicContainer: {
      borderRadius: '8% 8% 8% 8%',
      width: '100%'
    },
    topicEmptyContainer: {
      borderRadius: '8% 8% 8% 8%',
      width: '100%'
    },
    paper: {
      backgroundColor: 'transparent',
      padding: theme.spacing(2),
      margin: 'auto',
      maxWidth: 500,
      maxHeight: 130,
      height: 130,
      textAlign: 'center'
    },
    eventDay: {
      color: '#EBAB88',
      fontWeight: 'bold'
    },
    holiday: {
      color: '#323232'
    },
    emptyTopic: {
      backgroundColor: '#F0968A',
      border: '1px solid #F0968A',
      color: '#FFFFFF'
    },
    topic: {
      backgroundColor: '#CCDCC0',
      border: '1px solid #99BA82',
      color: '#FFFFFF',
    },
    color: {
      backgroundColor: '#D3BFF1',
      border: '1px solid #A898C0',
      color: '#FFFFFF',
      marginTop: '7px'
    },
    textBox: {
      maxWidth: '200px',
      //border: '1px solid #000000',
      // margin: '3px',
      textAlign:'center',
      position: 'relative',
      display:'block',
      //float:'left',
      overflow:'hidden',
      marginLeft: 'auto',
      marginRight: 'auto',
      //paddingBottom:'24px',
      //color: 'pink'
    }
  })
);

export default Event;