import { ThemeProvider } from "@material-ui/styles"
import { Switch, BrowserRouter as Router } from "react-router-dom"
import AuthRouter from "./AuthRouter"
import PrivateRoute from "./PrivateRoute"
import PublicRoute from "./PublicRoute"
import theme from '../plugins/theme';
import AccountRouter from "./AccountRouter"
import { useDispatch } from "react-redux"
import { useEffect, useState } from "react"
import { firebaseAuth } from "../plugins/firebase"
import { asyncLogout, login } from "../store/actions/auth_actions"
import { getSession } from "repositories/auth_repository"
import { setLocale } from 'react-redux-i18n';

const AppRouter = () => {
  const dispatch = useDispatch()

  const isAutenticated = localStorage.getItem('caleman_usuario') !== null;
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(isAutenticated);

  useEffect(() => {
    const unsubscribe = firebaseAuth.onAuthStateChanged( async (user) => {
      const token = user ? await user.getIdToken() : null;
      const loginResponse: any = token ? await getSession(token) : null;

      if (user && (loginResponse && !loginResponse.error)) {
        // Verifica que tenga al menos un módulo al que pueda acceder
        if (!loginResponse.rutas || loginResponse.rutas.length === 0) {
          dispatch(asyncLogout());
          return;
        }

        // RESTAURA EL IDIOMA DESDE EL BACKEND
        const newLocale = loginResponse.idioma === 'es_ES' ? 'es' : 'de';
        localStorage.setItem('locale', newLocale);
        dispatch(setLocale(newLocale));
        // RESTAURA EL IDIOMA DESDE EL BACKEND

        dispatch( login(user) );
        window.localStorage.setItem(
          'user',
          JSON.stringify({
            email: user.email,
            rol: loginResponse.rol,
            claims: loginResponse.claims,
          }),
        )
        setIsLoggedIn(true);
      } else {
        setIsLoggedIn(false);
        // window.localStorage.clear();
      }
    });
    return () => unsubscribe();
  }, [dispatch, setIsLoggedIn])

  return (
    <Router>
      <ThemeProvider theme={theme}>
        <Switch>
          <PublicRoute
            Componente={ AuthRouter }
            isAuthenticated={ isLoggedIn }
            path="/auth"
          />
          <PrivateRoute
            Componente={ AccountRouter }
            isAuthenticated={ isLoggedIn }
            path="/"
          />
        </Switch>
      </ThemeProvider>
    </Router>
  )
}

export default AppRouter
