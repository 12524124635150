import Util from '../utils';

const DateTime = {
  formatDate: "dd/MM/YYYY",
  months: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto','Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
  monthsShort: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
  days: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
  dayslong: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],

  format (date: Date | string | null, format?: string) {
    if (date) {
      const d = new Date(date);
      return this.replace(d, format || this.formatDate);
    }
  },

  replace (date: Date, format: string) {
    format = format || this.formatDate;
    // date = this.standar(date, format);
    const monthLiteral = format.indexOf('MMM') !== -1;
    return Util.replace(format,
      ['dddd', 'ddd', 'dd', monthLiteral ? 'MMM' : 'MM', 'YYYY', 'HH', 'mm', 'ss'],
      [
        this.dayslong[date.getDay()],
        this.days[date.getDay()],
        (date.getUTCDate() < 10 ? '0' : '') + date.getUTCDate(),
        monthLiteral ? this.months[date.getUTCMonth()] : ((date.getUTCMonth() + 1 < 10
          ? '0' : '') + (date.getUTCMonth() + 1)),
        date.getUTCFullYear(),
        (date.getHours() < 10 ? '0' : '') + date.getHours(),
        (date.getMinutes() < 10 ? '0' : '') + date.getMinutes(),
        (date.getSeconds() < 10 ? '0' : '') + date.getSeconds()
      ]);
  }
}

export default DateTime;