import { TextField, Box, DialogContent, Grid, Typography, Button, DialogActions, Theme, makeStyles, createStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { cargarCursos } from '../../../../../store/actions/curso_actions';
import { RootState, useStateSelector } from '../../../../../store/reducers';
import { Autocomplete } from '@material-ui/lab';
import { Curso } from '../../../../../models/curso_model';

type StepCursoEstudianteProps = {
  handleClose: () => void;
  handleSave: (data: unknown) => void;
}

export const StepCursoEstudiante: React.FC<StepCursoEstudianteProps> = ({
  handleClose,
  handleSave,
}) => {
  const { estudiante } = useStateSelector((state: RootState) => state.estudianteState);
  const classes = useStyles();
  const { cursos } = useStateSelector((state: RootState) => state.cursoState);
  const [curso, setCurso] = useState<Curso | null>(null);
  const [nuevoCurso, setNuevoCurso] = useState<Curso | null>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (estudiante) {
      const cursoSeleccionado = cursos.find(curso => curso.id === estudiante.cursoId);
      setCurso(cursoSeleccionado || null);
      setNuevoCurso(cursoSeleccionado || null);
    }
  }, [cursos, estudiante]);

  useEffect(() => {
    dispatch( cargarCursos() );
  }, [dispatch])

  const onSubmit: React.FormEventHandler<HTMLFormElement> = async (event) => {
    event.preventDefault();
    if (nuevoCurso) {
      handleSave({
        estudianteId: estudiante?.id,
        cursoId: curso?.id,
        nuevoCurso: nuevoCurso?.id,
      });
    }
  }

  return (
    <form onSubmit={onSubmit}>
      <DialogContent dividers>
        <Typography gutterBottom align='center'>
          <strong>Estudiante: </strong> {estudiante?.nombreCompleto}
        </Typography>
        <Typography gutterBottom style={{ fontWeight: "bold" }}>
          1. Curso actual:
        </Typography>
        <Box my={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Autocomplete
                options={cursos}
                autoHighlight
                getOptionSelected={(option, value) => value.id === option.id}
                getOptionLabel={(option) => `[${option.codigoCurso} - ${option.gestion}] Nivel: ${option.nivel} - Grado: ${option.grado} - Paralelo: ${option.paralelo} - Turno: ${option.turno}`}
                renderOption={(option) => (
                  <React.Fragment>
                      <Box>
                          <Typography variant="body2"><strong>{option.codigoCurso} - {option.gestion}</strong></Typography>
                          <Typography variant="caption">Nivel: {option.nivel} - Grado: {option.grado} - Paralelo: {option.paralelo} - Turno: {option.turno}</Typography>
                      </Box>
                  </React.Fragment>
                )}
                value={curso}
                onChange={(event: any, newValue: Curso | null) => {
                  setCurso(newValue);
                }}
                disabled
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Curso"
                    variant="filled"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password', // disable autocomplete and autofill
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Box>

        <Typography gutterBottom style={{ fontWeight: "bold" }}>
          2. Nuevo curso:
        </Typography>
        <Box my={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Autocomplete
                options={cursos}
                autoHighlight
                getOptionSelected={(option, value) => value.id === option.id}
                getOptionLabel={(option) => `[${option.codigoCurso} - ${option.gestion}] Nivel: ${option.nivel} - Grado: ${option.grado} - Paralelo: ${option.paralelo} - Turno: ${option.turno}`}
                renderOption={(option) => (
                  <React.Fragment>
                      <Box>
                          <Typography variant="body2"><strong>{option.codigoCurso} - {option.gestion}</strong></Typography>
                          <Typography variant="caption">Nivel: {option.nivel} - Grado: {option.grado} - Paralelo: {option.paralelo} - Turno: {option.turno}</Typography>
                      </Box>
                  </React.Fragment>
                )}
                value={nuevoCurso}
                onChange={(event: any, newValue: Curso | null) => {
                  setNuevoCurso(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="-Busca un curso-"
                    variant="filled"
                    required
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password', // disable autocomplete and autofill
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} className={classes.btnError}>
          Cancelar
        </Button>
        <Button type="submit" className={classes.btnSave}>
          Aceptar y Guardar
        </Button>
      </DialogActions>
    </form>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    btnSave: {
      padding: theme.spacing(1, 4),
      backgroundColor: theme.palette.success.main,
      // margin: '0 3px',
      "&:hover": {
        backgroundColor: theme.palette.success.light,
      },
    },
    btnError: {
      padding: theme.spacing(1, 4),
      backgroundColor: theme.palette.error.main,
      // margin: '0 3px',
      "&:hover": {
        backgroundColor: theme.palette.error.light,
      },
    },
    formControl: {
      margin: theme.spacing(1),
      width: "100%",
      // minWidth: '100%',
    },
    stepRoot: {
      display: 'flex',
      justifyContent: 'center',
    },
    small: {
      width: theme.spacing(3),
      height: theme.spacing(3),
      backgroundColor: theme.palette.primary.main
    },
    activeStep: {
      margin: theme.spacing(0, 1),
      width: theme.spacing(2),
      height: theme.spacing(2),
      backgroundColor: theme.palette.primary.main,
      borderRadius: '50%'
    },
    inactiveStep: {
      margin: theme.spacing(0, 1),
      width: theme.spacing(2),
      height: theme.spacing(2),
      backgroundColor: theme.palette.grey[400],
      borderRadius: '50%'
    },
    label: {
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.common.black
    },
    btnBack: {
      padding: theme.spacing(1, 4),
      backgroundColor: theme.palette.grey[400],
      // margin: '0 3px',
      "&:hover": {
        backgroundColor: theme.palette.grey[200],
      },
    },
  })
);