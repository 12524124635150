import { AsistenciaKinder } from "models/asistencia_kinder_model"; 
import { getKinderAssistanceByDate, saveAssistance } from '../../repositories/asistencia_kinder_repository';
import { KINDER_ASSISTANCE_TYPES } from "store/types";

export const getKinderAssistance = (date?:moment.Moment) => {
  return async (dispatch:any) => {
    const asistenciaKinder:any = await getKinderAssistanceByDate(date);
    
    dispatch( setKinderAssistance(asistenciaKinder || []) );
  }
};

export const setKinderAssistance = (asistenciaKinder:AsistenciaKinder) => ({
  type: KINDER_ASSISTANCE_TYPES.GET_KINDER_ASSISTANCE,
  payload: [...asistenciaKinder]
});

export const updateAssistance = (data: AsistenciaKinder, date?:string) => {
  return async (dispatch: any) => {
    await saveAssistance(data, date);
  }
}
