import { USER_TYPES, Action } from "../types";
import { IUser } from "../../models/user_model";

export type State = {
  users: IUser[] | [];
};

const initialState: State = {
  users: [],
};

const reducer = (state: State = initialState, { type, payload }: Action) => {
  switch (type) {
    case USER_TYPES.LOAD_USERS:
      return {
        ...state,
        users: payload,
      };
    default:
      return state;
  }
};

export default reducer;
