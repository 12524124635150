import React, { useEffect } from "react";
import { useHistory } from "react-router";
import {
  Button,
  IconButton,
  Box,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  makeStyles,
  useTheme,
  createStyles,
  Theme,
  Chip,
  Tooltip,
  TextField
} from "@material-ui/core";
import { Refresh, FilterList, Search } from '@material-ui/icons';
import MainLayout from "../../components/layout/MainLayout";
import { CSwitch } from "../../components/core/CSwitch";
import FormUser from "./components/FormUser";
import { RootState, useStateSelector } from "../../store/reducers";
import { useDispatch } from "react-redux";
import { addUsuario, cargarUsuarios, deleteUsuario, editUsuario } from "../../store/actions/user";
import { IUser } from "../../models/user_model";
import message from "../../plugins/utils/message";
import Util from "../../plugins/utils";
// import BackIcon from '../../assets/img/icons/arrowBack.svg';
// import EditIcon from '../../assets/img/icons/edit.svg';
// import DeleteIcon from '../../assets/img/icons/trash.svg';

interface Column {
  id: "actions" | "name" | "active" | "email" | "rol" | "state";
  label: string;
  minWidth?: number;
  align?: "right";
  format?: (value: number) => string;
}

const columns: Column[] = [
  {
    id: "actions",
    label: "Acciones",
    // minWidth: 170
  },
  {
    id: "active",
    label: "Activo",
    // minWidth: 100
  },
  {
    id: "name",
    label: "Nombre",
    // minWidth: 170,
    // align: "right",
    // format: (value: number) => value.toLocaleString("es-ES"),
  },
  {
    id: "email",
    label: "Correo electrónico",
    // minWidth: 170,
    // align: "right"
  },
  {
    id: "rol",
    label: "Rol",
  },
  {
    id: "state",
    label: "Estado",
  },
];

const User: React.FC = () => {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [user, setUser] = React.useState<IUser | null>(null);

  const { users } = useStateSelector((state: RootState) => state.userState);

  useEffect(() => {
    dispatch( cargarUsuarios() )
  }, [dispatch])

  const handleRefreshUsuarios = () => dispatch( cargarUsuarios() );

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const toHome = () => {
    history.push('/');
  }

  const [modalFormUser, setModalFormUser] = React.useState(false);

  const handleClickOpen = () => {
    setModalFormUser(true);
  };

  const handleClose = () => {
    setModalFormUser(false);
    setUser(null);
  };

  // const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setState({ ...state, [event.target.name]: event.target.checked });
  // };

  const handleEditUser = (user: IUser) => {
    setUser(user);
    handleClickOpen();
  }

  const handleEditState = async (user: IUser) => {
    const changed = await message.confirm(`¿Está segura(o) de ${user?.estado === 'ACTIVO' ? 'inactivar': 'activar'} este usuario?`)
    if (changed) {
      handleChangeState(user)
    }
  }

  const handleChangeState = (user: IUser) => {
    if (user.id) {
      dispatch(editUsuario({ estado: user.estado === 'ACTIVO' ? 'INACTIVO' : 'ACTIVO' }, user.id));
    }

    setTimeout(() => {
      handleRefreshUsuarios();
    }, 1000);
  }

  const handleDeleteUser = async (id: string) => {
    const deleted = await message.confirm("¿Desea eliminar el usuario?")
    
    if (deleted) {
      dispatch( deleteUsuario(id) );
  
      setTimeout(() => {
        handleRefreshUsuarios();
      }, 1000);
    }
  }

  // Filters
  const [filter, setFilter] = React.useState(false);
  const [nombreFilter, setNombreFilter] = React.useState('');
  const [emailFilter, setEmailFilter] = React.useState('');

  const handleFilter = () => {
    setFilter(!filter);
  }

  const handleNombreFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNombreFilter(event.target.value)
  }

  const handleEmailFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmailFilter(event.target.value)
  }

  const handleSubmitFilter = () => {
    dispatch( cargarUsuarios(nombreFilter, emailFilter) )
    setPage(0);
  }

  const handleFilterCancel = () => {
    setFilter(false)
    setNombreFilter('')
    setEmailFilter('')
    handleRefreshUsuarios();
  }

  const handleSaveUser = (data: IUser) => {
    if (user?.id) {
      dispatch( editUsuario({...data, estado: user.estado}, user.id) );
    } else {
      dispatch( addUsuario(data) );
    }

    message.success(`${user?.id ? 'Edición' : 'Creación'} del usuario en proceso, por favor espere un momento.`)

    setTimeout(() => {
      dispatch( cargarUsuarios() );
    }, 2000);

    handleClose()
  }

  return (
    <MainLayout>
      <Box mb={2} display='flex' alignItems='center'>
        <IconButton onClick={toHome}>
          <svg width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M8.85853 11.4101C9.09252 11.2839 9.2384 11.0438 9.2384 10.7827V6.71729H17.2657C17.671 6.71729 18 6.39596 18 6.00003C18 5.6041 17.671 5.28276 17.2657 5.28276H9.2384V1.21731C9.2384 0.955274 9.09252 0.71523 8.85853 0.589948C8.62453 0.462753 8.33865 0.471361 8.11248 0.610031L0.342671 5.39274C0.129236 5.52472 0 5.75329 0 6.00003C0 6.24676 0.129236 6.47533 0.342671 6.60731L8.11248 11.39C8.23193 11.4627 8.36802 11.5 8.50411 11.5C8.62551 11.5 8.74789 11.4694 8.85853 11.4101Z" 
              fill={theme.palette.error.main}
            />
          </svg>
        </IconButton>
        <Typography variant='h6'>
          Usuarios
        </Typography>
      </Box>
      <Paper className={classes.root}>
        <Box p={3} mb={2} display='flex'>
          <Button variant='contained' color='primary' onClick={handleClickOpen}>
            Agregar Usuario
          </Button>
          <Button variant='contained' style={{ marginLeft: 10 }} onClick={handleFilter}>
            <FilterList />
          </Button>
          <Button variant='contained' onClick={handleRefreshUsuarios} style={{ marginLeft: 10 }}>
            <Refresh />
          </Button>
        </Box>
        {filter && (
          <div className={classes.containerFilter}>
            <label>Buscar por: </label><br />
            <TextField
              label="Nombre"
              value={nombreFilter}
              variant="filled"
              size="small"
              onChange={handleNombreFilter} />
            <TextField
              label="Correo"
              value={emailFilter}
              variant="filled"
              size="small"
              onChange={handleEmailFilter} />
            <Button variant="contained" color="primary" onClick={handleSubmitFilter}>
              <Search /> Buscar
            </Button>
            {(emailFilter.length > 0 || nombreFilter.length > 0) && (
              <Button variant="contained" onClick={handleFilterCancel}>
                Cancelar
              </Button>
            )}
          </div>
        )}
        {(users.length === 0 || users === undefined || users === null) && (
          <Typography variant='subtitle1' align='center'>
            No hay datos de usuarios
          </Typography>
        )}
        { users.length > 0 && (
          <>
            <TableContainer className={classes.container}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth, fontWeight: 'bold' }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {users
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row: IUser) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.id}
                        >
                          <TableCell>
                            <Box display='flex'>
                              <Tooltip title="Editar usuario">
                                <IconButton size='small' onClick={() => handleEditUser(row)}>
                                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M19.9898 18.9533C20.5469 18.9533 21 19.4123 21 19.9766C21 20.5421 20.5469 21 19.9898 21H14.2797C13.7226 21 13.2695 20.5421 13.2695 19.9766C13.2695 19.4123 13.7226 18.9533 14.2797 18.9533H19.9898ZM16.0299 3.69906L17.5049 4.87078C18.1097 5.34377 18.513 5.96726 18.6509 6.62299C18.8101 7.3443 18.6403 8.0527 18.1628 8.66544L9.3764 20.0279C8.97316 20.5439 8.37891 20.8341 7.74222 20.8449L4.24039 20.8879C4.04939 20.8879 3.89021 20.7589 3.84777 20.5761L3.0519 17.1255C2.91395 16.4912 3.0519 15.8355 3.45514 15.3303L9.68413 7.26797C9.79025 7.13898 9.98126 7.11855 10.1086 7.21422L12.7297 9.29967C12.8994 9.43942 13.1329 9.51467 13.377 9.48242C13.8969 9.41792 14.2471 8.94493 14.1941 8.43969C14.1622 8.1817 14.0349 7.96671 13.8651 7.80546C13.812 7.76246 11.3183 5.76301 11.3183 5.76301C11.1591 5.63401 11.1273 5.39752 11.2546 5.23735L12.2415 3.95706C13.1541 2.78534 14.7459 2.67784 16.0299 3.69906Z" fill={theme.palette.warning.main}/>
                                  </svg>
                                </IconButton>
                              </Tooltip>
                              {/* <Tooltip title="Cambiar contraseña">
                                <IconButton size='small'>
                                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M17.0108 14.6012C17.4248 14.6012 17.7608 14.2652 17.7608 13.8512V11.9992C17.7608 11.5852 17.4248 11.2492 17.0108 11.2492H11.3188C10.9948 10.1822 10.0128 9.39821 8.84076 9.39821C7.40676 9.39821 6.23976 10.5652 6.23976 11.9992C6.23976 13.4342 7.40676 14.6012 8.84076 14.6012C10.0128 14.6012 10.9948 13.8172 11.3188 12.7492H13.4308V13.8512C13.4308 14.2652 13.7668 14.6012 14.1808 14.6012C14.5948 14.6012 14.9308 14.2652 14.9308 13.8512V12.7492H16.2608V13.8512C16.2608 14.2652 16.5968 14.6012 17.0108 14.6012ZM7.66576 1.99921H16.3348C19.7228 1.99921 21.9998 4.37721 21.9998 7.91621V16.0832C21.9998 19.6222 19.7228 21.9992 16.3338 21.9992H7.66576C4.27676 21.9992 1.99976 19.6222 1.99976 16.0832V7.91621C1.99976 4.37721 4.27676 1.99921 7.66576 1.99921ZM7.73886 12.0001C7.73886 11.3921 8.23386 10.8981 8.84086 10.8981C9.44786 10.8981 9.94286 11.3921 9.94286 12.0001C9.94286 12.6071 9.44786 13.1011 8.84086 13.1011C8.23386 13.1011 7.73886 12.6071 7.73886 12.0001Z" fill={theme.palette.success.main}/>
                                  </svg>
                                </IconButton>
                              </Tooltip> */}
                              <Tooltip title="Eliminar usuario">
                                <IconButton size='small' onClick={() => row.id ? handleDeleteUser(row.id) : null}>
                                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M18.9391 8.69713C19.1384 8.69713 19.3193 8.78413 19.4623 8.93113C19.5955 9.08813 19.6626 9.28313 19.6432 9.48913C19.6432 9.55712 19.1102 16.2971 18.8058 19.134C18.6152 20.875 17.4929 21.932 15.8094 21.961C14.5149 21.99 13.2496 22 12.0038 22C10.6811 22 9.38763 21.99 8.13206 21.961C6.50498 21.922 5.38168 20.846 5.20079 19.134C4.88763 16.2871 4.36439 9.55712 4.35467 9.48913C4.34494 9.28313 4.41108 9.08813 4.54529 8.93113C4.67756 8.78413 4.86818 8.69713 5.06852 8.69713H18.9391ZM14.0647 2C14.9488 2 15.7385 2.61699 15.967 3.49699L16.1304 4.22698C16.2627 4.82197 16.7781 5.24297 17.3714 5.24297H20.2871C20.6761 5.24297 21 5.56596 21 5.97696V6.35696C21 6.75795 20.6761 7.09095 20.2871 7.09095H3.71385C3.32386 7.09095 3 6.75795 3 6.35696V5.97696C3 5.56596 3.32386 5.24297 3.71385 5.24297H6.62957C7.22185 5.24297 7.7373 4.82197 7.87054 4.22798L8.02323 3.54598C8.26054 2.61699 9.0415 2 9.93527 2H14.0647Z" fill={theme.palette.error.main}/>
                                  </svg>
                                </IconButton>
                              </Tooltip>
                            </Box>
                          </TableCell>
                          <TableCell>
                            <CSwitch checked={row.estado === 'ACTIVO'} name={row.id} onClick={() => handleEditState(row)} />
                          </TableCell>
                          <TableCell align='left'>
                            <Typography>{Util.buildDisplayName(row)}</Typography>
                            <Typography variant="caption" style={{ color: 'grey' }}>C.I.: {row.ci}</Typography>
                          </TableCell>
                          <TableCell align='left'>
                            {row.email}
                          </TableCell>
                          <TableCell align='left'>
                            {row.rol}
                          </TableCell>
                          <TableCell align='left'>
                            <Chip
                              size='small'
                              label={row.estado}
                              className={row.estado === 'ACTIVO' ? classes.activeChip : classes.inactiveChip}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
                <TablePagination
                      rowsPerPageOptions={[5, 50, 100, 250]}
                      component="div"
                      count={users.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onChangePage={handleChangePage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                />
          </>
        ) }
      </Paper>
      { modalFormUser && (
        <FormUser handleClose={handleClose} user={user} handleSave={handleSaveUser} isModalOpen={modalFormUser} />
      )}
    </MainLayout>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    container: {
      // maxHeight: 'calc(100vh - 350px)',
    },
    containerFilter: {
      padding: '0 15px 15px',
      '& .MuiFormControl-root, & .MuiButtonBase-root': {
        marginRight: 5
      },
      '& .MuiButtonBase-root': {
        marginTop: 6
      }
    },
    btnAction: {
      margin: '0 3px',
      '&:hover': {
        cursor: 'pointer'
      }
    },
    activeChip: {
      backgroundColor: theme.palette.success.light,
      color: theme.palette.getContrastText(theme.palette.success.light),
    },
    inactiveChip: {
      backgroundColor: theme.palette.error.light,
      color: theme.palette.getContrastText(theme.palette.error.light),
    }
  })
);

export default User;
