import React from "react";
import { useHistory } from "react-router";
import { IconButton, Box, useTheme } from "@material-ui/core";

import MainLayout from "../../components/layout/MainLayout";
import { CTabPanel } from "../../components/core/CTabPanel";
import { CTab, CTabs } from "../../components/core/CTab";

import CursoPage from "../Curso";
import EstudiantePage from "../Estudiante";


const CursoTab: React.FC = () => {
  const theme = useTheme();
  const history = useHistory();

  const [pestania, setPestania] = React.useState(0);
  const handleChangePestania = (event: React.ChangeEvent<{}>, newValue: number) => {
    setPestania(newValue);
  };

  return (
    <MainLayout>
      <Box mb={2} display='flex' alignItems='center'>
        <IconButton onClick={() => history.push('/')}>
          <svg width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M8.85853 11.4101C9.09252 11.2839 9.2384 11.0438 9.2384 10.7827V6.71729H17.2657C17.671 6.71729 18 6.39596 18 6.00003C18 5.6041 17.671 5.28276 17.2657 5.28276H9.2384V1.21731C9.2384 0.955274 9.09252 0.71523 8.85853 0.589948C8.62453 0.462753 8.33865 0.471361 8.11248 0.610031L0.342671 5.39274C0.129236 5.52472 0 5.75329 0 6.00003C0 6.24676 0.129236 6.47533 0.342671 6.60731L8.11248 11.39C8.23193 11.4627 8.36802 11.5 8.50411 11.5C8.62551 11.5 8.74789 11.4694 8.85853 11.4101Z" 
              fill={theme.palette.error.main}
            />
          </svg>
        </IconButton>
        <CTabs value={pestania} onChange={handleChangePestania}>
          <CTab label="Cursos" />
          <CTab label="Estudiantes" />
        </CTabs>
      </Box>
      <CTabPanel value={pestania} index={0}>
        <CursoPage />
      </CTabPanel>
      <CTabPanel value={pestania} index={1}>
        <EstudiantePage />
      </CTabPanel>
    </MainLayout>
  );
};

export default CursoTab;
