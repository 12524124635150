import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Grid,
  IconButton,
  TextField,
  makeStyles,
  Theme,
  createStyles,
} from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";
import { Anotacion } from "models/anotacion_model";
import React, { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { useDispatch } from "react-redux";
import { cargarEstudiantes } from "store/actions/estudiante_actions";
import { Translate, I18n } from "react-redux-i18n";
import { Buscador } from "./Buscador";
import moment from "moment";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import esl from "date-fns/locale/es";

type FormAnotacionData = {
  id?: string;
  categoria: string;
  descripcion: string,
  estudianteId: string,
  fecha: Date | string,
};

type FormAnotacionProps = {
  anotacion: Anotacion | null;
  isModalOpen: boolean;
  handleClose: () => void;
  handleSave: (data: Partial<Anotacion>) => void;
};

const FormAnotacion: React.FC<FormAnotacionProps> = ({
  isModalOpen,
  handleClose,
  handleSave,
}) => {
  const { handleSubmit, control } = useForm<FormAnotacionData>({
    shouldUnregister: false,
  });

  const [parametrica, setParametrica] = React.useState<string | null>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(cargarEstudiantes());
  }, [dispatch]);

  const classes = useStyles();

  const onSubmit = (data: FormAnotacionData) => {
    console.log("data = ", data);
    handleSave({
      categoria: String(parametrica),
      descripcion: data.descripcion,
      fecha: moment(data.fecha).format('YYYY-MM-DD'),
    });
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={isModalOpen}
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle>
        <Box display={"flex"} flexDirection={"row"} justifyContent={'space-between'}>
          <Box>
            <Translate value="agregarAnotacion" />
          </Box>
          <Box ml={2}>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent dividers>
          <Box mb={3}>
            <Typography gutterBottom style={{ fontWeight: "bold" }}>
              <Translate value="form.seleccioneMotivo" />
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Buscador
                  onSearchClick={async (idParametrica) =>
                    setParametrica(idParametrica)
                  }
                />
              </Grid>
            </Grid>
            {parametrica === 'Retraso' && <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <MuiPickersUtilsProvider locale={esl} utils={DateFnsUtils}>
                  <Controller
                    name="fecha"
                    control={control}
                    defaultValue={null}
                    render={({ field: { ref, ...rest } }) => (
                      <KeyboardDatePicker
                        className={classes.formControl}
                        inputVariant="filled"
                        required
                        label={I18n.t('form.fecha')}
                        autoOk
                        openTo="date"
                        views={["year", "month", "date"]}
                        format="dd/MM/yyyy"
                        {...rest}
                      />
                    )}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            </Grid>}
            <Box m={2} />
            <Typography gutterBottom style={{ fontWeight: "bold" }}>
              <Translate value="form.descripcion" />
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <Controller
                  name="descripcion"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      variant="filled"
                      rows={6}
                      multiline
                      label={I18n.t("form.escribaMasInformacion")}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} className={classes.btnError} fullWidth>
            <Translate value="form.cancelar" />
          </Button>
          <Button type="submit" className={classes.btnSave} fullWidth>
            <Typography variant="body2" noWrap>
              <Translate value="form.aceptarYGuardar" />
            </Typography>
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    btnSave: {
      backgroundColor: theme.palette.success.main,
      // margin: '0 3px',
      "&:hover": {
        backgroundColor: theme.palette.success.light,
      },
    },
    btnError: {
      backgroundColor: theme.palette.error.main,
      // margin: '0 3px',
      "&:hover": {
        backgroundColor: theme.palette.error.light,
      },
    },
    formControl: {
      // margin: theme.spacing(1),
      width: "100%",
      // minWidth: '100%',
    },
  })
);

export default FormAnotacion;
