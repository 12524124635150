import DateFnsUtils from "@date-io/date-fns";
import { Box, Button, createStyles, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, makeStyles, TextField, Theme, Typography } from "@material-ui/core";
import { Close as CloseIcon } from '@material-ui/icons';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import esl from "date-fns/locale/es";
import moment from "moment";
import message from "plugins/utils/message";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { I18n, Translate } from "react-redux-i18n";
import { Buscador } from "views/Kardex/components/Buscador";

type FormAccidenteData = {
  //id?: string;
  // estudianteId?: string; Se controla mediante useState y no mediante useForm
  descripcion?: string;
  comoFueAtendido?: string;
  fecha?: Date | string | null;
  hora: string;
};

type FormSaveAccidenteData = {
  estudianteId: string;
  descripcion?: string;
  comoFueAtendido?: string;
  fecha: string;
  hora: string;
};

type FormAccidenteProps = {
  isModalOpen: boolean;
  handleClose: () => void;
  handleSave: (data: FormSaveAccidenteData) => void;
  codigoCurso: string;
};

const ModalAccidents: React.FC<FormAccidenteProps> = ({
  isModalOpen,
  handleClose,
  handleSave,
  codigoCurso,
}) => {
  const { handleSubmit, control, reset } = useForm<FormAccidenteData>({
    shouldUnregister: false,
    defaultValues: {
      descripcion: '',
      comoFueAtendido: '',
      fecha: null,
      hora: '',
    },
  });

  const [estudiante, setEstudiante] = React.useState<string[]>([]);

  const classes = useStyles();

  useEffect(() => {
    if (!isModalOpen) {
      reset();
      setEstudiante([]);
    }
  }, [reset, isModalOpen]);

  const onSubmit = (data: FormAccidenteData) => {
    if (!estudiante.join(',')) {
      message.error('Seleccione un estudiante');
      return;
    }

    if (data.fecha === null) {
      message.error('Debe seleccionar una fecha');
      return;
    }

    const fechaString = `${moment(data.fecha).format('YYYY-MM-DD')}`;

    handleSave({
      estudianteId: estudiante.join(','),
      descripcion: data.descripcion,
      comoFueAtendido: data.comoFueAtendido,
      fecha: fechaString,
      hora: data.hora,
    });
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={isModalOpen}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>
        <Grid container justify="space-between">
          <Translate value="kinder.formularioRegistroAccidentes" />
          <IconButton size="small" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent dividers>
          <Box mb={3}>
            <Typography gutterBottom style={{ fontWeight: "bold" }}>
              <Translate value="kinder.datosEstudiante" />
            </Typography>
            <Grid container>
              <Grid item xs={12}>
                <Buscador
                  isKinder={true}
                  codigoCurso={codigoCurso}
                  value={
                    estudiante && estudiante.length > 0 ? estudiante[0] : null
                  }
                  onSearchClick={async (estudianteId) =>
                    setEstudiante([estudianteId])
                  }
                />
              </Grid>
            </Grid>
            <Box m={2} />
            <Typography gutterBottom style={{ fontWeight: "bold" }}>
              <Translate value="kinder.comentario" />
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <Controller
                  name="descripcion"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      type="textarea"
                      multiline
                      rows={3}
                      rowsMax={7}
                      inputProps={{ step: 1, min: 0, max: 6 }}
                      fullWidth
                      variant="filled"
                      required
                      label={I18n.t('form.descripcion')}
                    />
                  )}
                />
              </Grid>
            </Grid>

            <Grid
              container
              style={{
                marginTop: 10,
                marginBottom: 10,
              }}
            >
              <Typography gutterBottom style={{ fontWeight: 'bold' }}>
                <Translate value="kinder.comoFueAtendido" />
              </Typography>
              <Grid item xs={12} sm={12}>
                <Controller
                  name="comoFueAtendido"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      type="textarea"
                      multiline
                      rows={3}
                      rowsMax={7}
                      inputProps={{ step: 1, min: 0, max: 6 }}
                      fullWidth
                      variant="filled"
                      required
                      label={I18n.t('form.descripcion')}
                    />
                  )}
                />
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <MuiPickersUtilsProvider locale={esl} utils={DateFnsUtils}>
                  <Controller
                    name="fecha"
                    control={control}
                    defaultValue={null}
                    render={({ field: { ref, ...rest } }) => (
                      <KeyboardDatePicker
                        //className={classes.formControl}
                        style={{ width: '70%' }}
                        inputVariant="filled"
                        required
                        label={I18n.t('form.fecha')}
                        autoOk
                        openTo="date"
                        views={['year', 'month', 'date']}
                        format="dd/MM/yyyy"
                        maxDate={new Date()}
                        {...rest}
                      />
                    )}
                  />
                </MuiPickersUtilsProvider>
                <Controller
                  name="hora"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      style={{ width: '30%', paddingLeft: 5 }}
                      variant='filled'
                      type="time"
                      label={<Translate value="hora" />} />
                  )}
                />
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} className={classes.btnError} fullWidth>
            <Translate value="form.cancelar" />
          </Button>
          <Button type="submit" className={classes.btnSave} fullWidth>
            <Typography variant="body2" noWrap><Translate value="form.aceptarYGuardar" /></Typography>
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    btnSave: {
      backgroundColor: theme.palette.success.main,
      // margin: '0 3px',
      "&:hover": {
        backgroundColor: theme.palette.success.light,
      },
    },
    btnError: {
      backgroundColor: theme.palette.error.main,
      // margin: '0 3px',
      "&:hover": {
        backgroundColor: theme.palette.error.light,
      },
    },
    formControl: {
      // margin: theme.spacing(1),
      width: "100%",
      // minWidth: '100%',
    },
    studentSelector: {
      minWidth: '350px'
    }
  })
);

export default ModalAccidents;