import http from "./http";
import { Curso } from "../models/curso_model";

export const getCursos = async (cursoFilter?: string, turnoFilter?: string, gestionFilter?: string) => {
  let filters = []
    if (cursoFilter) {
      filters.push(`curso=${cursoFilter}`)
    }
    if (turnoFilter) {
      filters.push(`turno=${turnoFilter}`)
    }
    if (gestionFilter) {
      filters.push(`gestion=${gestionFilter}`)
    }
  try {
    return await http.get(`/curso/${filters.length ? ('?' + filters.join('&')) : ''}`);
  } catch (error) {
    console.error("[get] Error :: ", error);
    return [];
  }
};

export const getCursoById = async (id: string) => {
  try {
    return await http.get(`/curso/${id}`);
  } catch (error) {
    console.error('[get_by_id] error :: ', error);
    return null;
  }
}

export const createCurso = async (data: Curso) => {
  try {
    return await http.post(`/curso/`, data);
  } catch (error) {
    console.error("[create] Error :: ", error);
    return null;
  }
};

export const editCurso = async (data: Curso, id: string) => {
  try {
    return await http.put(`/curso/${id}`, data);
  } catch (error) {
    console.error("[put] Error :: ", error);
    return null;
  }
};

export const deleteCurso = async (id: string) => {
  try {
    return await http.delete(`/curso/${id}`);
  } catch (error) {
    console.error("[delete] Error :: ", error);
    return null;
  }
};
