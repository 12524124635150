import {
  ICertificate,
  ICertificateEdit,
  ICertificateForm,
  ICertificateGenerate,
  ICertificateUploadForm,
} from 'models/certificate_model';
import http from './http';
import { TipoCertificadoGenerado } from 'views/Certificates';
import { base64ToFile } from './ticket_repository';
import moment from 'moment';

export const getCertificatesService = async (filtro?: {
  tipoGeneracion: TipoCertificadoGenerado;
  nombreUsuario?: string;
  gestion?: string;
  tipoCertificado?: string;
  _limit?: number;
  _page?: number;
}) => {
  try {
    let filters = [];
    if (filtro?.tipoGeneracion) {
      if (filtro?.tipoGeneracion === 'PROFESOR') {
        filters.push(`certificadosProfesores=true`);
      } else {
        if (filtro?.tipoGeneracion !== 'EXTERNO') {
          filters.push(`certificadosEstudiantes=true`);
        }
        filters.push(`tipoCertificadoGenerado=${filtro.tipoGeneracion}`);
      }
    }
    if (filtro?.nombreUsuario) {
      filters.push(`nombreUsuario=${filtro.nombreUsuario}`);
    }
    if (filtro?.gestion) {
      filters.push(`gestion=${filtro.gestion}`);
    }
    if (filtro?.tipoCertificado) {
      filters.push(`tipoCertificado=${filtro.tipoCertificado}`);
    }
    if (filtro?._limit) {
      filters.push(`_limit=${filtro._limit}`);
    }
    if (filtro?._page) {
      filters.push(`_page=${filtro._page}`);
    }
    const items = await http.get(
      `/certificados${filters.length ? '?' + filters.join('&') : ''}`,
    );
    return items as { rows: ICertificate[]; count: number };
  } catch (error) {
    console.error('[get_certificates]  Error -> ', error);
    return { rows: [], count: 0 };
  }
};

export const getPlantillasService = async (filtro?: {
  nombre?: string;
  tipo?: 'PROFESOR' | 'ESTUDIANTE';
}) => {
  try {
    let filters = [];
    if (filtro?.nombre) {
      filters.push(`nombre=${filtro.nombre}`);
    }

    if (filtro?.tipo) {
      filters.push(`tipo=${filtro.tipo}`);
    }

    const response = await http.get(
      `/certificado/plantillas${filters.length ? '?' + filters.join('&') : ''}`,
    );
    return response as {
      rows: { id: string; nombre: string }[];
      count: number;
    };
  } catch (error) {
    console.error(
      'Ocurrió un error al obtener las plantillas de certificados :: ',
      error,
    );
    return { rows: [], count: 0 };
  }
};

export const createCertificateService = async (data: ICertificateForm) => {
  try {
    const formData = new FormData();
    formData.append('idTipoCertificado', data.tipoCertificado);
    formData.append('tipoCertificadoGenerar', data.tipoCertificadoGenerar);
    formData.append('frase', data.frase);
    formData.append('fechaCertificado', data.fechaCertificado);

    if (data.temas) {
      formData.append('temas', data.temas);
    }

    if (data.archivo.length > 0) {
      formData.append('file', data.archivo[0]);
    }
    const response = await http.post(`/certificado`, formData, {
      'Content-Type': 'multipart/form-data',
      Accept: 'application/json',
    });

    return response;
  } catch (error) {
    console.error(
      'Ocurrió un error al verificar usuarios del certificado :: ',
      error,
    );
    return null;
  }
};

export const editCertificateService = async (data: ICertificateEdit) => {
  try {
    const formData = new FormData();
    formData.append('id', data.id);
    formData.append('tipoCertificado', data.tipoCertificado || '');
    formData.append('tipoGenerado', data.tipoGenerado);
    formData.append('frase', data.frase || '');
    formData.append(
      'fechaCertificado',
      moment(data.fechaCertificado).format('YYYY-MM-DD'),
    );

    formData.append('urlArchivo', data.urlArchivo || '');
    if (data.temas) {
      formData.append('temas', data.temas);
    }

    if (data.adjunto && JSON.parse(data.adjunto)?.length > 0) {
      const file = JSON.parse(data.adjunto)[0];
      const fileObject = base64ToFile(
        file.fileObject.data,
        file.fileName,
        file.fileType,
      );
      formData.append('file', fileObject);
    }
    const response = await http.put(`/certificado/${data.id}`, formData, {
      'Content-Type': 'multipart/form-data',
      Accept: 'application/json',
    });

    return response;
  } catch (error) {
    console.error('Ocurrió un error al editar el certificado :: ', error);
    return null;
  }
};

export const deleteCertificateService = async (id: string) => {
  try {
    const response = await http.delete(`/certificado/${id}`);
    return response;
  } catch (error) {
    console.error('Ocurrió un error al eliminar el certificado :: ', error);
    return null;
  }
};

export const uploadCertificateService = async (
  data: ICertificateUploadForm,
) => {
  try {
    const formData = new FormData();
    formData.append('esProfesor', data.esProfesor ? 'true' : 'false');
    formData.append('urlArchivo', data.urlArchivo || '');
    formData.append('usuario', data.usuario || '');

    if (JSON.parse(data.adjunto)?.length > 0) {
      const file = JSON.parse(data.adjunto)[0];
      const fileObject = base64ToFile(
        file.fileObject.data,
        file.fileName,
        file.fileType,
      );
      formData.append('file', fileObject);
    }
    const response = await http.post(`/certificado-upload`, formData, {
      'Content-Type': 'multipart/form-data',
      Accept: 'application/json',
    });

    return response;
  } catch (error) {
    console.error(
      'Ocurrió un error al subir el certificado existente :: ',
      error,
    );
    return null;
  }
};

export const downloadCertificateService = async (data: {
  id: string;
  nombreFile: string;
}) => {
  try {
    return http.download(`/certificado/${data.id}`, `${data.nombreFile}`);
  } catch (error) {
    console.error('Ocurrió un error al descargar el certificado :: ', error);
  }
};

export const generarCertificadoTemporalService = async (
  data: ICertificateGenerate,
  fileName: string,
) => {
  try {
    const params = new URLSearchParams({
      usuario: data.idUsuario,
      plantilla: data.idPlantilla,
      fecha: moment(data.fecha).format('YYYY-MM-DD'),
      frase: data.frase,
      idioma: data.idioma || 'es',
    });

    if (data.genero) {
      params.append('genero', data.genero);
    }

    if (data.temas) {
      params.append('temas', data.temas);
    }

    const consulta = `?${params.toString()}`;

    return http.download(`/certificado/temporal${consulta}`, `${fileName}.pdf`);
  } catch (error) {
    console.log('Ocurrió un error al generar el certificado ::', error);
    return null;
  }
};

export const generarCertificadoTemporalExistenteService = async (
  data: {
    idCertificado: string;
    frase: string;
    fecha: string;
    temas?: string;
  },
  fileName: string,
) => {
  try {
    let consulta = `?id=${data.idCertificado}&fecha=${moment(data.fecha).format(
      'YYYY-MM-DD',
    )}&frase=${data.frase}`;

    if (data.temas) {
      consulta += `&temas=${data.temas}`;
    }

    return http.download(
      `/certificado/temporal/existente${consulta}`,
      fileName,
    );
  } catch (error) {
    console.log('Ocurrió un error al generar el certificado ::', error);
    return null;
  }
};

export const descargarCSVEjemploPeticion = async (
  tipo: 'PROFESOR' | 'ESTUDIANTE',
) => {
  try {
    return await http.download(
      `/certificado/ejemplo?tipo=${tipo}`,
      `TEMPLATE_CERTIFICADOS.xlsx`,
    );
  } catch (error) {
    console.error('Ocurrió un error al descargar el ejemplo CSV :: ', error);
  }
};

export const verificarUsuariosCertificadoService = async (
  data: ICertificateForm,
) => {
  try {
    const formData = new FormData();

    formData.append('idTipoCertificado', data.tipoCertificado);
    formData.append('tipoCertificadoGenerar', data.tipoCertificadoGenerar);
    formData.append('frase', data.frase);
    formData.append(
      'fechaCertificado',
      moment(data.fechaCertificado).format('YYYY-MM-DD'),
    );

    if (data.archivo.length > 0) {
      formData.append('file', data.archivo[0]);
    }
    const response = await http.post(
      `/certificado/verificar-usuarios`,
      formData,
      {
        'Content-Type': 'multipart/form-data',
        Accept: 'application/json',
      },
    );

    return response as {
      datos: { nombreCompleto: string }[];
      cantidadCertificadosTipo: {};
    };
  } catch (error) {
    console.error(
      'Ocurrió un error al verificar usuarios del certificado :: ',
      error,
    );
    return { datos: [] };
  }
};

export const obtenerUsuariosParaCertificadoService = async (
  tipoUsuario: 'ESTUDIANTE' | 'PROFESOR',
) => {
  try {
    const response = await http.get(
      `/usuarios/certificado?tipoUsuario=${tipoUsuario}`,
    );
    return response as {
      id: string;
      nombre: string;
      codigoCurso: string | null;
      nivel: string | null;
    }[];
  } catch (error) {
    console.error(
      'Ocurrió un error al obtener los usuarios para el certificado :: ',
      error,
    );
    return [];
  }
};
