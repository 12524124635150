import React from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import { useForm, Controller } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { recoveryPassword } from '../../../store/actions/auth_actions';
import AppLogo from '../../../assets/logo/colegio_aleman.png';
import { Translate, I18n, setLocale } from 'react-redux-i18n';
import { RootState, useStateSelector } from 'store/reducers';
import LanguageIcon from '@material-ui/icons/Language';
import packageJson from '../../../../package.json';
import { useHistory } from 'react-router';

type FormLoginData = {
  email: string;
  password: string;
};

function Copyright() {
  return (
    <Box>
      <Typography variant="body2" color="textSecondary" align="center">
        {' © '}
        {new Date().getFullYear()}{' '}
        <Link color="inherit" href="https://material-ui.com/">
          Educonnect
        </Link>{' '}
      </Typography>
      <Typography variant="body2" color="textSecondary" align="center">
        <Translate value="version" /> {packageJson.version}
      </Typography>
    </Box>
  );
}

function buildUserLabel() {
  return I18n.t('correoElectronico');
}

const ForgotScreen = ({ volver }: any) => {
  const { locale } = useStateSelector((state: RootState) => state.i18n);
  const [userLabel, setUserLabel] = React.useState(buildUserLabel());
  const [enviado, setEnviado] = React.useState<boolean>(false);
  const [enviando, setEnviando] = React.useState<boolean>(false);
  const history = useHistory();

  const { handleSubmit, control, getValues } = useForm<FormLoginData>({
    shouldUnregister: false,
  });
  const dispatch = useDispatch();
  const classes = useStyles();

  const onSubmit = (data: FormLoginData) => {
    setEnviando(true)
    dispatch(recoveryPassword(data.email, (result) => {
      setEnviando(false)
      if (result) {
        setEnviado(true);
      }
    }))
  };

  const handleOnclick = (e: any) => {
    const newLocale = locale === 'es' ? 'de' : 'es';
    localStorage.setItem('locale', newLocale);
    dispatch(setLocale(newLocale));

    setUserLabel(I18n.t('correoElectronico'));
  };

  return (
    <Container className={classes.root} component="main" maxWidth="xs">
      <CssBaseline />
      <Box display="flex" justifyContent="center" mb={4}>
        <img src={AppLogo} alt="app icon" width="100px" />
      </Box>
      <div className={classes.paper}>
        <Typography component="h1" variant="h5" align='center'>
          <Translate value="restablecimientoContrasenia" />
        </Typography>

        <Typography
          variant="body1"
          align="center"
          style={{ padding: '24px 0px' }}
        >
          {!enviado && <Translate value="restablecimientoContraseniaMensaje" />}
          {enviado && (
            <>
              <Translate value="restablecimientoContraseniaMensajeEnviado" />{' '}
              <strong>{getValues().email}</strong>
            </>
          )}
        </Typography>

        <form onSubmit={handleSubmit(onSubmit)}>
          {!enviado && (
            <Controller
              name="email"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  margin="normal"
                  required
                  fullWidth
                  variant="filled"
                  label={userLabel}
                />
              )}
            />
          )}
          {!enviado && (
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={enviando}
              className={classes.submit}
            >
              {enviando && <Translate value={"enviando"} />}
              {!enviando && <Translate value={"enviar"} />}
            </Button>
          )}
          {enviado && (
            <Button
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={() => history.push('/login')}
            >
              <Translate value="aceptar" />
            </Button>
          )}
          <Grid container>
            <Grid item xs style={{ textAlign: 'center' }}>
              <Link variant="body2" className={classes.link} onClick={() => volver(false)}>
                Volver al inicio
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={2}>
        <Copyright />
      </Box>
      <Button
        className={classes.languageBtn}
        startIcon={<LanguageIcon />}
        onClick={handleOnclick}
      >
        {locale}
      </Button>
    </Container>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    background: '#c6ba8e69',
    padding: theme.spacing(5, 5, 2, 5),
    marginTop: theme.spacing(10),
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(0),
    },
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    // background: '#c6ba8e',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    background: '#9a851e',
    color: '#fff',
  },
  link: {
    color: theme.palette.common.black,
  },
  languageBtn: {
    position: 'absolute',
    top: 50,
    right: 50,
    [theme.breakpoints.down('xs')]: {
      top: 10,
      right: 10,
    },
  },
}));

export default ForgotScreen;
