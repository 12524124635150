import {
  Typography,
  Box,
  makeStyles,
  createStyles,
  Theme,
  Button,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
  ListItem,
  ListItemIcon,
  Grid
} from "@material-ui/core";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React from "react";
import { useHistory } from "react-router";
import { Translate } from 'react-redux-i18n';
import MainLayout from "../../components/layout/MainLayout";
  
  type HelpProps = {
  };
  
  const Help: React.FC<HelpProps> = () => {
  const classes = useStyles();
  const history = useHistory();

  const renderFAQ = () => {
    return(
      <Box style={{width:"100%"}}>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="p1-i-1-content"
            id="p1-i-1-header"
          >
            <Typography variant="h5">
              1. <Translate value="help.q1" />
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Translate value="help.a1" dangerousHTML />
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="p1-i-2-content"
            id="p1-i-2-header"
          >
            <Typography variant="h5">
              2. <Translate value="help.q2" />
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
              <Translate value="help.a2" />
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="p1-i-3-content"
            id="p1-i-3-header"
          >
            <Typography variant="h5">
              3. <Translate value="help.q3" />
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Translate value="help.a3" />
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="p1-i-4-content"
            id="p1-i-4-header"
          >
            <Typography variant="h5">
              4. <Translate value="help.q4" />
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Translate value="help.a4" />
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="p1-i-5-content"
            id="p1-i-5-header"
          >
            <Typography variant="h5">
              5. <Translate value="help.q5" />
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Translate value="help.a5" />
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="p1-i-6-content"
            id="p1-i-6-header"
          >
            <Typography variant="h5">
              6. <Translate value="help.q6" />
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Translate value="help.a6" />
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="p1-i-7-content"
            id="p1-i-7-header"
          >
            <Typography variant="h5">
              7. <Translate value="help.q7" />
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Translate value="help.a7" dangerousHTML />
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="p1-i-8-content"
            id="p1-i-8-header"
          >
            <Typography variant="h5">
              8. <Translate value="help.q8" />
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Translate value="help.a8" />
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="p1-i-9-content"
            id="p1-i-9-header"
          >
            <Typography variant="h5">
              9. <Translate value="help.q9" />
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Translate value="help.a9" />
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="p1-i-10-content"
            id="p1-i-10-header"
          >
            <Typography variant="h5">
              10. <Translate value="help.q10" />
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Translate value="help.a10" />
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="p1-i-11-content"
            id="p1-i-11-header"
          >
            <Typography variant="h5">
              11. <Translate value="help.q11" />
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Translate value="help.a11" />
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="p1-i-12-content"
            id="p1-i-12-header"
          >
            <Typography variant="h5">
              12. <Translate value="help.q12" />
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Translate value="help.a12" />
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="p1-i-13-content"
            id="p1-i-13-header"
          >
            <Typography variant="h5">
              13. <Translate value="help.q13" />
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Translate value="help.a13" dangerousHTML />
          </AccordionDetails>
        </Accordion>
      </Box>
    );
  }
   
  const renderVideos = () => {
    return (
      <Box style={{width: '100%'}}>
        <List>
          <ListItem button component={Box} onClick={() => window.open('https://youtu.be/aXnaLS9ckFo', '_blank')} >
            <div className={classes.navText}><Translate value="Tour por la plataforma" /></div>
            <Grid container justify="flex-end">
              <ListItemIcon className={classes.navIcon}>
                <Button 
                  variant="text" 
                  startIcon={<ArrowForwardIosIcon />}
                >        
                </Button>
              </ListItemIcon>
            </Grid>
          </ListItem>
          <ListItem button component={Box} onClick={() => window.open('https://youtu.be/W_r4wcbc6zQ', '_blank')} >
            <div className={classes.navText}><Translate value="Libro de curso" /></div>
            <Grid container justify="flex-end">
              <ListItemIcon className={classes.navIcon}>
                <Button 
                  variant="text" 
                  startIcon={<ArrowForwardIosIcon />}
                >        
                </Button>
              </ListItemIcon>
            </Grid>
          </ListItem>
          <ListItem button component={Box} onClick={() => window.open('https://youtu.be/8sVedAR-n0c', '_blank')} >
            <div className={classes.navText}><Translate value="Asistencia" /></div>
            <Grid container justify="flex-end">
              <ListItemIcon className={classes.navIcon}>
                <Button 
                  variant="text" 
                  startIcon={<ArrowForwardIosIcon />}
                >        
                </Button>
              </ListItemIcon>
            </Grid>
          </ListItem>
          <ListItem button component={Box} onClick={() => window.open('https://www.youtube.com', '_blank')} >
            <div className={classes.navText}><Translate value="Reportes" /></div>
            <Grid container justify="flex-end">
              <ListItemIcon className={classes.navIcon}>
                <Button 
                  variant="text" 
                  startIcon={<ArrowForwardIosIcon />}
                >        
                </Button>
              </ListItemIcon>
            </Grid>
          </ListItem>
          <ListItem button component={Box} onClick={() => window.open('https://youtu.be/mXeL4twQ5SE', '_blank')} >
            <div className={classes.navText}><Translate value="Kardex" /></div>
            <Grid container justify="flex-end">
              <ListItemIcon className={classes.navIcon}>
                <Button 
                  variant="text" 
                  startIcon={<ArrowForwardIosIcon />}
                >        
                </Button>
              </ListItemIcon>
            </Grid>
          </ListItem>
        </List>
      </Box>
    );
  }
    return (
      <MainLayout>
        <Button 
          variant="text" 
          startIcon={<ArrowBackIosIcon />}
          onClick={() => history.push(`/home`)}>
            <Translate value="kinder.volverMenu" />     
        </Button>
        <Typography variant="h4" className={classes.titleHeader}>
          <Translate value="Ayuda" />
        </Typography>
        <Divider className={classes.titleDivider} />
          
        <Box style={{width:"100%"}}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="p1-content"
              id="p1-header"
            >
              <Typography variant="h4">
                <Translate value="help.kinderFAQ"/>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {renderFAQ()}
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="p2-content"
              id="p2-header"
            >
              <Typography variant="h4">
                <Translate value="Videotutoriales"/>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              { renderVideos() }
            </AccordionDetails>
          </Accordion>
        </Box>
      </MainLayout>
    );
  };
  
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        width: "100%",
        backgroundColor: 'transparent'
      },
      titleHeader: {
        paddingLeft: 30
      },
      titleDivider: {
        background: theme.palette.primary.main,
        paddingBottom: 5,
        marginBottom: 20
      },
      heading: {
        //fontSize: theme.typography.pxToRem(15),
        //fontWeight: theme.typography.fontWeightRegular,
      },
      navIcon: {
        minWidth: 'auto'
      },
      navText: {
        fontSize: 15,
        width: '80%'
      },
    })
  );
  
export default Help;