import http from "./http";
import { Materia, HorarioType } from "../models/materia_model";

export const getMaterias = async (cursoId: string, materiaFilter?: string, siglaFilter?: string) => {
  let filters = []
    if (materiaFilter) {
      filters.push(`materia=${materiaFilter}`)
    }
    if (siglaFilter) {
      filters.push(`sigla=${siglaFilter}`)
    }
  try {
    return await http.get(`/curso/${cursoId}/materia/${filters.length ? ('?' + filters.join('&')) : ''}`);
  } catch (error) {
    console.error("[get] Error :: ", error);
    return error;
  }
};

export const getMateriaById = async (cursoId: string, id: string) => {
  try {
    return await http.get(`/curso/${cursoId}/materia/${id}`);
  } catch (error) {
    console.error('[get_by_id] error :: ', error);
    return error;
  }
}

export const createMateria = async (cursoId: string, data: Materia) => {
  try {
    return await http.post(`/curso/${cursoId}/materia`, data);
  } catch (error) {
    console.error("[create] Error :: ", error);
    return error;
  }
};

export const createAllMaterias = async (cursoId: string,data: Materia[]) => {
  try {
    return await http.post(`/curso/${cursoId}/materia/`, { materias: data });
  } catch (error) {
    console.error('Error al crear array de materias ', error)
    return error;
  }
}

export const editMateria = async (cursoId: string, data: Materia, id: string) => {
  try {
    return await http.put(`/curso/${cursoId}/materia/${id}`, data);
  } catch (error) {
    console.error("[put] Error :: ", error);
    return error;
  }
};

export const editHorario = async (cursoId: string, materiaId: string, fechaCambio: string, horarios: HorarioType[]) => {
  try {
    const data = { fechaCambio, horarios };
    return await http.put(`/curso/${cursoId}/materia/${materiaId}/reasignarHorario`, data);
  } catch (error) {
    console.error("[put] Error :: ", error);
    return error;
  }
};

export const deleteMateria = async (cursoId: string, id: string) => {
  try {
    return await http.delete(`/curso/${cursoId}/materia/${id}`);
  } catch (error) {
    console.error("[delete] Error :: ", error);
    return error;
  }
};
