import http from "./http";
import { Permiso } from "../models/permiso_model";
import { ResultadoConPaginacion } from "../types";

export const listarPermisos = async (
  customQuery: any
): Promise<ResultadoConPaginacion<Permiso>> => {
  try {
    const filtros: any = customQuery;
    const query = Object.keys(filtros)
      .map((key) => `${key}=${filtros[key]}`)
      .join("&");
    const result = await http.get(
      `/permiso_v2/${query.length > 0 ? `?${query}` : ""}`
    );
    return result as ResultadoConPaginacion<Permiso>;
  } catch (error) {
    console.error("[get] Error :: ", error);
    return { rows: [], count: 0, limit: 0, page: 0 };
  }
};

export const getPermisos = async (estado?: string, estudiante?: string) => {
  try {
    const filtros: any = {};
    if (estado) filtros.estado = estado;
    if (estudiante) filtros.estudiante = estudiante;
    const query = Object.keys(filtros)
      .map((key) => `${key}=${filtros[key]}`)
      .join("&");
    return await http.get(`/permiso/${query.length > 0 ? `?${query}` : ""}`);
  } catch (error) {
    console.error("[get] Error :: ", error);
    return error;
  }
};

export const getPermisoById = async (id: string) => {
  try {
    return await http.get(`/permiso/${id}`);
  } catch (error) {
    console.error("[get_by_id] error :: ", error);
    return error;
  }
};

export const createPermiso = async (data: Permiso) => {
  try {
    return await http.post(`/permiso/`, data);
  } catch (error) {
    console.error("[create] Error :: ", error);
    return error;
  }
};

export const editPermiso = async (data: Permiso, id: string) => {
  try {
    return await http.put(`/permiso/${id}`, data);
  } catch (error) {
    console.error("[put] Error :: ", error);
    return error;
  }
};

export const infoPermiso = async (data: Permiso, id: string) => {
  try {
    return await http.put(`/permiso/${id}/mas-info`, data);
  } catch (error) {
    console.error("[put] Error :: ", error);
    return error;
  }
};

export const infoAltaMedica = async (data: Permiso, id: string) => {
  try {
    return await http.put(`/altaMedica/${id}/mas-info`, data);
  } catch (error) {
    console.error("[put] Error :: ", error);
    return error;
  }
};

export const deletePermiso = async (id: string) => {
  try {
    return await http.delete(`/permiso/${id}`);
  } catch (error) {
    console.error("[delete] Error :: ", error);
    return error;
  }
};

export const aceptarRechazarPermiso = async (data: Permiso, id: string) => {
  try {
    return await http.put(`/permiso/${id}/responder`, data);
  } catch (error) {
    console.error("[put] Error :: ", error);
    return error;
  }
};

export const sendEmail = async (id: string) => {
  try {
    return await http.post(`/permiso/${id}/send-mail`, {});
  } catch (error) {
    console.error("[post] Error :: ", error);
    return error;
  }
};

export const buscarCorreo = async (id: string) => {
  try {
    return await http.get(`/permiso/${id}/buscar-correo`, {});
  } catch (error) {
    console.error("[post] Error :: ", error);
    return error;
  }
};
