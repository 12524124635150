import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  makeStyles,
  Theme,
  createStyles,
  Typography,
} from '@material-ui/core';
import { Close as CloseIcon, Edit, Visibility } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { ICircularForm } from '../../../../models/circular_model';
import { useForm, Controller } from 'react-hook-form';
import { I18n, Translate } from 'react-redux-i18n';
import { FormInputEditorHTML } from './FormInputEditorHTML';
import DOMPurify from 'dompurify';

type FormCircularProps = {
  circular: ICircularForm | null;
  isModalOpen: boolean;
  handleClose: () => void;
  handleSave: (data: ICircularForm) => void;
};

const FormCircular: React.FC<FormCircularProps> = ({
  circular,
  isModalOpen,
  handleClose,
  handleSave,
}) => {
  const { handleSubmit, control, setValue, watch } = useForm<ICircularForm>({
    shouldUnregister: false,
  });
  const [modoEdicion, setModoEdicion] = useState(true);

  const [imagesEliminarEs, setImagesEliminarEs] = useState<string[]>([]);
  const [imagesEliminarDe, setImagesEliminarDe] = useState<string[]>([]);

  const codigo = watch('codigo');
  const asuntoES = watch('asuntoES');
  const asuntoDE = watch('asuntoDE');
  const contenidoES = watch('contenidoES');
  const contenidoDE = watch('contenidoDE');

  useEffect(() => {
    if (circular) {
      setTimeout(() => {
        setValue('codigo', circular.codigo);
        setValue('asuntoES', circular.asuntoES);
        setValue('asuntoDE', circular.asuntoDE);
        setValue('contenidoES', circular.contenidoES);
        setValue('contenidoDE', circular.contenidoDE);
      }, 1000);
    }
  }, [circular, setValue]);

  const classes = useStyles();

  const onSubmit = (data: ICircularForm) => {
    handleSave({
      id: data.id,
      codigo: data.codigo,
      asuntoES: data.asuntoES,
      asuntoDE: data.asuntoDE,
      contenidoES: data.contenidoES,
      contenidoDE: data.contenidoDE,
      imagesToDelete: [
        ...(imagesEliminarEs || []),
        ...(imagesEliminarDe || []),
      ],
    });
  };

  const renderFormulario = () => {
    return (
      <Box mb={3}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography gutterBottom style={{ fontWeight: 'bold' }}>
              <Translate value="form.nroCircular" />
            </Typography>
            <Controller
              name="codigo"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  variant="filled"
                  required
                  placeholder={I18n.t('form.escribaNumeroCircular')}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} md={6}></Grid>

          <Grid item xs={12} md={6}>
            <Grid
              container
              style={{
                border: '1px solid #e0e0e0',
                borderRadius: '4px',
                padding: '16px',
              }}
            >
              <Grid item xs={12} sm={12}>
                <Typography>
                  <Translate value="form.inserteInformacionES" />
                </Typography>
              </Grid>

              <Grid item xs={12} sm={12}>
                <Typography
                  gutterBottom
                  style={{ fontWeight: 'bold', marginTop: '16px' }}
                >
                  <Translate value="form.asunto" />
                </Typography>
                <Controller
                  name="asuntoES"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      variant="filled"
                      required
                      placeholder={I18n.t('form.escribaAsuntoCircular')}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={12}>
                <Typography
                  gutterBottom
                  style={{ fontWeight: 'bold', marginTop: '16px' }}
                >
                  <Translate value="form.contenido" />
                </Typography>
                <FormInputEditorHTML
                  control={control}
                  name={'contenidoES'}
                  setImagesDelete={setImagesEliminarEs}
                  imagesDelete={imagesEliminarEs}
                  // onChange={(val) => setValue('contenidoES', val)}
                  placeholder={I18n.t('form.escribaContenidoCircular')}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} md={6}>
            <Grid
              container
              style={{
                border: '1px solid #e0e0e0',
                borderRadius: '4px',
                padding: '16px',
              }}
            >
              <Grid item xs={12} sm={12}>
                <Typography>
                  <Translate value="form.inserteInformacionDE" />
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Typography
                  gutterBottom
                  style={{ fontWeight: 'bold', marginTop: '16px' }}
                >
                  <Translate value="form.asunto" />
                </Typography>
                <Controller
                  name="asuntoDE"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      variant="filled"
                      required
                      placeholder={I18n.t('form.escribaAsuntoCircular')}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <Typography
                  gutterBottom
                  style={{ fontWeight: 'bold', marginTop: '16px' }}
                >
                  <Translate value="form.contenido" />
                </Typography>
                <FormInputEditorHTML
                  name={'contenidoDE'}
                  control={control}
                  setImagesDelete={setImagesEliminarDe}
                  imagesDelete={imagesEliminarDe}
                  placeholder={I18n.t('form.escribaContenidoCircular')}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    );
  };

  const renderVistaPrevia = () => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Box
            display={'flex'}
            flexDirection={'column'}
            alignItems={'center'}
            minWidth={'300px'}
            minHeight={'200px'}
          >
            <Typography>
              <strong>{codigo || '<codigo>'}</strong>
            </Typography>
            <Typography>
              <strong>{asuntoES || '<asuntoES>'}</strong>
            </Typography>
            <Box>
              {contenidoES && (
                <div
                  className={classes.textHtml}
                  dangerouslySetInnerHTML={{
                    __html:
                      typeof contenidoES === 'string'
                        ? DOMPurify.sanitize(contenidoES)
                        : '',
                  }}
                />
              )}
              {!contenidoES && '<contenidoES>'}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box
            display={'flex'}
            flexDirection={'column'}
            alignItems={'center'}
            minWidth={'300px'}
            minHeight={'200px'}
          >
            <Typography>
              <strong>{codigo || '<codigo>'}</strong>
            </Typography>
            <Typography>
              <strong>{asuntoDE || '<asuntoDE>'}</strong>
            </Typography>
            <Box>
              {contenidoDE && (
                <div
                  className={classes.textHtml}
                  dangerouslySetInnerHTML={{
                    __html:
                      typeof contenidoDE === 'string'
                        ? DOMPurify.sanitize(contenidoDE)
                        : '',
                  }}
                />
              )}
              {!contenidoDE && '<contenidoDE>'}
            </Box>
          </Box>
        </Grid>
      </Grid>
    );
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={isModalOpen}
      maxWidth={'lg'}
    >
      <DialogTitle>
        <Grid container justify="space-between">
          {circular
            ? I18n.t('form.editarCircular')
            : I18n.t('form.crearNuevaCircular')}
          <IconButton size="small" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent dividers>
          {modoEdicion && renderFormulario()}
          {!modoEdicion && renderVistaPrevia()}
          <Button
            variant="contained"
            startIcon={modoEdicion ? <Visibility /> : <Edit />}
            onClick={() => setModoEdicion(!modoEdicion)}
          >
            {modoEdicion && I18n.t('form.vistaPrevia')}
            {!modoEdicion && I18n.t('form.modoEdicion')}
          </Button>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} className={classes.btnError} fullWidth>
            <Translate value="form.cancelar" />
          </Button>
          <Button type="submit" className={classes.btnSave} fullWidth>
            <Translate value="form.publicar" />
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    btnSave: {
      maxWidth: '160px',
      backgroundColor: theme.palette.success.main,
      '&:hover': {
        backgroundColor: theme.palette.success.light,
      },
    },
    btnError: {
      maxWidth: '160px',
      backgroundColor: theme.palette.error.main,
      '&:hover': {
        backgroundColor: theme.palette.error.light,
      },
    },
    formControl: {
      width: '100%',
    },
    textHtml: {
      maxWidth: '100%',
      '& p': {
        marginTop: 0,
        padding: 0,
      },
      '& img': {
        maxWidth: '100%',
        maxHeight: '100%',
        objectFit: 'contain',
      },
    },
  }),
);

export default FormCircular;
