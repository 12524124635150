import { Dialog, DialogTitle, Grid, IconButton } from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";
import { Estudiante } from "../../../../models/estudiante_model";
import { StepCursoEstudiante } from "./components/StepCursoEstudiante";

type FormCourseProps = {
  isModalOpen: boolean;
  handleClose: () => void;
  handleSave: (data: any) => void;
  estudiante: Estudiante | null;
};

const ModalCursoEstudiante: React.FC<FormCourseProps> = ({
  isModalOpen,
  handleSave,
  handleClose,
}) => {
  return (
    <Dialog
      onClose={handleClose}
      open={isModalOpen}
      fullWidth
      maxWidth="md"
      disableBackdropClick
    >
      <DialogTitle>
        <Grid container justify="space-between">
          Cambio de curso
          <IconButton size="small" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      <StepCursoEstudiante handleClose={handleClose} handleSave={handleSave} />
    </Dialog>
  );
};

export default ModalCursoEstudiante;
