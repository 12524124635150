import { KINDER_ASSISTANCE_TYPES, Action } from "store/types";
import { AsistenciaKinder } from "../../models/asistencia_kinder_model";

export type State = {
    asistenciaKinder: AsistenciaKinder | [];

};

const initialState: State = {
    asistenciaKinder: []
};

const reducer = (state: State = initialState, { type, payload }: Action) => {
  switch (type) {
    case KINDER_ASSISTANCE_TYPES.GET_KINDER_ASSISTANCE:
      return {
        ...state,
        asistenciaKinder: payload
      };
    default:
      return state;
  }
};

export default reducer;