import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Grid,
  IconButton,
  TextField,
  makeStyles,
  Theme,
  createStyles,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";
import {
  KeyboardDatePicker,
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { Permiso } from "models/permiso_model";
import React, { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import DateFnsUtils from "@date-io/date-fns";
import esl from "date-fns/locale/es";
import { useDispatch } from "react-redux";
import { cargarEstudiantes } from "store/actions/estudiante_actions";
import { Translate, I18n } from 'react-redux-i18n';
import { BuscadorMultiple } from "views/Kardex/components/BuscadorMultiple";
import { Buscador } from "views/Kardex/components/Buscador";
import moment from "moment";

type FormPermisoData = {
  id?: string;
  motivo: string;
  fechaInicio?: Date | string;
  fechaFin?: Date | string;
  fecha?: Date | string;
  descripcion?: string;
  adjuntos?: [] | string;
  estudianteId?: string;
  permisoParcial?: boolean;
  horaInicio?: Date | string;
  horaFin?: Date | string;
};

type FormPermisoProps = {
  permiso: Permiso | null;
  isModalOpen: boolean;
  handleClose: () => void;
  handleSave: (data: Permiso) => void;
};

const FormPermiso: React.FC<FormPermisoProps> = ({
  permiso,
  isModalOpen,
  handleClose,
  handleSave,
}) => {
  const { handleSubmit, control, setValue } = useForm<FormPermisoData>({
    shouldUnregister: false,
  });

  const [estudiante, setEstudiante] = React.useState<string[]>([]);
  const [permisoParcial, setPermisoParcial] = React.useState<boolean>(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(cargarEstudiantes());
  }, [dispatch]);

  useEffect(() => {
    if (permiso) {
      setTimeout(() => {
        setValue("motivo", permiso.motivo);
        setValue("descripcion", permiso.descripcion);
        setValue("fechaInicio", permiso.fechaInicio);
        setValue("fechaFin", permiso.fechaFin);
        setValue("estudianteId", permiso.estudianteId);
        setValue("permisoParcial", permiso.permisoParcial);
        setValue("horaInicio", permiso.fechaInicio);
        setValue("horaFin", permiso.fechaFin);
        setPermisoParcial(Boolean(permiso.permisoParcial));
        if (permiso.estudiante) {
          setEstudiante(String(permiso.estudianteId)?.split(','));
        }
      }, 1000);
    }
  }, [permiso, setValue]);

  const classes = useStyles();

  const onSubmit = (data: FormPermisoData) => {
    const horaInicio = `${moment(data.horaInicio).format('HH:mm')}:00`;
    const horaFin = `${moment(data.horaFin).format('HH:mm')}:00`;
    const fechaInicio = permisoParcial
      ? `${moment(data.fechaInicio).format('YYYY-MM-DD')} ${horaInicio}`
      : moment(data.fechaInicio).format('YYYY-MM-DD');
    const fechaFin = permisoParcial
      ? `${moment(data.fechaInicio).format('YYYY-MM-DD')} ${horaFin}`
      : moment(data.fechaFin).format('YYYY-MM-DD');

    handleSave({
      motivo: data.motivo,
      descripcion: data.descripcion,
      fechaInicio,
      fechaFin,
      estudianteId: estudiante.join(','),
      estado: "",
      adjuntos: [],
      fecha: "",
      otroMotivo: "",
      permisoParcial,
    });
  };

  const handleChangePermisoParcial = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setPermisoParcial(checked);
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={isModalOpen}
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle>
        <Grid container justify="space-between">
          {permiso ? <Translate value="formularioSolicitudPermiso" /> : <Translate value="formularioSolicitudPermiso" />}
          <IconButton size="small" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent dividers>
          <Box mb={3}>
            <Typography gutterBottom style={{ fontWeight: "bold" }}>
              <Translate value="form.datosUsuario" />
            </Typography>
            <Grid container spacing={2}>
              {!permiso && <Grid item xs={12}>
                <BuscadorMultiple value={estudiante} onSearchClick={async (estudianteId) => setEstudiante(estudianteId)} />
              </Grid>}
              {permiso && <Grid item xs={12}>
                <Buscador value={estudiante && estudiante.length > 0 ? estudiante[0] : null} onSearchClick={async (estudianteId) => setEstudiante([estudianteId])} />
              </Grid>}
            </Grid>
            <Box m={2} />
            <Typography gutterBottom style={{ fontWeight: "bold" }}>
              <Translate value="form.datosPermiso" />
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <Controller
                  name="descripcion"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      type="descripcion"
                      fullWidth
                      variant="filled"
                      required
                      label={I18n.t('form.descripcion')}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <Controller
                  name="motivo"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <FormControl
                      variant="filled"
                      required
                      className={classes.formControl}
                    >
                      <InputLabel>{I18n.t('form.seleccionaMotivo')}</InputLabel>
                      <Select {...field}>
                        <MenuItem value={"SALUD"}>{I18n.t('opcion.SALUD')}</MenuItem>
                        <MenuItem value={"VIAJE"}>{I18n.t('opcion.VIAJE')}</MenuItem>
                        <MenuItem value={"VIAJE_OTRO"}>{I18n.t('opcion.VIAJE_OTRO')}</MenuItem>
                        <MenuItem value={"TORNEO"}>{I18n.t('opcion.TORNEO')}</MenuItem>
                        {/* <MenuItem value={"AUSENCIA"}>{I18n.t('opcion.AUSENCIA')}</MenuItem> */}
                        <MenuItem value={"CITA_MEDICA"}>{I18n.t('opcion.CITA_MEDICA')}</MenuItem>
                        <MenuItem value={"TRAMITES"}>{I18n.t('opcion.TRAMITES')}</MenuItem>
                        <MenuItem value={"OTRO"}>{I18n.t('opcion.OTRO')}</MenuItem>
                      </Select>
                    </FormControl>
                  )}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <FormControlLabel
                  value="permisoParcial"
                  control={
                    <Checkbox
                      checked={permisoParcial}
                      color="primary"
                      onChange={handleChangePermisoParcial}
                    />
                  }
                  label={I18n.t('tienePermisoParcial')}
                  labelPlacement="start"
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <MuiPickersUtilsProvider locale={esl} utils={DateFnsUtils}>
                  <Controller
                    name="fechaInicio"
                    control={control}
                    defaultValue={null}
                    render={({ field: { ref, ...rest } }) => (
                      <KeyboardDatePicker
                        className={classes.formControl}
                        inputVariant="filled"
                        required
                        label={I18n.t('form.fechaInicio')}
                        autoOk
                        openTo="date"
                        views={["year", "month", "date"]}
                        format="dd/MM/yyyy"
                        {...rest}
                      />
                    )}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            </Grid>
            {!permisoParcial && <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <MuiPickersUtilsProvider locale={esl} utils={DateFnsUtils}>
                  <Controller
                    name="fechaFin"
                    control={control}
                    defaultValue={null}
                    render={({ field: { ref, ...rest } }) => (
                      <KeyboardDatePicker
                        className={classes.formControl}
                        inputVariant="filled"
                        required
                        label={I18n.t('form.fechaFin')}
                        autoOk
                        openTo="date"
                        views={["year", "month", "date"]}
                        format="dd/MM/yyyy"
                        {...rest}
                      />
                    )}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            </Grid>}
            {permisoParcial && (
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <MuiPickersUtilsProvider locale={esl} utils={DateFnsUtils}>
                    <Controller
                      name={"horaInicio"}
                      control={control}
                      defaultValue={null}
                      render={({ field }) => (
                        <KeyboardTimePicker
                          label="Hora de inicio"
                          fullWidth
                          autoOk
                          ampm
                          format="hh:mm a"
                          inputVariant="filled"
                          {...field}
                        />
                      )}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MuiPickersUtilsProvider locale={esl} utils={DateFnsUtils}>
                    <Controller
                      name={"horaFin"}
                      control={control}
                      defaultValue={null}
                      render={({ field }) => (
                        <KeyboardTimePicker
                          label="Hora fin"
                          fullWidth
                          autoOk
                          ampm
                          format="hh:mm a"
                          inputVariant="filled"
                          {...field}
                        />
                      )}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
              </Grid>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} className={classes.btnError} fullWidth>
            <Translate value="form.cancelar" />
          </Button>
          <Button type="submit" className={classes.btnSave} fullWidth>
            <Typography variant="body2" noWrap><Translate value="form.aceptarYGuardar" /></Typography>
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    btnSave: {
      backgroundColor: theme.palette.success.main,
      // margin: '0 3px',
      "&:hover": {
        backgroundColor: theme.palette.success.light,
      },
    },
    btnError: {
      backgroundColor: theme.palette.error.main,
      // margin: '0 3px',
      "&:hover": {
        backgroundColor: theme.palette.error.light,
      },
    },
    formControl: {
      // margin: theme.spacing(1),
      width: "100%",
      // minWidth: '100%',
    },
  })
);

export default FormPermiso;
