import { Evento } from "../../models/evento_model";
import { createAllEventos, createEvento, deleteEvento, editEvento, getEventos } from "../../repositories/evento_repository";
import { EVENTO_TYPES } from "../types";

export const cargarEventos = () => {
  return async (dispatch: any) => {
    const eventos: any = await getEventos();
    // console.log('eventos :: ', eventos, eventos.length);

    dispatch(setEventos(eventos || []));
  }
}

export const agregarEvento = (data: Evento) => {
  return async (dispatch: any) => {
    await createEvento(data);
  }
}

export const agregarTodosEventos = (data: Evento[]) => {
  return async (dispatch: any) => {
    await createAllEventos(data);
  }
}

export const editarEvento = (data: Evento, eventoId: string) => {
  return async (dispatch: any) => {
    await editEvento(data, eventoId);
  }
}

export const eliminarEvento = (id: string) => {
  return async (dispatch: any) => {
    await deleteEvento(id);
  }
}

export const changeEvento = (evento: Evento | null) => ({
  type: EVENTO_TYPES.SET_EVENTO,
  payload: evento
})

export const setEventos = (eventos: Evento[]) => ({
  type: EVENTO_TYPES.CARGAR_EVENTOS,
  payload: [...eventos]
})