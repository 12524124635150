import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete, { AutocompleteRenderGroupParams } from '@material-ui/lab/Autocomplete';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ListSubheader from '@material-ui/core/ListSubheader';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import { VariableSizeList, ListChildComponentProps } from 'react-window';
import { Checkbox, Typography } from '@material-ui/core';
import { I18n } from 'react-redux-i18n';

const LISTBOX_PADDING = 8; // px

function renderRow(props: ListChildComponentProps) {
  const { data, index, style } = props;
  return React.cloneElement(data[index], {
    style: {
      ...style,
      top: (style.top as number) + LISTBOX_PADDING,
    },
  });
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data: any) {
  const ref = React.useRef<VariableSizeList>(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

// Adapter for react-window
const ListboxComponent = React.forwardRef<HTMLDivElement>(function ListboxComponent(props, ref) {
  const { children, ...other } = props;
  const itemData = React.Children.toArray(children);
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });
  const itemCount = itemData.length;
  const itemSize = smUp ? 36 : 48;

  const getChildSize = (child: React.ReactNode) => {
    if (React.isValidElement(child) && child.type === ListSubheader) {
      return 48;
    }

    return itemSize;
  };

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  const gridRef = useResetCache(itemCount);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={(index: any) => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

const useStyles = makeStyles({
  listbox: {
    boxSizing: 'border-box',
    '& ul': {
      padding: 0,
      margin: 0,
    },
  },
});

const renderGroup = (params: AutocompleteRenderGroupParams) => [
  <ListSubheader key={params.key} component="div">
    {params.group}
  </ListSubheader>,
  params.children,
];

export type SelectOption = {
  value: string;
  label: string;
  caption?: string;
};

type Props = {
  options: SelectOption[];
  loading: boolean;
  onChange: (value: SelectOption[]) => void;
  value?: string[];
  label: string;
};

export default function Virtualize({ options, loading, onChange, label }: Props) {
  const classes = useStyles();
  const [todasOpciones, setTodasOpciones] = React.useState<SelectOption[]>([]);
  const [currentValue, setCurrentValue] = React.useState<SelectOption[]>([]);

  React.useEffect(() => {
    const newOptions: SelectOption[] = [{ value: '0', label: 'TODOS' }].concat([...options]);
    setTodasOpciones(newOptions);

    const newCurrentValue = currentValue.filter(opt => newOptions.some(x => x.value === opt.value));
    setCurrentValue(newCurrentValue);
    onChange(newCurrentValue.filter(x => x.value !== '0'));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options])

  const handleChange = (value: SelectOption[]) => {
    const newValue = value || [];

    if (!newValue.some(x => x.value === '0')) {
      if (currentValue.some(z => z.value === '0')) {
        setCurrentValue([]);
        onChange([]);
        return;
      }
    }

    if (newValue.some(x => x.value === '0')) {
      if (!currentValue.some(z => z.value === '0')) {
        setCurrentValue(todasOpciones);
        onChange(todasOpciones.filter(x => x.value !== '0'));
        return;
      }
    }

    if (newValue.some(v => v.value === '0')) {
      if (newValue.length < todasOpciones.length && newValue.length !== 1) {
        const newItem = todasOpciones
          .filter(opt => newValue.some(v => v.value === opt.value))
          .filter(opt => opt.value !== '0');

        setCurrentValue(newItem);
        onChange(newItem);
        return;
      }

      setCurrentValue(todasOpciones);
      onChange(todasOpciones.filter(x => x.value !== '0'));
      return;
    }

    const newItem = todasOpciones.filter(opt => newValue.some(v => v.value === opt.value));
    setCurrentValue(newItem);
    onChange(newItem.filter(x => x.value !== '0'));
  };

  return (
    <Autocomplete
      id="virtualize-demo-2"
      disableListWrap
      classes={classes}
      ListboxComponent={ListboxComponent as React.ComponentType<React.HTMLAttributes<HTMLElement>>}
      renderGroup={renderGroup}
      options={todasOpciones.map(x => x.value)}
      value={currentValue.map(x => x.value)}
      getOptionLabel={(value) => todasOpciones.find(opt => opt.value === value)?.label || ''}
      multiple
      disableCloseOnSelect
      onChange={(event, value) => handleChange(todasOpciones.filter(x => value.includes(x.value)))}
      noOptionsText={` >>> ${I18n.t('reporte.sinOpciones')} <<<`}
      renderInput={(params: any) => (
        <TextField
            {...params}
            name="buscador"
            label={loading ? `${I18n.t('reporte.cargando')}...` : `${label}...`}
            variant="filled"
        />
      )}
      renderOption={(option, state) => {
        const checked = state.selected
        return (
          <>
            <Checkbox
              style={{ color: checked ? '#99BA82' : 'unset' }}
              checked={checked}
            />
            <Typography noWrap>{todasOpciones.find(opt => opt.value === option)?.label || ''}</Typography>
          </>
        )
      }}
    />
  );
}
