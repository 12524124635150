import {
  Box,
  Button,
  Grid,
  LinearProgress,
  makeStyles,
  TextField,
  Theme,
  Typography,
} from "@material-ui/core";
import React, { ReactElement, useEffect } from "react";
import { useIsMounted } from "hooks/useIsMounted";
import { Controller, useForm } from "react-hook-form";
import message from "plugins/utils/message";
import { MyDropzone } from "components/core/MyDropzone";
import { crearTarea, modificarTarea, buscarTarea } from "repositories/clase_repository";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import esl from "date-fns/locale/es";
import { I18n, Translate } from 'react-redux-i18n';
import moment from "moment";

type FormData = {
  id?: string;
  titulo: string;
  descripcion: string;
  fecha: Date | string;
  claseId: string;
  adjuntos: string;
};

export async function getTarea(claseId: string) {
  const tareaEncontrada: any = await buscarTarea(claseId);
  const tareaPrincipal = tareaEncontrada && tareaEncontrada.length > 0 ? tareaEncontrada[0] : null;

  if (!tareaPrincipal) return { tarea: null };

  const tarea: FormData = {
    id : tareaPrincipal.id,
    titulo: tareaPrincipal.titulo,
    descripcion: tareaPrincipal.descripcion,
    fecha: moment(tareaPrincipal.fecha).toDate(),
    claseId,
    adjuntos: JSON.stringify(tareaPrincipal.adjuntos.map((item: string) => ({ id: item.substring(0, 36), fileName: item, filePath: '' }))),
  };
  return { tarea };
}

type StepAsistenciaProps = {
  cursoId: string;
  claseId: string;
  materiaId: string;
  onGuardarClick: () => void;
};

const StepTarea = ({ cursoId, claseId, materiaId, onGuardarClick }: StepAsistenciaProps): ReactElement => {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const isMounted = useIsMounted();

  const [myValue, setMyValue] = React.useState<string>('[]');
  const { setValue, control, handleSubmit } = useForm<FormData>({ shouldUnregister: false });

  const cargarEstudiantes = async () => {
    setLoading(true);
    const result = await getTarea(claseId);
    if (isMounted()) setLoading(false);
    if(result.tarea) {
      setValue("id", result.tarea.id);
      setValue("titulo", result.tarea.titulo);
      setValue("descripcion", result.tarea.descripcion);
      setValue("claseId", claseId);
      setValue("fecha", moment(result.tarea.fecha).toDate());
      setValue("adjuntos", result.tarea.adjuntos);
      setMyValue(result.tarea.adjuntos);
    };
  };
  const cargadoInicial = async () => {
    cargarEstudiantes();
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const cargadoInicialCallback = React.useCallback(cargadoInicial, []);

  useEffect(() => {
    cargadoInicialCallback();
  }, [cargadoInicialCallback]);

  const handleGuardarClick = async (formValues: any) => {
    const data = {
      id: formValues.id,
      titulo: formValues.titulo,
      descripcion: formValues.descripcion,
      fecha: moment(formValues.fecha).format('YYYY-MM-DD'),
      claseId,
      adjuntos: JSON.parse(myValue).map((item: any) => item.fileName).join(','),
    };
    if (formValues.id) {
      await modificarTarea(formValues.id, data);
    }
    if (!formValues.id) {
      await crearTarea(data);
    }
    message.success(I18n.t('tareasGuardadasConExito'));
    onGuardarClick();
  };

  return (
    <>
      {loading && <Box position="absolute" left="0" right="0">
        <LinearProgress variant="indeterminate" color="secondary"/>
      </Box>}
      <Box className={classes.root}>
      <form onSubmit={handleSubmit(handleGuardarClick)}>
        <Box position="relative">
            <Box>
              <Typography gutterBottom style={{ fontWeight: 'bold' }}>
                  <Translate value="form.tituloTarea" />
              </Typography>
            </Box>

            <Box mb={3}>
              <Grid item xs={12} sm={12}>
                <Controller
                  name="titulo"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      type="titulo"
                      fullWidth
                      disabled={loading}
                      variant="outlined"
                      required
                      hiddenLabel
                    />
                  )}
                />
              </Grid>
            </Box>

            <Box>
              <Typography gutterBottom style={{ fontWeight: 'bold' }}>
                  <Translate value="form.descripcion" />
              </Typography>
            </Box>

            <Box mb={3}>
              <Grid item xs={12} sm={12}>
                <Controller
                  name="descripcion"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      type="textarea"
                      fullWidth
                      multiline
                      disabled={loading}
                      rows={4}
                      rowsMax={8}
                      variant="outlined"
                      required
                      hiddenLabel
                    />
                  )}
                />
              </Grid>
            </Box>

            <Box>
              <Typography gutterBottom style={{ fontWeight: 'bold' }}>
                  <Translate value="form.seleccioneFechaEntrega" />
              </Typography>
            </Box>

            <Box mb={3}>
              <MuiPickersUtilsProvider locale={esl} utils={DateFnsUtils}>
                <Controller
                  name="fecha"
                  control={control}
                  defaultValue={null}
                  render={({ field: { ref, ...rest } }) => (
                    <KeyboardDatePicker
                      style={{ width: "100%" }}
                      inputVariant="outlined"
                      required
                      hiddenLabel
                      disabled={loading}
                      autoOk
                      openTo="date"
                      views={["year", "month", "date"]}
                      format="dd/MM/yyyy"
                      {...rest}
                    />
                  )}
                />
              </MuiPickersUtilsProvider>
            </Box>

            <Box>
              <Typography gutterBottom style={{ fontWeight: 'bold' }}>
                  <Translate value="form.adjuntos" />
              </Typography>
            </Box>

            <Box mb={3}>
              <MyDropzone
                  label=""
                  name="adjuntos"
                  disabled={loading}
                  value={myValue}
                  maxFileSize={50 * 1024 * 1024}
                  dropzoneText={`${I18n.t('form.arrastreYSuelteUnArchivo')}. (${I18n.t('form.tamanioMaximo')} 50MB)`}
                  onChange={(value: any) => {
                      setMyValue(String(value));
                      setValue('adjuntos', JSON.parse(String(value)).map((item: any) => item.fileName).join(','));
                  }}
              />
            </Box>

          <Box display="flex" alignContent="center" justifyContent="center">
            <Button type="submit" disabled={loading} className={classes.btnSaveForm} fullWidth>
              <Typography variant="body2" noWrap><Translate value="form.guardar" /></Typography>
            </Button>
          </Box>

        </Box>
      </form>
      </Box>
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'relative',
    padding: theme.spacing(3),
  },
  btnSaveForm: {
    margin: theme.spacing(4, 'auto'),
    maxWidth: '150px',
    maxHeight: '50px',
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
  },
}));

export default StepTarea;
