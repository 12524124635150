import DateFnsUtils from '@date-io/date-fns';
import {
  Box,
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Theme,
  Typography,
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import esl from 'date-fns/locale/es';
import moment from 'moment';
import message from 'plugins/utils/message';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { I18n, Translate } from 'react-redux-i18n';
import { Buscador } from 'views/Kardex/components/Buscador';

type FormPermisoData = {
  //id?: string;
  // estudianteId?: string; // EstudianteId se esta manejando mediante useState en vez de useForm
  descripcion?: string;
  motivo: string;
  fecha?: Date | string | null;
  hora: string;
};

type FormPermisoProps = {
  permiso?: any;
  isModalOpen: boolean;
  handleClose: () => void;
  handleSave: (data: any) => void;
  codigoCurso: string;
};

const ModalPermissions: React.FC<FormPermisoProps> = ({
  codigoCurso,
  isModalOpen,
  handleClose,
  handleSave,
}) => {
  const { handleSubmit, control, reset } = useForm<FormPermisoData>({
    shouldUnregister: false,
    defaultValues: {
      descripcion: '',
      fecha: null,
      hora: '',
    },
  });

  const [estudiante, setEstudiante] = React.useState<string[]>([]);

  const classes = useStyles();

  useEffect(() => {
    // Resetear el formulario cuando se cierra
    if (!isModalOpen) {
      reset();
      setEstudiante([]);
    }
  }, [reset, isModalOpen]);

  const onSubmit = (data: FormPermisoData) => {
    if (!estudiante.join(',')) {
      message.error('Seleccione un estudiante');
      return;
    }

    const fechaString = `${moment(data.fecha).format('YYYY-MM-DD')}`;

    handleSave({
      estudianteId: estudiante.join(','),
      descripcion: data.descripcion,
      motivo: data.motivo,
      fecha: fechaString,
      hora: data.hora,
    });
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={isModalOpen}
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle>
        <Grid container justify="space-between">
          <Translate value="kinder.formularioRegistroPermiso" />
          <IconButton size="small" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent dividers>
          <Box mb={3}>
            <Typography gutterBottom style={{ fontWeight: 'bold' }}>
              <Translate value="kinder.datosEstudiante" />
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Buscador
                  isKinder={true}
                  codigoCurso={codigoCurso}
                  value={
                    estudiante && estudiante.length > 0 ? estudiante[0] : null
                  }
                  onSearchClick={async (estudianteId) =>
                    setEstudiante([estudianteId])
                  }
                />
              </Grid>
            </Grid>
            <Box m={2} />
            <Typography gutterBottom style={{ fontWeight: 'bold' }}>
              <Translate value="kinder.datosPermiso" />
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <Controller
                  name="descripcion"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      type="descripcion"
                      fullWidth
                      variant="filled"
                      required
                      label={I18n.t('form.descripcion')}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <Controller
                  name="motivo"
                  control={control}
                  render={({ field }) => (
                    <FormControl
                      variant="filled"
                      required
                      className={classes.formControl}
                    >
                      <InputLabel>{I18n.t('form.seleccionaMotivo')}</InputLabel>
                      <Select {...field}>
                        <MenuItem value={'SALUD'}>
                          {I18n.t('opcion.SALUD')}
                        </MenuItem>
                        <MenuItem value={'VIAJE'}>
                          {I18n.t('opcion.VIAJE')}
                        </MenuItem>
                        <MenuItem value={'VIAJE_OTRO'}>
                          {I18n.t('opcion.VIAJE_OTRO')}
                        </MenuItem>
                        <MenuItem value={'TORNEO'}>
                          {I18n.t('opcion.TORNEO')}
                        </MenuItem>
                        <MenuItem value={'CITA_MEDICA'}>
                          {I18n.t('opcion.CITA_MEDICA')}
                        </MenuItem>
                        <MenuItem value={'TRAMITES'}>
                          {I18n.t('opcion.TRAMITES')}
                        </MenuItem>
                        <MenuItem value={'OTRO'}>
                          {I18n.t('opcion.OTRO')}
                        </MenuItem>
                      </Select>
                    </FormControl>
                  )}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <MuiPickersUtilsProvider locale={esl} utils={DateFnsUtils}>
                  <Controller
                    name="fecha"
                    control={control}
                    defaultValue={null}
                    render={({ field: { ref, ...rest } }) => (
                      <KeyboardDatePicker
                        //className={classes.formControl}
                        style={{ width: '70%' }}
                        inputVariant="filled"
                        required
                        label={I18n.t('form.fecha')}
                        autoOk
                        openTo="date"
                        views={['year', 'month', 'date']}
                        format="dd/MM/yyyy"
                        {...rest}
                      />
                    )}
                  />
                </MuiPickersUtilsProvider>
                <Controller
                  name="hora"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      required
                      style={{ width: '30%', paddingLeft: 5 }}
                      variant="filled"
                      type="time"
                      label={<Translate value="hora" />}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} className={classes.btnError} fullWidth>
            <Translate value="form.cancelar" />
          </Button>
          <Button type="submit" className={classes.btnSave} fullWidth>
            <Typography variant="body2" noWrap>
              <Translate value="form.aceptarYGuardar" />
            </Typography>
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    btnSave: {
      backgroundColor: theme.palette.success.main,
      // margin: '0 3px',
      '&:hover': {
        backgroundColor: theme.palette.success.light,
      },
    },
    btnError: {
      backgroundColor: theme.palette.error.main,
      // margin: '0 3px',
      '&:hover': {
        backgroundColor: theme.palette.error.light,
      },
    },
    formControl: {
      // margin: theme.spacing(1),
      width: '100%',
      // minWidth: '100%',
    },
    studentSelector: {
      minWidth: '350px',
    },
  }),
);

export default ModalPermissions;
