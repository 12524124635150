// import { IProfesor } from "../../models/profesor_model";
import { IProfesor } from "../../models/profesor_model";
import { allProfesorsRepository, createProfesorRepository } from "../../repositories/profesor";
import { PROFESOR_TYPES } from "../types";


export const cargarProfesores = (isKinder?:boolean) => {
  return async (dispatch: any) => {
    const profesores: any = await allProfesorsRepository(isKinder);

    // console.log('[actions] profesors :: ', profesores);

    dispatch( setProfesores(profesores || []) );
  }
}

export const addProfesor = (nombre: string, ci: string) => {
  return async (dispatch: any) => {
    const newProfesor = {
      nombre,
      ci
    };

    await createProfesorRepository(newProfesor);
  }
}

export const setProfesores = (profesores: Array<IProfesor>) => ({
  type: PROFESOR_TYPES.LOAD_PROFESORS,
  payload: [...profesores]
})