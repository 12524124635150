import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import Box from '@material-ui/core/Box';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import LanguageIcon from '@material-ui/icons/Language';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import FormChangeDialog from './FormChangeDialog';
import { Button, ListItem, useMediaQuery } from '@material-ui/core';
// import { useIsMounted } from 'hooks/useIsMounted';
import ProfileIcon from '../../assets/img/icons/profile.svg';
import { RootState, useStateSelector } from 'store/reducers';
import { useDispatch } from "react-redux";
import { asyncLogout } from "../../store/actions/auth_actions";
import { Translate, setLocale } from 'react-redux-i18n';
import { editUsuario } from "store/actions/user";

export type UserInfo = {
    nombre: string;
    email: string;
    rol: string;
    idioma: string;
}

const DialogProfile = () => {
    const classes = useStyles();
    // const notify = useNotify();
    // const isMounted = useIsMounted();
    const history = useHistory();
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));
    const { usuario } = useStateSelector((state: RootState) => state.authState);
    const [userInfo, setUserInfo] = React.useState<UserInfo>({ nombre: '', rol: '', idioma: '', email: '' })
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    // const [imagen, setImagen] = useState<string>(authUser?.imagen);
    const openMenu = Boolean(anchorEl);
    // const [openDialog, setOpenDialog] = useState(false);
    const [openDialogChangePassword, setOpenDialogChangePassword] = useState(false);
    // const [loading, setLoading] = useState<boolean>(false);
    const dispatch = useDispatch();
    const { locale } = useStateSelector((state: RootState) => state.i18n);
    // const [notificationPermission] = useLocalStorage('notificationPermission', 'denied');

    const handleDialogCloseChangePassword = () => {
        setOpenDialogChangePassword(!openDialogChangePassword);
    };

    const handleOnIdiomaClick = (e: any) => {
        const newLocale = locale === 'es' ? 'de' : 'es';
        localStorage.setItem('locale', newLocale);
        dispatch(setLocale(newLocale));
        dispatch(editUsuario({ idioma: newLocale === 'es' ? 'es_ES' : 'de_De' }, usuario.uid));
    };

    // const handleOpen = () => {
    //     setOpenDialog(true);
    //     setAnchorEl(null);
    // };
    const handleOpenChangePassword = () => {
        setOpenDialogChangePassword(true);
        setAnchorEl(null);
    };
    const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };
    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        dispatch(asyncLogout());
    };

    useEffect(() => {
        const user = localStorage.getItem('user');
        const locale = localStorage.getItem('locale');
        setUserInfo({
            nombre: usuario.displayName,
            idioma: locale || '',
            rol: user ? JSON.parse(user).rol : '',
            email: usuario.email,
        })
    }, [usuario])

    return (
        <>
            <div>
                <Button
                    startIcon={<img src={ProfileIcon} alt="profile icon" />}
                    color="inherit"
                    aria-label="Profile"
                    aria-controls="long-menu"
                    aria-haspopup="true"
                    onClick={handleOpenMenu}
                >
                    <Typography noWrap>
                        {isDesktop ? userInfo.nombre : ''}
                    </Typography>
                    {/* <div data-tour="step-menuperfil">
                        <Avatar alt="avatar" src={'imagen_perfil'} />
                    </div> */}
                </Button>
                {/* <IconButton
                    color="inherit"
                    aria-label="Profile"
                    aria-controls="long-menu"
                    aria-haspopup="true"
                    onClick={handleOpenMenu}
                >
                    <div data-tour="step-menuperfil">
                        <Avatar alt="avatar" src={'imagen_perfil'} />
                    </div>
                </IconButton> */}
            </div>
            <Menu
                id="long-menu"
                anchorEl={anchorEl}
                open={openMenu}
                keepMounted
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                onClose={handleCloseMenu}
            >
                <div data-tour="step-perfilbox">
                <ListItem button className={classes.user}>
                    <Box pt={1} pb={1} pl={2} pr={2} display="flex">
                        {/* <Box>
                            <Avatar
                                alt="Nombre Usuario"
                                src="url_imagen"
                                className={classes.large}
                            />
                        </Box> */}
                        <Box width="100%" overflow="hidden" display="flex" flexDirection="column">
                            <Typography variant="subtitle2">{userInfo.nombre}</Typography>
                            <Typography
                                variant="caption"
                                // style={{ color: 'rgba(255, 255, 255, 0.71)' }}
                                noWrap
                                gutterBottom
                            >
                                {userInfo.email}
                            </Typography>
                            {[userInfo.rol].map((rolName, index) => (
                                <Typography key={index} variant="caption" noWrap>
                                    <i>{rolName}</i>
                                </Typography>
                            ))}
                        </Box>
                    </Box>
                </ListItem>
                <MenuItem button onClick={handleOnIdiomaClick} data-tour="step-imagenperfil">
                    <ListItemIcon>
                        <LanguageIcon />
                    </ListItemIcon>
                    <ListItemText primary={<Typography noWrap><Translate value="idioma" /></Typography>} />
                </MenuItem>
                <MenuItem button onClick={handleOpenChangePassword} data-tour="step-cambiarpass">
                    <ListItemIcon>
                        <VpnKeyIcon />
                    </ListItemIcon>
                    <ListItemText primary="Cambiar contraseña" />
                </MenuItem>
                <MenuItem onClick={() => history.push('help')} data-tour="step-ayuda">
                    <ListItemIcon>
                        <HelpOutlineIcon />
                    </ListItemIcon>
                    <ListItemText primary="Ayuda" />
                </MenuItem>
                {openDialogChangePassword && (
                    <FormChangeDialog
                        openDialog={openDialogChangePassword}
                        handleDialogClose={handleDialogCloseChangePassword}
                    />
                )}
                <ListItem onClick={() => handleLogout()} button data-tour="step-cerrarsesion">
                    <ListItemIcon>
                        <PowerSettingsNewIcon />
                    </ListItemIcon>
                    <ListItemText primary="Cerrar Sesión" />
                </ListItem>
                </div>
            </Menu>
        </>
    );
};

const useStyles = makeStyles((theme: Theme) => ({
    user: {
        backgroundColor: '#f2e172 !important',
        cursor: 'auto',
    },
    large: {
        width: theme.spacing(12),
        height: theme.spacing(12),
    },
}));

export default DialogProfile;
