import { Box, DialogContent, Grid, Typography, Button } from '@material-ui/core';
import React from 'react'
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { Translate } from 'react-redux-i18n';

type FormNewTopicProps = {
  handleClose: () => void;
  setDisplayLateAttendance: (val: boolean) => void;
  handleAbsentWithoutLicense: () => void;
  handlePresent: () => void;
}

const FormAttendance: React.FC<FormNewTopicProps> = ({
  handleClose,
  setDisplayLateAttendance,
  handleAbsentWithoutLicense,
  handlePresent
}) => {
  // const classes = useStyles();

  const setDelayForm = () => {
    setDisplayLateAttendance(true);
  }

  return (
    <form>
      <DialogContent dividers>
        <Box my={3}>
          <Grid container spacing={6}>
            <Grid item xs={12} sm={12}>
              <Typography gutterBottom style={{}}>
                <Translate value="kinder.seleccioneEstadoAsistencia" />:
              </Typography>
            </Grid>
            <Grid container item xs={12} sm={12}>
              <ButtonGroup fullWidth={true} variant="text" aria-label="text primary button group" orientation="vertical">
                <Button onClick={handlePresent}><strong><Translate value="asistenciaPresente" /></strong></Button>
                <Button onClick={handleAbsentWithoutLicense}><strong><Translate value="asistenciaFaltaSinLicencia" /></strong></Button>
                <Button onClick={setDelayForm}><strong><Translate value="asistenciaRetraso" /></strong></Button>
                <Button onClick={handleClose}><Translate value="kinder.cancelar" /></Button>
              </ButtonGroup>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
    </form>
  )
}

// const useStyles = makeStyles((theme: Theme) =>
//   createStyles({
//     btnSave: {
//       padding: theme.spacing(1, 4),
//       backgroundColor: theme.palette.success.main,
//       // margin: '0 3px',
//       "&:hover": {
//         backgroundColor: theme.palette.success.light,
//       },
//     },
//     btnError: {
//       padding: theme.spacing(1, 4),
//       backgroundColor: theme.palette.error.main,
//       // margin: '0 3px',
//       "&:hover": {
//         backgroundColor: theme.palette.error.light,
//       },
//     },
//     formControl: {
//       margin: theme.spacing(1),
//       width: "100%",
//       // minWidth: '100%',
//     },
//     stepRoot: {
//       display: 'flex',
//       justifyContent: 'center',
//     },
//     small: {
//       width: theme.spacing(3),
//       height: theme.spacing(3),
//       backgroundColor: theme.palette.primary.main
//     },
//     activeStep: {
//       margin: theme.spacing(0, 1),
//       width: theme.spacing(2),
//       height: theme.spacing(2),
//       backgroundColor: theme.palette.primary.main,
//       borderRadius: '50%'
//     },
//     inactiveStep: {
//       margin: theme.spacing(0, 1),
//       width: theme.spacing(2),
//       height: theme.spacing(2),
//       backgroundColor: theme.palette.grey[400],
//       borderRadius: '50%'
//     },
//     label: {
//       fontWeight: theme.typography.fontWeightBold,
//       color: theme.palette.common.black
//     },
//     input: {
//       margin: 0,
//     },
//   })
// );

export default FormAttendance;