import React, {
  forwardRef,
  ReactElement,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import clsx from 'clsx';
import { v4 as uuid } from 'uuid';

// material-ui
import {
  makeStyles,
  Theme,
  FormHelperText,
  FormControl,
  FormLabel,
  Chip,
  Box,
  CircularProgress,
  LinearProgress,
  Typography,
  Tooltip,
} from '@material-ui/core';
import { DropzoneAreaBase, FileObject } from 'material-ui-dropzone';
import CloseIcon from '@material-ui/icons/Close';
import message from 'plugins/utils/message';

type FormDialogRefProps = {
  isPrepared: () => boolean;
};

type FileItem = {
  id: string;
  fileName: string;
  filePath: string;
  fileType: string; // All types: https://www.freeformatter.com/mime-types-list.html
  fileObject?: FileObject;
};

type OnChangeFunction = (
  value:
    | string
    | number
    | boolean
    | null
    | (string | number | boolean | null)[],
  formik?: any,
  fieldValue?: unknown,
) => void | Promise<void>;

const DEFAULT_FILES_LIMIT = 15;
const DEFAULT_ACCEPTED_FILES: string[] = []; // More info: https://react-dropzone.js.org/#section-accepting-specific-file-types Ej: ['image/*', 'video/*', 'application/*']
const DEFAULT_MAX_FILE_SIZE = 150 * 1024 * 1024; // bytes
const DEFAULT_DROPZONE_TEXT = `Arrastre y suelte un archivo aquí o haga click. (Tamaño máximo: 150MB)`;

type ProgressState = { id: string; value: number } | null;

type Props = {
  name: string;
  label: string;
  value?: string;
  disabled?: boolean;
  fieldRequired?: string;
  error?: boolean;
  helperText?: string;
  formik?: any;
  filesLimit?: number;
  filesExt?: string[];
  acceptedFiles?: string[];
  maxFileSize?: number;
  dropzoneText?: string;
  variant?: 'filled' | 'standard' | 'outlined';
  onChange?: OnChangeFunction;
};

/**
 * Permite cargar archivos en un campo de formulario todo de manera local.
 * @param props 
 * @param ref 
 * @returns 
 */
const DropzoneComponent = (
  props: Props,
  ref: React.Ref<FormDialogRefProps>,
): ReactElement => {
  const { label, error, helperText } = props;

  const classes = useStyles();

  const errorText = '';

  // controlled or uncontrolled
  const _val = typeof props.value !== 'undefined' ? props.value : '[]';
  let VALUE: FileItem[] = [];
  try {
    VALUE = _val ? JSON.parse(_val || '[]') : [];
  } catch (err) {
    console.log(err);
  }
  const FILES_EXT = props.filesExt;
  const FILES_LIMIT = props.filesLimit || DEFAULT_FILES_LIMIT;
  const MAX_FILE_SIZE = props.maxFileSize || DEFAULT_MAX_FILE_SIZE;
  const ACCEPTED_FILES = props.acceptedFiles || DEFAULT_ACCEPTED_FILES;
  const DROPZONE_TEXT = props.dropzoneText || DEFAULT_DROPZONE_TEXT;

  // const VARIANT = props.variant  || DEFAULT_VARIANT;
  const FIELD_REQUIRED = props.fieldRequired;
  const [DISABLED, setDisabled] = useState(false);
  const requiredValue: any = FIELD_REQUIRED ? undefined : undefined;
  useEffect(() => {
    const disableByProp =
      typeof props.disabled !== 'undefined' ? props.disabled : false;
    let newFieldDisabled = disableByProp;
    if (!disableByProp && FIELD_REQUIRED) {
      newFieldDisabled =
        requiredValue === undefined ||
        requiredValue === '' ||
        requiredValue === null ||
        requiredValue === '[]' || // Para los archivos adjuntos (MyDropzone)
        requiredValue === '{}' || // Para los grupos de checkbox (MyCheckboxGroup)
        (Array.isArray(requiredValue) && requiredValue.length === 0);
    }
    setDisabled(newFieldDisabled);
  }, [props.disabled, FIELD_REQUIRED, requiredValue]);

  const progressList = [
    useState<ProgressState>(null),
    useState<ProgressState>(null),
    useState<ProgressState>(null),
    useState<ProgressState>(null),
    useState<ProgressState>(null),
    useState<ProgressState>(null),
    useState<ProgressState>(null),
    useState<ProgressState>(null),
    useState<ProgressState>(null),
    useState<ProgressState>(null),
  ];

  const getExt = (obj: FileObject): string => {
    const dotIndex = obj.file.name.indexOf('.');
    if (dotIndex > -1) {
      return obj.file.name.split('.').pop() || '';
    }
    return '';
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const isUploading = (fileItem?: FileItem): boolean => {
    if (!fileItem) return progressList.some((state) => state[0] !== null);
    return progressList.some(
      (state) => state[0] !== null && state[0].id === fileItem.id,
    );
  };

  const handleDropzoneOnAdd = (newFileObjs: FileObject[]) => {
    // Verifica el límite de archivos
    if (newFileObjs.length > FILES_LIMIT) {
      message.error('Solo puede cargar ' + FILES_LIMIT + ' archivo(s).');
      return;
    }
    if (VALUE.length + newFileObjs.length > FILES_LIMIT) {
      message.error('Solo puede cargar ' + FILES_LIMIT + ' archivo(s).');
      return;
    }

    // Verifica las extensiones permitidas
    if (FILES_EXT) {
      const EXT_VALIDAS = FILES_EXT.map((ext) => `*.${ext}`).join(', ');
      const archivosNoValidos = newFileObjs.filter((obj) => {
        const ext = getExt(obj);
        const esValido = FILES_EXT.includes(ext);
        if (!esValido) {
          message.error(
            `Formato de archivo incorrecto (${obj.file.name}). Extensiones válidas: ${EXT_VALIDAS}`,
          );
        }
        return !esValido;
      });
      if (archivosNoValidos.length > 0) return;
    }

    // Agrega los archivos al estado local
    const newFileItems: FileItem[] = newFileObjs.map((fileObject) => ({
      id: uuid(),
      fileName: fileObject.file.name,
      filePath: '', // No se utiliza
      fileType: '*',
      fileObject: fileObject,
    }));

    const updatedFiles = [...VALUE, ...newFileItems];

    if (props.onChange) props.onChange(JSON.stringify(updatedFiles));
  };

  const handleDropzoneOnDelete = async (deleteFileItem: FileItem) => {
    if (isUploading(deleteFileItem)) {
      message.warning('Por favor espere a que se guarde el archivo.');
      return;
    }
    const newFileItemValue = VALUE.filter(
      (item: FileItem) => item.id !== deleteFileItem.id,
    );
    const newValue = JSON.stringify(
      newFileItemValue.map(({ id, fileName, filePath, fileType }) => ({
        id,
        fileName,
        filePath,
        fileType,
      })),
    );

    if (props.onChange) props.onChange(newValue);
  };

  const handleClick = (fileItem: FileItem) => {
    if (isUploading(fileItem)) {
      message.warning('Por favor espere a que se guarde el archivo.');
      return;
    }

    const base64Data = String(fileItem.fileObject?.data).split(',')[1];

    if (!base64Data) {
      message.error('No se encontró el archivo.');
      return;
    }

    // Verifica que la cadena base64 sea válida
    if (!/^[A-Za-z0-9+/]+={0,2}$/.test(base64Data)) {
      message.error('La cadena base64 no es válida.');
      return;
    }

    // Convertir base64 a un Blob
    const byteCharacters = atob(base64Data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const fileType =
      fileItem.fileObject?.file?.type || getFileTypeFromName(fileItem.fileName);

    const blob = new Blob([byteArray], { type: fileType });
    const FILE_URL = URL.createObjectURL(blob);

    window.open(FILE_URL);
  };

  const getFileTypeFromName = (fileName: string) => {
    const extension = fileName?.split('.')?.pop()?.toLowerCase();

    if (!extension) return 'application/octet-stream';

    const mimeTypes: { [key: string]: string } = {
      pdf: 'application/pdf',
      jpg: 'image/jpeg',
      jpeg: 'image/jpeg',
      png: 'image/png',
      txt: 'text/plain',
      doc: 'application/msword',
      docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      xls: 'application/vnd.ms-excel',
      xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    };
    return mimeTypes[extension] ?? 'application/octet-stream';
  };

  const handleOnAlert = (msg: string, variant: string) => {
    if (variant === 'error') {
      if (msg.includes('File is too big')) {
        const maxSize = Number(MAX_FILE_SIZE / 1024 / 1024);
        return message.error(
          `El archivo es demasiado grande. Tamaño máximo permitido: ${maxSize}MB`,
        );
      }
      console.log(`Error al adjuntar el archivo. ${msg}`);
    }
  };

  const truncateText = (text: string, max: number = 20) => {
    if (text.length <= max) return text;
    const middle = parseInt(`${max / 2}`);
    const a = text.substr(0, middle);
    const b = text.substr(text.length - middle);
    const truncated = `${a} ... ${b}`;
    return truncated;
  };

  const refHandler = () => ({
    isPrepared: () => !isUploading(),
  });
  useImperativeHandle(ref, refHandler, [isUploading]);

  return (
    <FormControl className={classes.formControl} error={error || !!errorText}>
      <div className={classes.formContainer}>
        <FormLabel component="legend" className={classes.formLabel}>
          {label}
        </FormLabel>
        {isUploading() && (
          <Box className={classes.dropzoneUploadingContainer}>
            <Typography align="center" variant="h6">
              Guardando archivos...
            </Typography>
            <Typography align="center" variant="body1">
              Por favor espere a que se guarden todos los archivos antes de
              continuar.
            </Typography>
          </Box>
        )}
        <DropzoneAreaBase
          fileObjects={[]}
          onAdd={handleDropzoneOnAdd}
          dropzoneClass={clsx(classes.dropzoneContainer, {
            [classes.dropzoneContainerHidden]: isUploading(),
          })}
          dropzoneParagraphClass={clsx(classes.dropzoneParagraph, {
            [classes.error]: !!errorText,
          })}
          dropzoneText={DROPZONE_TEXT}
          showPreviewsInDropzone={false}
          useChipsForPreview={false}
          showAlerts={false}
          filesLimit={FILES_LIMIT}
          acceptedFiles={ACCEPTED_FILES}
          maxFileSize={MAX_FILE_SIZE}
          onAlert={handleOnAlert}
          inputProps={{ disabled: DISABLED }}
        />
        <Box className={classes.chipContainer}>
          {VALUE.map((item) => {
            const state = progressList.find(
              (state) => state[0] !== null && state[0].id === item.id,
            );
            const value = state && state[0] !== null ? state[0].value : null;
            const fileNameOriginal = item.fileName.replace(`${item.id}-`, '');
            return (
              <Box key={item.id} className={classes.itemContainer}>
                <Tooltip title={fileNameOriginal} placement="top">
                  <Chip
                    className={clsx(classes.chip, {
                      [classes.chipLoaded]: !value,
                    })}
                    label={truncateText(fileNameOriginal)}
                    variant="outlined"
                    deleteIcon={
                      value !== null ? (
                        <CircularProgress size="small" />
                      ) : (
                        <CloseIcon />
                      )
                    }
                    onDelete={() => handleDropzoneOnDelete(item)}
                    onClick={() => handleClick(item)}
                    disabled={DISABLED}
                  />
                </Tooltip>
                {value !== null ? (
                  <LinearProgress
                    className={classes.loaderBar}
                    variant="determinate"
                    value={value}
                  />
                ) : (
                  <></>
                )}
              </Box>
            );
          })}
        </Box>
      </div>
      <FormHelperText className={classes.helperText}>
        {helperText || errorText}
      </FormHelperText>
    </FormControl>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  formControl: {
    width: '100%',
    paddingTop: theme.spacing(0),
  },
  formContainer: {
    borderTopLeftRadius: theme.spacing(0.5),
    borderTopRightRadius: theme.spacing(0.5),
    background: '#e8e8e8',
  },
  dropzoneContainer: {
    minHeight: theme.spacing(0),
    padding: theme.spacing(0, 1, 1, 1),
  },
  dropzoneContainerHidden: {
    display: 'none',
  },
  dropzoneUploadingContainer: {
    border: 'dashed',
    borderColor: 'rgba(0, 0, 0, 0.12)',
    borderRadius: '4px',
    background: '#fff',
    color: 'rgba(34,47,62,.7)',

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '80px',
    padding: theme.spacing(1),

    backgroundColor: '#a3cc72',
    backgroundImage: `linear-gradient(
          -45deg,
          rgba(255, 255, 255, .2) 25%,
          transparent 25%,
          transparent 50%,
          rgba(255, 255, 255, .2) 50%,
          rgba(255, 255, 255, .2) 75%,
          transparent 75%,
          transparent
        )`,
    backgroundSize: '50px 50px',
    animation: '$move 2s linear infinite',
  },
  dropzoneParagraph: {
    color: 'rgba(34,47,62,.7)',
    fontSize: '18px',
    padding: theme.spacing(0),
    margin: theme.spacing(1, 0, 0, 0),
  },
  error: {
    color: 'red',
  },
  formLabel: {
    padding: theme.spacing(1, 1, 1, 1.5),
  },
  helperText: {
    paddingLeft: theme.spacing(1.5),
  },
  '@keyframes move': {
    '0%': {
      backgroundPosition: '0 0',
    },
    '100%': {
      backgroundPosition: '50px 50px',
    },
  },
  itemContainer: {
    position: 'relative',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '5px',
  },
  chipContainer: {
    minHeight: theme.spacing(1),
  },
  chip: {
    zIndex: 2,
  },
  chipLoaded: {
    backgroundColor: '#ffffff',
  },
  loaderBar: {
    position: 'absolute',
    width: 'calc(100% - 10px)',
    height: 'calc(100% - 10px)',
    bottom: '4px',
    left: '5px',
    borderRadius: '16px',
    backgroundColor: '#ffffff',
    '& .MuiLinearProgress-barColorPrimary': {
      backgroundColor: '#a3cc72',
      backgroundImage: `linear-gradient(
              -45deg,
              rgba(255, 255, 255, .2) 25%,
              transparent 25%,
              transparent 50%,
              rgba(255, 255, 255, .2) 50%,
              rgba(255, 255, 255, .2) 75%,
              transparent 75%,
              transparent
            )`,
      backgroundSize: '50px 50px',
      animation: '$move 2s linear infinite',
    },
  },
}));

export const FormInputDropzone = forwardRef(DropzoneComponent);
