import {
  Box,
  makeStyles,
  Paper,
  Theme,
  Typography,
} from "@material-ui/core";
import React, { forwardRef, useImperativeHandle, useRef } from "react";
import { Translate } from "react-redux-i18n";
import AnotacionesReporteSimple, { AnotacionesReporteSimpleRefProps } from "./AnotacionesReporteSimple";

export type AnotacionesRefProps = {
  refresh: () => void;
};

type Props = {
  estudiante: string | null;
};

const AnotacionesComponent = ({ estudiante }: Props, ref: React.Ref<AnotacionesRefProps>) => {
  const classes = useStyles();
  const tableRef = useRef<AnotacionesReporteSimpleRefProps>(null);

  const tableHandler = () => ({
    refresh: () => {
      tableRef.current?.refresh();
    },
  });
  useImperativeHandle(ref, tableHandler, []);

  return (
    <Box className={classes.root}>
      <Box display="flex" alignItems="center">
        <svg width="38" height="50" viewBox="0 0 38 50" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0.0188354 24.1003C0.748382 11.6839 14.195 -6.28818 23.3851 2.1971C29.0077 7.38844 24.3504 14.1416 26.5826 21.4232C29.4504 30.7782 39.0998 34.11 37.8968 43.8131C35.8617 60.2275 -0.951214 40.6098 0.0188354 24.1003Z" fill="#90B8ED"/>
          <path d="M24.2086 11.1113C29.6036 11.1113 32.9862 14.4339 32.9862 19.7965V23.0213L32.9764 23.1655C32.9053 23.6843 32.4559 24.0841 31.9122 24.0841H31.8998L31.7302 24.0706C31.5073 24.035 31.3001 23.9296 31.1402 23.7678C30.9404 23.5655 30.8315 23.2919 30.8381 23.0091V19.7965C30.8381 15.5699 28.4801 13.2368 24.2086 13.2368H13.6902C9.40636 13.2368 7.06071 15.5699 7.06071 19.7965V30.2162C7.06071 34.4427 9.4187 36.7636 13.6902 36.7636H24.2086C28.4925 36.7636 30.8381 34.4305 30.8381 30.2162C30.8381 29.6293 31.319 29.1534 31.9122 29.1534C32.5054 29.1534 32.9862 29.6293 32.9862 30.2162C32.9862 35.5665 29.6283 38.8891 24.2209 38.8891H13.6902C8.27057 38.8891 4.9126 35.5665 4.9126 30.2162V19.7965C4.9126 14.4339 8.27057 11.1113 13.6902 11.1113H24.2086ZM12.9865 21.6043C13.2715 21.6139 13.5409 21.7351 13.7354 21.9413C13.9299 22.1476 14.0336 22.4218 14.0236 22.7037V31.5843C14.0031 32.1712 13.5057 32.6306 12.9125 32.6104C12.3193 32.5902 11.855 32.0979 11.8755 31.511V22.6182L11.8946 22.452C11.9377 22.2342 12.0497 22.0339 12.2168 21.8814C12.4256 21.6909 12.7028 21.5911 12.9865 21.6043ZM18.9988 17.4389C19.592 17.4389 20.0729 17.9147 20.0729 18.5016V31.5232C20.0729 32.1102 19.592 32.586 18.9988 32.586C18.4056 32.586 17.9247 32.1102 17.9247 31.5232V18.5016C17.9247 17.9147 18.4056 17.4389 18.9988 17.4389ZM24.9493 26.295C25.5425 26.295 26.0234 26.7709 26.0234 27.3578V31.511C26.0234 32.0979 25.5425 32.5738 24.9493 32.5738C24.3561 32.5738 23.8753 32.0979 23.8753 31.511V27.3578C23.8753 26.7709 24.3561 26.295 24.9493 26.295Z" fill="#323232"/>
        </svg>
        <Box pl={1} py={2}>
          <Typography variant="h6"><strong><Translate value="reporte.anotaciones"/></strong></Typography>
        </Box>
      </Box>
      <Paper elevation={3}>
        <AnotacionesReporteSimple ref={tableRef} estudiante={estudiante} />
      </Paper>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: theme.spacing(2, 0),
    paddingLeft: theme.spacing(4),
    minWidth: '45vw',
  },
  picture: {
    padding: "20px",
    margin: "10px",
    width: "150px",
    height: "150px",
    border: "solid #c6ba8e69 2px",
    borderRadius: "50%",
    backgroundColor: "#FFF7B0",
  },
  table: {
    minWidth: 650,
  },
}));

export const Anotaciones = forwardRef(AnotacionesComponent);
