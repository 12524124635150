import { Avatar, Box, makeStyles, Paper, Theme, Typography } from "@material-ui/core";
import React from "react";
import { ReporteEstudiante } from "..";
import ProfileIcon from "../../../assets/img/icons/profile.svg";

type Props = {
  reporteEstudiante: ReporteEstudiante | null;
};

export const FotoPerfil: React.FC<Props> = ({ reporteEstudiante }) => {
  const classes = useStyles();

  const datosGenerales = reporteEstudiante?.datosGenerales;

  return (
    <Box>
    <Paper elevation={3}>
      <Box className={classes.root}>
        <Box display="flex" alignItems="center" justifyContent="center">
          <Box className={!datosGenerales?.estudiante?.foto ? classes.picture : classes.avatar}>
            <Avatar style={{marginRight: '5px', width:'110px', height:'110px'}} src={datosGenerales?.estudiante?.foto || ProfileIcon} alt="profile icon"/>
            {/* <img
              width={110}
              height={110}
              src={datosGenerales?.estudiante?.foto || ProfileIcon}
              alt="profile icon"
            /> */}
          </Box>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column">
          <Typography align="center">
            <strong>
              {datosGenerales?.estudiante.nombre}
            </strong>
          </Typography>
          <Typography align="center">
            {datosGenerales?.estudiante.correo}
          </Typography>
          <Typography align="center">
            <strong>
              {datosGenerales ? `${datosGenerales.curso.codigoCurso} - ${datosGenerales.curso.gestion}` : ''}
            </strong>
          </Typography>
          <Typography align="center">
            <strong>
              {datosGenerales ? `${datosGenerales.estudiante.codigoPadre}` : ''}
            </strong>
          </Typography>
        </Box>
      </Box>
    </Paper>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    // margin: theme.spacing(4),
    padding: theme.spacing(4),
    borderRadius: "20px",
  },
  picture: {
    padding: "20px",
    margin: "10px",
    width: "150px",
    height: "150px",
    border: "solid  2px",
    borderRadius: "50%",
    backgroundColor: "#FFF7B0",
  },
  avatar: {
    padding: "20px",
    margin: "10px",
    width: "150px",
    height: "150px",
  }
}));
