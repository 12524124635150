import { AltaMedica } from "../../models/altaMedica_model";
import {
  infoAltaMedica,
  createAltaMedica,
  deleteAltaMedica,
  editAltaMedica,
  aceptarRechazarAltaMedica,
} from "../../repositories/altaMedica_repository";

export const agregarAltaMedica = (data: AltaMedica) => {
  return async (dispatch: any) => {
    await createAltaMedica(data);
  };
};

export const editarAltaMedica = (data: AltaMedica, altaMedicaId: string) => {
  return async (dispatch: any) => {
    await editAltaMedica(data, altaMedicaId);
  };
};

export const masInfoAltaMedica = (data: AltaMedica, altaMedicaId: string) => {
  return async (dispatch: any) => {
    await infoAltaMedica(data, altaMedicaId);
  };
};

export const responderAltaMedica = (data: AltaMedica, altaMedicaId: string) => {
  return async (dispatch: any) => {
    await aceptarRechazarAltaMedica(data, altaMedicaId);
  };
};

export const eliminarAltaMedica = (id: string) => {
  return async (dispatch: any) => {
    await deleteAltaMedica(id);
  };
};
