import http from "./http";
import { AltaMedica } from "../models/altaMedica_model";

export const createAltaMedica = async (data: AltaMedica) => {
  try {
    return await http.post(`/altaMedica/`, data);
  } catch (error) {
    console.error("[create] Error :: ", error);
    return error;
  }
};

export const editAltaMedica = async (data: AltaMedica, id: string) => {
  try {
    return await http.put(`/altaMedica/${id}`, data);
  } catch (error) {
    console.error("[put] Error :: ", error);
    return error;
  }
};

export const infoAltaMedica = async (data: AltaMedica, id: string) => {
  try {
    return await http.put(`/altaMedica/${id}/mas-info`, data);
  } catch (error) {
    console.error("[put] Error :: ", error);
    return error;
  }
};

export const deleteAltaMedica = async (id: string) => {
  try {
    return await http.delete(`/altaMedica/${id}`);
  } catch (error) {
    console.error("[delete] Error :: ", error);
    return error;
  }
};

export const aceptarRechazarAltaMedica = async (data: AltaMedica, id: string) => {
  try {
    return await http.put(`/altaMedica/${id}/responder`, data);
  } catch (error) {
    console.error("[put] Error :: ", error);
    return error;
  }
};

// export const sendEmail = async (id: string) => {
//   try {
//     return await http.post(`/altaMedica/${id}/send-mail`, {});
//   } catch (error) {
//     console.error("[post] Error :: ", error);
//     return error;
//   }
// };

// export const buscarCorreo = async (id: string) => {
//   try {
//     return await http.get(`/altaMedica/${id}/buscar-correo`, {});
//   } catch (error) {
//     console.error("[post] Error :: ", error);
//     return error;
//   }
// };
