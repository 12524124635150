import React, { useEffect } from 'react'
import {
  Paper,
  Box,
  IconButton,
  makeStyles,
  Typography,
  useTheme,
  TextField,
  Grid,
} from '@material-ui/core';
import MainLayout from '../../../../components/layout/MainLayout';
import { useHistory } from 'react-router';
import { RootState, useStateSelector } from '../../../../store/reducers';
import { useDispatch } from 'react-redux';
import MateriaPage from '../../materia';
// import { cargarCursoPorId } from '../../../../store/actions/curso_actions';

type CursoDetalleProps = {}
const CursoDetalle: React.FC<CursoDetalleProps> = () => {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const dispatch = useDispatch();
  const { curso } = useStateSelector((state: RootState) => state.cursoState);

  useEffect(() => {
    // if (curso) {
    //   curso.id ? dispatch( cargarCursoPorId(curso.id) ) : null;
    // } else {
    //   console.log('no se seleccionó un curso!!!');
    //   history.push('/courses');
    // }
    if (!curso) history.push('/courses');
  }, [dispatch, curso, history])

  return (
    <MainLayout>
      { curso && (
        <React.Fragment>
          <Box mb={2} display='flex' alignItems='center'>
            <IconButton onClick={() => history.push('/courses')}>
              <svg width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M8.85853 11.4101C9.09252 11.2839 9.2384 11.0438 9.2384 10.7827V6.71729H17.2657C17.671 6.71729 18 6.39596 18 6.00003C18 5.6041 17.671 5.28276 17.2657 5.28276H9.2384V1.21731C9.2384 0.955274 9.09252 0.71523 8.85853 0.589948C8.62453 0.462753 8.33865 0.471361 8.11248 0.610031L0.342671 5.39274C0.129236 5.52472 0 5.75329 0 6.00003C0 6.24676 0.129236 6.47533 0.342671 6.60731L8.11248 11.39C8.23193 11.4627 8.36802 11.5 8.50411 11.5C8.62551 11.5 8.74789 11.4694 8.85853 11.4101Z" 
                  fill={theme.palette.error.main}
                />
              </svg>
            </IconButton>
            <Typography variant='h6'>
              Información del curso
            </Typography>
          </Box>
          <Paper className={classes.root}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={3}>
                <TextField
                  variant='filled'
                  label='Nivel'
                  value={curso.nivel}
                  fullWidth
                  disabled
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField variant='filled' fullWidth label='Turno' value={curso.turno} disabled />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField variant='filled' fullWidth label='Grado' value={curso.grado} disabled />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField variant='filled' fullWidth label='Codigo de Curso' value={`${curso.codigoCurso} - ${curso.gestion}`} disabled />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField variant='filled' fullWidth label='Paralelo' value={curso.paralelo} disabled />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField variant='filled' fullWidth label='Jefe de curso' value={curso.jefeCurso?.nombre} disabled />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField variant='filled' fullWidth label='Fecha de inicio' value={curso.fechaInicioFormato} disabled />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField variant='filled' fullWidth label='Fecha de finalización' value={curso.fechaFinFormato} disabled />
              </Grid>
            </Grid>
          </Paper>
          <Box mt={2}>
            <Typography gutterBottom style={{ fontWeight: "bold" }}>
              Materias
            </Typography>
            { curso.id && (
              <MateriaPage cursoId={curso.id} />
            )}
          </Box>
        </React.Fragment>
      )}
    </MainLayout>
  )
}

const useStyles = makeStyles({
  root: {
    width: "100%",
    padding: 10
  },
});

export default CursoDetalle;