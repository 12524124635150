import {
  Box,
  Button,
  FormControl,
  Grid,
  LinearProgress,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Theme,
  Typography,
} from '@material-ui/core';
import React, { useEffect } from 'react';
import { I18n, Translate } from 'react-redux-i18n';
import message from '../../../plugins/utils/message';
import { listarPermisos } from 'repositories/permiso_repository';
import { useIsMounted } from 'hooks/useIsMounted';
import { Permiso } from 'models/permiso_model';
import { Refresh as RefreshIcon } from '@material-ui/icons';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import esLocale from 'date-fns/locale/es';
import { parse as dateParse } from 'date-fns';
import moment from 'moment';

type Props = {
  estudiante: string | null;
};

const urlFile = process.env.REACT_APP_FILE_URL;

const FORMAT = 'dd/MM/yyyy';
const DEFAULT_DESDE = moment().startOf('month').format('DD/MM/YYYY');
const DEFAULT_HASTA = moment().format('DD/MM/YYYY');

interface Column {
  id: 'nro' | 'comentario' | 'motivo' | 'adjunto' | 'fecha';
  label: string;
  minWidth?: number;
  align?: 'right';
  format?: (value: number) => string;
}

function buildColumns(): Column[] {
  return [
    { id: 'nro', label: I18n.t('tabla.nro') },
    { id: 'fecha', label: I18n.t('fechaSolicitud') },
    { id: 'motivo', label: I18n.t('motivo') },
    { id: 'comentario', label: I18n.t('kinder.comentario') },
    { id: 'adjunto', label: I18n.t('form.adjuntos') },
  ];
}

export const Permisos: React.FC<Props> = ({ estudiante }) => {
  const classes = useStyles();
  const isMounted = useIsMounted();

  const [columns, setColumns] = React.useState(buildColumns());

  const [loading, setLoading] = React.useState<boolean>(false);
  const [permisosAprobados, setPermisos] = React.useState<Permiso[]>([]);
  const [count, setCount] = React.useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [page, setPage] = React.useState(0);

  const [desde, setDesde] = React.useState<string | null>(DEFAULT_DESDE);
  const [hasta, setHasta] = React.useState<string | null>(DEFAULT_HASTA);

  const cargarPermisosAprobados = async ({
    estudiante,
    page,
    rowsPerPage,
    desde,
    hasta,
  }: {
    estudiante: string | null;
    page: number;
    rowsPerPage: number;
    desde: string | null;
    hasta: string | null;
  }) => {
    if (!estudiante) return;
    setLoading(true);

    let desdeTmp = moment(desde, 'DD/MM/YYYY');
    let hastaTmp = moment(hasta, 'DD/MM/YYYY');

    const filters = {
      _page: page + 1,
      _limit: rowsPerPage,
      estudiante,
      desde: desdeTmp.isValid() ? desdeTmp.format('YYYY-MM-DD') : undefined,
      hasta: hastaTmp.isValid() ? hastaTmp.format('YYYY-MM-DD') : undefined,
      estado: 'Aprobado',
    };

    const result = await listarPermisos(filters);
    if (isMounted()) setLoading(false);
    if (result) {
      setPermisos(result.rows);
      setCount(result.count);
    }
  };

  const handleRefreshPermisos = () => {
    cargarPermisosAprobados({
      estudiante,
      page,
      rowsPerPage,
      desde,
      hasta,
    });
    setColumns(buildColumns());
  };

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPage(newPage);
    cargarPermisosAprobados({
      estudiante,
      page: newPage,
      rowsPerPage,
      desde,
      hasta,
    });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const newRowsPerPage = +event.target.value;
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    cargarPermisosAprobados({
      estudiante,
      page: 0,
      rowsPerPage: newRowsPerPage,
      desde,
      hasta,
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const cargarPermisosCallback = React.useCallback(cargarPermisosAprobados, [
    estudiante,
  ]);

  useEffect(() => {
    cargarPermisosCallback({
      estudiante,
      page,
      rowsPerPage,
      desde,
      hasta,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cargarPermisosCallback, desde, hasta]);

  // #region RENDER COLUMNAS
  const renderAdjuntos = (adjuntos: string) => {
    return (
      <ul className={classes.adjuntos}>
        {String(adjuntos)
          .split(',')
          .map((item, itemIndex) => (
            <li key={itemIndex}>
              <a href={urlFile + '/' + item} target="_blank" rel="noreferrer">
                {item.indexOf('.pdf') !== -1 && (
                  <svg
                    width="18"
                    height="20"
                    viewBox="0 0 18 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.8534 0C16.0668 0 17.9913 1.95143 18 5.20971V14.7903C18 18.0486 16.0842 20 12.8621 20H10.0058C9.6395 19.959 9.36224 19.6452 9.36224 19.2715C9.36224 18.8978 9.6395 18.584 10.0058 18.543H12.8534C15.3091 18.543 16.5457 17.2804 16.5457 14.7903V5.20971C16.5457 2.71965 15.3091 1.45695 12.8534 1.45695H5.13788C2.68215 1.45695 1.43686 2.71965 1.43686 5.20971V14.7903C1.43686 17.2804 2.68215 18.543 5.13788 18.543C5.50419 18.584 5.78145 18.8978 5.78145 19.2715C5.78145 19.6452 5.50419 19.959 5.13788 20C1.92453 20 0 18.0486 0 14.7903V5.20971C0 1.9426 1.92453 0 5.13788 0H12.8534ZM12.5922 13.5099C12.9865 13.5099 13.3062 13.8341 13.3062 14.234C13.3062 14.6339 12.9865 14.9581 12.5922 14.9581H5.38171C4.98734 14.9581 4.66763 14.6339 4.66763 14.234C4.66763 13.8341 4.98734 13.5099 5.38171 13.5099H12.5922ZM12.5922 9.27152C12.8677 9.24072 13.1364 9.37244 13.2837 9.61057C13.431 9.84871 13.431 10.1513 13.2837 10.3894C13.1364 10.6276 12.8677 10.7593 12.5922 10.7285H5.38171C5.01541 10.6875 4.73814 10.3737 4.73814 10C4.73814 9.62632 5.01541 9.31248 5.38171 9.27152H12.5922ZM8.13353 5.04194C8.49983 5.0829 8.7771 5.39674 8.7771 5.77042C8.7771 6.1441 8.49983 6.45794 8.13353 6.4989H5.39042C5.02411 6.45794 4.74685 6.1441 4.74685 5.77042C4.74685 5.39674 5.02411 5.0829 5.39042 5.04194H8.13353Z"
                      fill="#200E32"
                    />
                  </svg>
                )}
                {item.indexOf('.pdf') === -1 && (
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.3 0C17.686 0 19.96 2.371 19.96 5.899V14.05C19.96 17.578 17.686 19.949 14.3 19.949H10.98C10.566 19.949 10.23 19.613 10.23 19.199C10.23 18.785 10.566 18.449 10.98 18.449H14.3C16.827 18.449 18.46 16.722 18.46 14.05V5.899C18.46 3.226 16.827 1.5 14.3 1.5H5.65C3.129 1.5 1.5 3.226 1.5 5.899V14.05C1.5 16.722 3.129 18.449 5.65 18.449H6.371C6.785 18.449 7.121 18.785 7.121 19.199C7.121 19.613 6.785 19.949 6.371 19.949H5.65C2.271 19.949 0 17.578 0 14.05V5.899C0 2.371 2.271 0 5.65 0H14.3ZM13.2915 9.5434C13.9825 9.5224 14.6585 9.7854 15.1405 10.2824L17.2175 12.4234C17.5055 12.7214 17.4985 13.1964 17.2005 13.4844C16.9035 13.7734 16.4285 13.7644 16.1405 13.4684L14.0635 11.3274C13.8755 11.1334 13.6295 11.0244 13.3525 11.0424C13.0825 11.0534 12.8395 11.1754 12.6675 11.3844L10.4385 14.0964C10.0455 14.5744 9.4655 14.8644 8.8475 14.8914C8.2285 14.9044 7.6265 14.6804 7.1925 14.2394L6.3065 13.3354C6.1765 13.2014 6.0115 13.0834 5.8275 13.1294C5.6465 13.1304 5.4795 13.2034 5.3555 13.3334L3.8255 14.9484C3.6785 15.1044 3.4805 15.1824 3.2815 15.1824C3.0965 15.1824 2.9105 15.1144 2.7655 14.9774C2.4655 14.6924 2.4525 14.2174 2.7375 13.9164L4.2665 12.3024C4.6745 11.8704 5.2265 11.6314 5.8215 11.6294C6.3955 11.6674 6.9705 11.8624 7.3825 12.2904L8.2635 13.1884C8.4005 13.3284 8.5805 13.4064 8.7815 13.3924C8.9775 13.3834 9.1545 13.2954 9.2795 13.1434L11.5085 10.4314C11.9485 9.8964 12.5985 9.5724 13.2915 9.5434ZM6.5603 4.6296C7.9403 4.6296 9.0633 5.7526 9.0633 7.1336C9.0633 8.5146 7.9403 9.6376 6.5603 9.6376C5.1793 9.6376 4.0563 8.5146 4.0563 7.1336C4.0563 5.7526 5.1793 4.6296 6.5603 4.6296ZM6.5603 6.1296C6.0063 6.1296 5.5563 6.5796 5.5563 7.1336C5.5563 7.6876 6.0063 8.1376 6.5603 8.1376C7.1133 8.1376 7.5633 7.6876 7.5633 7.1336C7.5633 6.5796 7.1133 6.1296 6.5603 6.1296Z"
                      fill="#323232"
                    />
                  </svg>
                )}
                <span className={classes.link}>
                  {item.length > 30
                    ? `${item.substring(0, 10)}...${item.substring(
                        item.length - 25,
                      )}`
                    : item}
                </span>
                <svg
                  width="17"
                  height="18"
                  viewBox="0 0 17 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16.8611 9.14147C16.666 8.90748 16.295 8.7616 15.8915 8.7616H9.60854V0.734294C9.60854 0.328964 9.11193 0 8.50004 0C7.88815 0 7.39154 0.328964 7.39154 0.734294V8.7616L1.10858 8.7616C0.703606 8.7616 0.332628 8.90748 0.139011 9.14147C-0.0575638 9.37547 -0.0442619 9.66135 0.170048 9.88752L7.56151 17.6573C7.76548 17.8708 8.11872 18 8.50004 18C8.88136 18 9.2346 17.8708 9.43857 17.6573L16.83 9.88752C16.9424 9.76807 17 9.63198 17 9.49589C17 9.37449 16.9527 9.25211 16.8611 9.14147Z"
                    fill="#323232"
                  />
                </svg>
              </a>
            </li>
          ))}
      </ul>
    );
  };

  const renderFecha = (permiso: Permiso) => {
    const {
      fechaInicioFormato,
      fechaFinFormato,
      permisoParcial,
      horaFinFormato,
      esPermisoKinder,
      horaInicioFormato,
    } = permiso;
    return (
      <Typography>
        {fechaInicioFormato === fechaFinFormato ? (
          <td>{fechaInicioFormato}</td>
        ) : (
          <td>
            {fechaInicioFormato} al {fechaFinFormato}
          </td>
        )}
        {permisoParcial && (
          <>
            <Typography>
              {horaInicioFormato}{' '}
              {!esPermisoKinder ? `hasta ${horaFinFormato}` : ''}
            </Typography>
          </>
        )}
      </Typography>
    );
  };
  // #endregion

  return (
    <Box className={classes.root}>
      <Box display="flex" alignItems="center">
        <svg
          width="38"
          height="50"
          viewBox="0 0 38 50"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.0188354 24.1003C0.748382 11.6839 14.195 -6.28818 23.3851 2.1971C29.0077 7.38844 24.3504 14.1416 26.5826 21.4232C29.4504 30.7782 39.0998 34.11 37.8968 43.8131C35.8617 60.2275 -0.951214 40.6098 0.0188354 24.1003Z"
            fill="#E0BAFF"
            fill-opacity="0.4"
          />

          <path
            d="M21 16.5c0 .829-.672 1.5-1.5 1.5H13.5c-.829 0-1.5-.671-1.5-1.5s.671-1.5 1.5-1.5h6c.828 0 1.5.671 1.5 1.5Zm-6.5 14.5h-2c-1.378 0-2.5-1.122-2.5-2.5V15.5c0-1.378 1.122-2.5 2.5-2.5H20.5c1.379 0 2.5 1.122 2.5 2.5V24.5c0 .829.672 1.5 1.5 1.5s1.5-.671 1.5-1.5V15.5C26 12.467 23.532 10 20.5 10H13.5C10.467 10 8 12.467 8 15.5v13c0 3.033 2.467 5.5 5.5 5.5h2c.829 0 1.5-.671 1.5-1.5s-.671-1.5-1.5-1.5Zm0-6h-1c-.829 0-1.5.671-1.5 1.5s.671 1.5 1.5 1.5h1c.829 0 1.5-.671 1.5-1.5s-.671-1.5-1.5-1.5Zm16.043.422c-.594-.576-1.543-.561-2.121.034l-5.102 5.271c-.354.369-.996.36-1.338-.02l-2.391-2.535c-.567-.602-1.517-.63-2.121-.062-.603.568-.63 1.518-.062 2.121l2.391 2.535c1.461 1.607 4.148 1.644 5.66.065l5.119-5.288c.576-.595.561-1.545-.035-2.121Zm-11.043-5.422H13.5c-.829 0-1.5.671-1.5 1.5s.671 1.5 1.5 1.5h6c.828 0 1.5-.671 1.5-1.5s-.672-1.5-1.5-1.5Z"
            fill="#323232"
          />
        </svg>

        <Box pl={1} py={2}>
          <Typography variant="h6">
            <strong>
              <Translate value="solicitudesPermisosAprobados" />
            </strong>
          </Typography>
        </Box>
      </Box>
      <Paper
        elevation={3}
        style={{
          marginTop: 10,
        }}
      >
        <Grid container spacing={3}>
          <Paper
            style={{
              maxWidth: '100%',
              width: '100%',
            }}
          >
            {loading && (
              <Box left={0} right={0}>
                <LinearProgress color="primary" />
              </Box>
            )}
            <Box p={2} display={'flex'}>
              <Box maxWidth={'100%'}>
                <Button
                  variant="contained"
                  onClick={() => handleRefreshPermisos()}
                  style={{ marginLeft: 10 }}
                  startIcon={<RefreshIcon />}
                  disabled={loading || !estudiante}
                >
                  <Translate value="refrescar" />
                </Button>
              </Box>
              <Box display="flex">
                <FormControl className={classes.formControl} variant="standard">
                  <MuiPickersUtilsProvider
                    utils={DateFnsUtils}
                    locale={esLocale}
                  >
                    <KeyboardDatePicker
                      variant="inline"
                      className={classes.input}
                      id="filtro-desde"
                      label={<Translate value="tabla.desde" />}
                      autoOk
                      format="dd/MM/yyyy"
                      value={
                        desde ? dateParse(desde, FORMAT, new Date()) : null
                      }
                      inputVariant="standard"
                      maxDate={new Date()}
                      disabled={loading || !estudiante}
                      onChange={(date) => {
                        if (date && moment(date).isValid()) {
                          const dateDesde = moment(date).format('DD/MM/YYYY');
                          if (
                            hasta &&
                            moment(dateDesde, 'DD/MM/YYYY').isAfter(
                              moment(hasta, 'DD/MM/YYYY'),
                            )
                          ) {
                            message.warning(
                              I18n.t('fechaDesdeMenorFechaHasta'),
                            );
                            return;
                          }
                          setDesde(dateDesde);
                        }
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </FormControl>

                <FormControl className={classes.formControl}>
                  <MuiPickersUtilsProvider
                    utils={DateFnsUtils}
                    locale={esLocale}
                  >
                    <KeyboardDatePicker
                      variant="inline"
                      className={classes.input}
                      id="filtro-hasta"
                      label={<Translate value="tabla.hasta" />}
                      autoOk
                      format="dd/MM/yyyy"
                      value={
                        hasta ? dateParse(hasta, FORMAT, new Date()) : null
                      }
                      disabled={loading || !estudiante}
                      maxDate={new Date()}
                      onChange={(date, value) => {
                        if (date && moment(date).isValid()) {
                          const dateHasta = moment(date).format('DD/MM/YYYY');
                          if (
                            desde &&
                            moment(dateHasta, 'DD/MM/YYYY').isBefore(
                              moment(desde, 'DD/MM/YYYY'),
                            )
                          ) {
                            message.warning(
                              I18n.t('fechaHastaMayorFechaDesde'),
                            );
                            return;
                          }
                          setHasta(dateHasta);
                        }
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </FormControl>
              </Box>
            </Box>

            <React.Fragment>
              <TableContainer className={classes.tableContainer}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{
                            minWidth: column.minWidth,
                            fontWeight: 'bold',
                          }}
                        >
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {permisosAprobados.length === 0 ||
                    permisosAprobados === undefined ||
                    permisosAprobados === null ? (
                      <TableRow>
                        <TableCell colSpan={999}>
                          <Typography variant="subtitle1" align="center">
                            <Translate value="sinRegistroPermisos" />
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ) : (
                      <>
                        {permisosAprobados.map((permiso: Permiso, index) => {
                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={permiso.id}
                            >
                              <TableCell key="nro">
                                <Typography>
                                  {index + 1 + page * rowsPerPage}
                                </Typography>
                              </TableCell>
                              <TableCell
                                style={{
                                  maxWidth: '100px',
                                }}
                              >
                                {renderFecha(permiso)}
                              </TableCell>
                              <TableCell style={{
                                maxWidth: '100px',
                              }}>
                                <Translate value={`opcion.${permiso.motivo}`} />{' '}
                                {permiso.permisoParcial &&
                                  !permiso.esPermisoKinder && (
                                    <strong>
                                      --{' '}
                                      <Translate value="tienePermisoParcial" />
                                    </strong>
                                  )}
                              </TableCell>
                              <TableCell className={classes.tableCell}>
                                {permiso.descripcion}
                              </TableCell>
                              <TableCell className={classes.tableCell}>
                                {permiso.adjuntos ? (
                                  renderAdjuntos(String(permiso.adjuntos))
                                ) : (
                                  <Typography>
                                    <Translate value="sinAdjuntos" />
                                  </Typography>
                                )}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 50, 100, 250]}
                component="div"
                count={count}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                labelRowsPerPage={<Translate value="tabla.filasPorPagina" />}
                labelDisplayedRows={({ from, to, count }) =>
                  `${from}-${to} ${I18n.t('tabla.de')} ${
                    count !== -1 ? count : `${I18n.t('tabla.masDe')} ${to}`
                  }`
                }
              />
            </React.Fragment>
          </Paper>
        </Grid>
      </Paper>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: theme.spacing(2, 0),
  },
  picture: {
    padding: '20px',
    margin: '10px',
    width: '150px',
    height: '150px',
    border: 'solid #c6ba8e69 2px',
    borderRadius: '50%',
    backgroundColor: '#FFF7B0',
  },
  formControl: {
    width: '160px',
    padding: '0 0 0 20px',
  },
  input: {
    margin: 0,
  },
  tableContainer: {
    maxHeight: 'calc(100vh - 150px)',
    '&::-webkit-scrollbar': {
      width: '3px',
    },
  },
  adjuntos: {
    listStyle: 'none',
    paddingLeft: '10px',
  },
  link: {
    color: '#323232',
    display: 'inline-block',
    padding: '0 10px 0',
    textDecoration: 'underline',
  },
  tableCell: {
    maxWidth: '200px',
  },
}));
