import { Action, TICKET_TYPES } from 'store/types';

export type State = {
  tickets: any[];
};

const initialState: State = {
  tickets: [],
};

const reducer = (state: State = initialState, { type, payload }: Action) => {
  switch (type) {
    case TICKET_TYPES.LOAD_TICKETS:
      return {
        ...state,
        tickets: payload,
      };
    default:
      return state;
  }
};

export default reducer;
