import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import rootReducer from './reducers'
import {
  setLocale,
  loadTranslations,
  syncTranslationWithStore } from "react-redux-i18n";
import translations from "../translations/i18n";

// const composeEnhancers = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;
const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;


const store = createStore(
  rootReducer,
  composeEnhancers(
    applyMiddleware( thunk )
  )
)

const loadTranslationsAction: any = loadTranslations(translations);
const setLocalAction: any = setLocale(localStorage.getItem('locale') || 'es');

syncTranslationWithStore(store);
store.dispatch(loadTranslationsAction);
store.dispatch(setLocalAction);

export default store
