import { Dialog, DialogTitle, Grid, IconButton } from "@material-ui/core";
import { Close as CloseIcon } from '@material-ui/icons';
import { Curso } from "../../../../models/curso_model";
import FormStepOne from "../FormStepOne";
import FormStepTwo from "../FormStepTwo";


type FormCourseProps = {
  isModalOpen: boolean;
  handleBack: () => void;
  handleClose: () => void;
  handleSaveStepOne: () => void;
  handleSaveStepTwo: (data: any) => void;
  step: number;
  curso: Curso | null;
}

const ModalCourse: React.FC<FormCourseProps> = ({
  handleBack,
  isModalOpen,
  handleClose,
  handleSaveStepOne,
  handleSaveStepTwo,
  step,
  curso
}) => {
  return (
    <Dialog
      onClose={handleClose}
      open={isModalOpen}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle>
        <Grid container justify="space-between">
          { curso ? 'Editar información del curso' : 'Crear nuevo curso' }
          <IconButton size="small" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      { step === 1 && (
        <FormStepOne handleClose={handleClose} handleSave={handleSaveStepOne} handleSaveKinder={handleSaveStepTwo}curso={curso} />
      )}
      {
        step === 2 && (
          <FormStepTwo handleBack={handleBack} handleClose={handleClose} handleSave={handleSaveStepTwo} curso={curso} />
      )}
    </Dialog>
  );

}

export default ModalCourse;