import { Provider } from 'react-redux';
import AppContextProvider from './components/core/AppContext'
import AppRouter from './routers/AppRouter';
import store from './store';


function App() {
  return (
    <AppContextProvider>
      <Provider store={store}>
        <AppRouter />
      </Provider>
    </AppContextProvider>
  );
}

export default App;
