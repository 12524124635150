import { Box, Divider, makeStyles, Paper, Theme, Typography } from "@material-ui/core";
import React from "react";
import { Translate } from "react-redux-i18n";
import { ReporteEstudiante } from "..";
import { FotoPerfil } from "./FotoPerfil";

type Props = {
  reporteEstudiante: ReporteEstudiante | null;
};

export const DatosGenerales: React.FC<Props> = ({ reporteEstudiante }) => {
  const classes = useStyles();

  const renderStudentCourse = () => {
    if (reporteEstudiante && reporteEstudiante.datosGenerales && reporteEstudiante.datosGenerales.curso && reporteEstudiante.datosGenerales.curso.nivel === 'KINDER')
      return null;

    return (
      <tr>
        <td>
          <Typography>
            <strong><Translate value="reporte.historial" />:</strong>
          </Typography>
        </td>
        <td>
          {
            (reporteEstudiante?.datosGenerales.historial || []).map((ins: any, index: number) => (
              <React.Fragment key={index}>
                <Typography>
                  {ins.codigoCurso} ({ins.fechaInicio} - {ins.fechaFin})
                </Typography>
              </React.Fragment>
            ))
          }
        </td>
      </tr>
    );
  }

  return (
    <Box className={classes.root}>
      <Box display="flex" alignItems="center">
        <svg width="50" height="50" viewBox="0 0 112 156" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0.641969 74.8809C2.76967 36.3654 41.9863 -19.3838 68.7891 6.93745C85.1872 23.041 71.6043 43.9891 78.1145 66.5767C86.4784 95.5958 114.621 105.931 111.112 136.03C105.177 186.947 -2.18716 126.094 0.641969 74.8809Z" fill="#FFF7B0" />
          <rect x="9" y="43" width="95" height="70.9494" rx="16" fill="#525252" />
          <g clipPath="url(#clip0_0_1)">
            <path d="M90.3932 76.5039H66.1484C65.192 76.5039 64.4166 77.3863 64.4166 78.4747C64.4166 79.5632 65.192 80.4455 66.1484 80.4455H90.3932C91.3496 80.4455 92.125 79.5632 92.125 78.4747C92.125 77.3863 91.3496 76.5039 90.3932 76.5039Z" fill="white" />
            <path d="M66.1484 71.2483H90.3932C91.3496 71.2483 92.125 70.3659 92.125 69.2775C92.125 68.189 91.3496 67.3066 90.3932 67.3066H66.1484C65.192 67.3066 64.4166 68.189 64.4166 69.2775C64.4166 70.3659 65.192 71.2483 66.1484 71.2483Z" fill="white" />
            <path d="M90.3932 85.7007H66.1484C65.192 85.7007 64.4166 86.5831 64.4166 87.6715C64.4166 88.7599 65.192 89.6423 66.1484 89.6423H90.3932C91.3496 89.6423 92.125 88.7599 92.125 87.6715C92.125 86.5831 91.3496 85.7007 90.3932 85.7007Z" fill="white" />
          </g>
          <path d="M36.7083 62.0513C32.7415 62.0513 29.5258 65.2305 29.5258 69.1522C29.5258 73.074 32.7415 76.2532 36.7083 76.2532C40.675 76.2532 43.8907 73.074 43.8907 69.1522C43.8907 65.2305 40.675 62.0513 36.7083 62.0513Z" fill="white" />
          <path d="M29.047 80.0404C25.0802 80.0404 21.8645 83.2196 21.8645 87.1413V89.3915C21.8645 90.8178 22.91 92.0339 24.3339 92.2637C32.5292 93.5866 40.8873 93.5866 49.0826 92.2637C50.5065 92.0339 51.552 90.8178 51.552 89.3915V87.1413C51.552 83.2196 48.3363 80.0404 44.3695 80.0404H43.7167C43.3633 80.0404 43.0122 80.0956 42.6763 80.2041L41.0185 80.7392C38.2178 81.6434 35.1987 81.6434 32.398 80.7392L30.7402 80.2041C30.4043 80.0956 30.0532 80.0404 29.6998 80.0404H29.047Z" fill="white" />
        </svg>
        <Box pl={1} py={2}>
          <Typography variant="h6"><strong><Translate value="reporte.datosGenerales" /></strong></Typography>
        </Box>
      </Box>
      <Box display="flex" maxWidth={'1200px'}>
        <Box mr={4} minWidth={'300px'}><FotoPerfil reporteEstudiante={reporteEstudiante} /></Box>
        <Box width={'100%'} minHeight={'300px'}><Paper className={classes.card} elevation={3}>
          <Box display="flex" flexDirection="column" alignItems="center">
            <table className={classes.tabla}>
              <tbody>
                <tr>
                  <td width={150}>
                    <Typography>
                      <strong><Translate value="reporte.familiar1" />:</strong>
                    </Typography>
                  </td>
                  <td>
                    <Typography>
                      {reporteEstudiante?.datosGenerales.padre.nombre}
                    </Typography>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Typography>
                      <strong><Translate value="reporte.correo" />:</strong>
                    </Typography>
                  </td>
                  <td>
                    <Typography>
                      {reporteEstudiante?.datosGenerales.padre.correo}
                    </Typography>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Typography>
                      <strong><Translate value="reporte.celular" />:</strong>
                    </Typography>
                  </td>
                  <td>
                    <Typography>
                      {reporteEstudiante?.datosGenerales.padre.celular}
                    </Typography>
                  </td>
                </tr>
                <tr><td colSpan={2}><Box mb={2}><Divider /></Box></td></tr>
                {reporteEstudiante?.datosGenerales.padre2 && (
                  <>
                    <tr>
                      <td>
                        <Typography>
                          <strong>
                            <Translate value="reporte.familiar2" />:
                          </strong>
                        </Typography>
                      </td>
                      <td>
                        <Typography>
                          {reporteEstudiante?.datosGenerales.padre2.nombre}
                        </Typography>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Typography>
                          <strong>
                            <Translate value="reporte.correo" />:
                          </strong>
                        </Typography>
                      </td>
                      <td>
                        <Typography>
                          {reporteEstudiante?.datosGenerales.padre2.correo}
                        </Typography>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Typography>
                          <strong>
                            <Translate value="reporte.celular" />:
                          </strong>
                        </Typography>
                      </td>
                      <td>
                        <Typography>
                          {reporteEstudiante?.datosGenerales.padre2.celular}
                        </Typography>
                      </td>
                    </tr>
                    <tr><td colSpan={2}><Box mb={2}><Divider /></Box></td></tr>
                  </>
                )}
                {reporteEstudiante?.datosGenerales.familiar && (
                  <>
                    <tr>
                      <td>
                        <Typography>
                          <strong>
                            <Translate value="kinder.contactoEmergencia" />:
                          </strong>
                        </Typography>
                      </td>
                      <td>
                        <Typography>
                          {reporteEstudiante?.datosGenerales?.familiar?.nombre}
                        </Typography>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Typography>
                          <strong>
                            <Translate value="kinder.parentesco" />:
                          </strong>
                        </Typography>
                      </td>
                      <td>
                        <Typography>
                          {reporteEstudiante?.datosGenerales?.familiar?.parentesco}
                        </Typography>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Typography>
                          <strong>
                            <Translate value="kinder.contacto" />:
                          </strong>
                        </Typography>
                      </td>
                      <td>
                        <Typography>
                          {reporteEstudiante?.datosGenerales?.familiar?.contacto}
                        </Typography>
                      </td>
                    </tr>
                    <tr><td colSpan={2}><Box mb={2}><Divider /></Box></td></tr>
                  </>
                )}
                <tr>
                  <td>
                    <Typography>
                      <strong>
                        <Translate value="reporte.direccion" />:
                      </strong>
                    </Typography>
                  </td>
                  <td>
                    <Typography>
                      {reporteEstudiante?.datosGenerales.estudiante.direccion}
                    </Typography>
                  </td>
                </tr>
                {renderStudentCourse()}
              </tbody>
            </table>
          </Box>
        </Paper>
        </Box>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: theme.spacing(2, 0),
  },
  card: {
    padding: theme.spacing(4),
    minWidth: '500px',
    minHeight: '234px',
  },
  tabla: {
    width: '100%',
    "& td": {
      // margin: "2px",
      verticalAlign: "top",
    },
  },
}));
